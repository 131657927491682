import styled from "styled-components"

export const SectionWrapper = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 120vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-blk);
  @media ${({ theme }) => theme.mediaQueries.ipad_pro} {
    height: 100vh;
  }

  @media ${({ theme }) => theme.mediaQueries.tablet} {
    height: 100vh;
  }
`

export const SectionWrapperImg = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 150vh;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  min-height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    background-position: right;
    height: 120vh;
  }
`

export const SubActWrapper = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;

  margin-left: 3rem;
  margin-top: 5rem;

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    justify-content: flex-start;
    margin-top: 0%;
    padding: 5% 8%;
    margin: 0%;
    margin-left: 0%;
  }
`

export const SayWrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: flex-start;
`

export const ServicesWrapper = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 10% 12%;
  height: 100%;
  opacity: 1;
  z-index: 3;
  justify-content: center;
`

export const ServicesWrapper2 = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 10% 12%;
  height: 100%;
  opacity: 1;
  z-index: 3;
  justify-content: center;

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    height: 100%;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: flex-start;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding: 6% 18%;
  }
`
