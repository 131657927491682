import locomotiveScroll from "locomotive-scroll"
import React from "react"
import { Link, useHistory } from "react-router-dom"
import styled from "styled-components"
import logo123 from "../../assets/images/logo123.png"
import LOGOIMG from "../../assets/images/LOGO.png"
// import MenuLink from "../Navigation/MenuLink"
const LINKS = [
  {
    id: 1,
    link: "about us",
    url: "/about-us",
  },
  {
    id: 2,
    link: "portfolio",
    url: "/portfolio",
  },
  {
    id: 2,
    link: "ledger",
    url: "/ledger",
  },
]

const LogoWrapper = styled(Link)`
  text-decoration: none;
  color: white;
  // position: fixed;
  font-family: roboto;
  font-size: 1.2rem;
  text-transform: capitalize;
  letter-spacing: 0.05rem;
  font-weight: 500;
  // mix-blend-mode: difference;
  // transition: all var(--speed);
  // top: 7vh;
  // left: 6%;
  // &:hover {
  //   color: var(--color-pri);
  // }

  // &:focus {
  //   color: var(--color-pri);
  // }

  // &:active {
  //   color: var(--color-pri);
  // }

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 1.2rem;
    // position: absolute;
    // top: 70px;
    // left: 40px;
    display: none;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    font-size: 1.1rem;
  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro} {
    // top: 80px;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet} {
    // top: 85px;
  }
`

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: none;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    display: none;
  }

  @media ${({ theme }) => theme.mediaQueries.tablet} {
    display: block;
  }
`

const Links = styled(Link)`
  color: white;
  font-size: 0.8rem;
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 500;
  margin-right: 3rem;
  text-decoration: none;
  transition: all var(--speed);
  opacity: 0.8;
  padding: 0.3rem;
  z-index: 99;

  &:hover {
    color: var(--color-pri);
    opacity: 1;
  }

  &:focus {
    color: var(--color-pri);
    opacity: 1;
  }

  &:active {
    color: var(--color-pri);
    opacity: 1;
  }

  @media ${({ theme }) => theme.mediaQueries.tablet} {
    margin-right: 2rem;
  }
`

const HeaderWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 2% 12% 4% 4%;
  z-index: 10;
  mix-blend-mode: difference;

  // height: ${(props) => (props.path === "home" ? "20vh" : "22vh")};
  height: 20vh;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 20vh;
    padding: 12%;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    height: 18vh;
    padding: 7% 5%;
  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro} {
    height: 30vh;
    padding: 3% 5%;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet} {
    padding: 3% 0%;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet_landscape} {
    padding: 3% 0%;
  }
`

const LogoSelection = styled.div`
  width: auto;
  height: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  top: 3vh;
  left: 3%;
  position: fixed;
  mix-blend-mode: difference;
  -webkit-transition: all var(--speed);
  transition: all var(--speed);
  z-index: 99;
  cursor: pointer;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
    top: 4vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`

const Logo = styled.img`
  width: 6rem;
  height: 4rem;
  object-fit: contain;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
  }
`

const ImgStyled = styled.img`
  width: 7rem;
  height: auto;
  opacity: 0;
  position: absolute;
  right: 13.5%;
  top: 20%;
  -webkit-transition: all var(--speed);
  transition: all var(--speed);

  &:hover {
    opacity: 0.3;
  }
`

const TxtStyled = styled.p`
  color: white;
  font-size: 0.7rem;
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 300;
  margin-right: 0;
  text-decoration: none;
  padding: 0;

  opacity: 0.7;
  position: absolute;
  right: 12.5%;
  top: 47%;
  -webkit-transition: all var(--speed);
  transition: all var(--speed);


  @media ${({ theme }) => theme.mediaQueries.tablet} {
    margin-right: 2rem;
  }
`

const Header = ({ logoOnly }) => {
  const [isMobile, setisMobile] = React.useState(null)
  const [path, setPath] = React.useState()
  // const [isIpadpro, setisIpadro] = React.useState(null)
  const changeMobile = () => {
    window.matchMedia("(max-width: 37.5em)").matches
      ? setisMobile(true)
      : setisMobile(false)
  }
  // const changeIpadpro = () => {
  //   window.matchMedia("(max-width: 37.5em)").matches
  //     ? setisIpadro(true)
  //     : setisIpadro(false)
  // }

  const scrollTop = () => {
    const target = document.querySelector("#target")
    const scroll = new locomotiveScroll({ target, Smooth: true })
    // window.location.href = "http://localhost:3000/"
  }

  React.useEffect(() => {
    changeMobile()
    const pathName = () =>
      window.location.pathname
        .slice(1)
        .replace(/-/g, " ")
        .toLowerCase()
        .replace(/(\b[a-z](?!\s))/g, function (item) {
          return item.toLowerCase()
        })
    setPath(pathName() + "home")
    window.addEventListener("resize", changeMobile)
    return () => window.removeEventListener("resize", changeMobile)
  }, [])

  const HeaderCont = styled.div`
    display: flex;
    justify-content: space-between;
  `

  const history = useHistory()

  const handlePush = () => {
    history.push("/")
  }

  return (
    <HeaderCont
      style={{ display: "flex", justifyContent: "space-between" }}
      id="target"
    >
      <LogoSelection>
        <Logo
          src={logo123}
          alt=""
          style={{
            width: "6rem",
            height: "4rem",
            objectFit: "contain",
          }}
          onClick={() => {
            scrollTop()
            handlePush()
          }}
        />{" "}
        <LogoWrapper to="/" style={{ fontSize: "1.2rem" }} onClick={scrollTop}>
          Shubhartha Facilities & Services LLP
        </LogoWrapper>
      </LogoSelection>
      <HeaderWrapper
        path={path}
        data-scroll-section
        data-scroll
        data-scroll-speed="3"
      >
        {logoOnly ? (
          <></>
        ) : (
          <>
            {isMobile ? (
              <></>
            ) : (
              <LinkWrapper>
                <Links to="/about-us">about us</Links>
                <Links to="/portfolio">portfolio</Links>
                <Links to="/ledger">ledger</Links>
                <ImgStyled src={LOGOIMG} alt="" />
                <TxtStyled>Powered by GRAVITONET</TxtStyled>
              </LinkWrapper>
            )}
          </>
        )}
      </HeaderWrapper>
    </HeaderCont>
  )
}

export default Header
