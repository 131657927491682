import { authAxios } from "../utils/apiConfigs";

export const getPortfolioApi = async () => {
  try {
    const response = await authAxios.get(`/getPortfolio`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getTransactionHeadersApi = async () => {
  try {
    const response = await authAxios.get(`/getTransactionHeaders`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const createLedgerApi = async (formData) => {
  const body = JSON.stringify(formData);
  try {
    const response = await authAxios.post(`/createLedger`, body);
    return response;
  } catch (err) {
    throw err;
  }
};

export const signinApi = async (formData) => {
  const body = JSON.stringify(formData);
  try {
    const response = await authAxios.post(`/signin`, body);
    return response;
  } catch (err) {
    throw err;
  }
};

export const signupApi = async (formData) => {
  const body = JSON.stringify(formData);
  try {
    const response = await authAxios.post(`/signup`, body);
    return response;
  } catch (err) {
    throw err;
  }
};
