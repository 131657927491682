import React from "react"
import styled from "styled-components"
import {
  DetailedSayingWrapper,
  InsideSectionWrapper,
  SectionWrapper,
} from "../../building-blocks/atoms/containers/containers"
import {
  ActionLinks,
} from "../../building-blocks/atoms/texts/texts"
import { scroll } from "../../utils/locomotiveScroll"

function PrivacyPolicyContext() {
  React.useEffect(() => {
    scroll()
  }, [])

  return (
    <>
      <SectionWrapper style={{ height: "auto" }} data-scroll-section>
        <InsideSectionWrapper
          style={{
            padding: "12%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <>
            <BannerOverlayText
              style={{
                // width: "32%",
                // textAlign: "center",
                // marginTop: "8rem",
                // fontWeight: "300",
                // fontFamily: "graphik",
                // fontSize: "4rem",
                // letterSpacing: "0.6rem",
              }}
              data-scroll
              data-scroll-speed="-1"
            >
              Privacy Policy
            </BannerOverlayText>
          </>
          <DetailedSayingWrapper style={{  }}>
            <ActionLinks
              style={{
                textTransform: "none",
                lineHeight: "2",
                fontWeight: "300",
                letterSpacing: "0.08rem",
                fontSize: "1.4rem",
                wordBreak: "normal",
              }}
            >
              Honeysys knows that your privacy is important to you and takes
              user privacy seriously. We are committed to protecting your
              personal data in accordance with this Privacy Policy. Please look
              at our privacy policy carefully to get a clear understanding of
              how we collect, and secure your Personal Information in accordance
              with our website Honeysys privacy policy has been compiled to
              better serve those who are concerned with how their Personally
              Identifiable Information & is being used online. Personally
              Information, as described in Indian privacy law and information
              security, is information that can be used on its own or with other
              information to identify, contact, or to identify an individual in
              context.
              <br />
              This Privacy Policy applies to the website and mobile applications
              provided by Honeysys IT Services Pvt Ltd, a company headquartered
              in Bangalore, India, and/or the other companies of the Honeysys
              group who act as data controllers with respect to the personal
              data you share with Honeysys under this Policy.
              <br />
              <br />
              <p
                style={{
                  textTransform: "uppercase",
                  letterSpacing: "0.4rem",
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
              >
                Information
              </p>
              <br />
              Honeysys might collect personal data from you such as your name,
              contact details, company or university affiliation, industry,
              region/country, relationship to Honeysys, the reason for
              contacting Honeysys, topics you are interested in and any other
              information you choose to share. By browsing our Sites or sending
              us information, you explicitly consent to Honeysys processing your
              personal data in accordance with this Privacy Policy. Honeysys
              might use your personal data to fulfill your request, send you
              marketing communications, process your job application or manage
              your membership of the Honeysys network. Honeysys collects
              personal data from you when you request information from Honeysys
              and/or supply, information through the Sites, such as when you
              fill out a subscription form or consulting expertise inquiry,
              opt-in to receive emails from Honeysys, agree to participate in
              surveys, join our network or apply for a role at Honeysys.
              Personal data we collect might include your name, e-mail.
              <br />
              <br />
              <p
                style={{
                  textTransform: "uppercase",
                  letterSpacing: "0.4rem",
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
              >
                Cookies
              </p>
              <br />
              Honeysys may use cookies or other technology to obtain certain
              types of information about you. You can disable or delete cookies
              by going to the Help section of your browser but certain
              functionalities of our site might not work properly if you do so.
              We may use cookies or other technology to obtain certain types of
              information when you browse our site. We may use cookies to keep
              track of your visit to our site, speed up navigation and improve
              your experience of the site. Our site may use cookies such as
              Google Analytics to carry out some analytics on and enhance our
              site. These cookies help us understand, for example, how you use
              our site, which pages are the most visited or where people
              visiting our Sites are located. We may also use cookies to enable
              you to share content on social networking sites or for marketing
              and promotional purposes where you have subscribed to our
              marketing communications from Honeysys. You can choose to have
              your computer warn you each time a cookie is being
              <br />
              <br />
              <p
                style={{
                  textTransform: "uppercase",
                  letterSpacing: "0.4rem",
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
              >
                Personal Data
              </p>
              <br />
              Honeysys may use cookies or other technology to obtain certain
              types of information about you. You can disable or delete cookies
              by going to the Help section of your browser but certain
              functionalities of our site might not work properly if you do so.
              We may use cookies or other technology to obtain certain types of
              information when you browse our site. We may use cookies to keep
              track of your visit to our site, speed up navigation and improve
              your experience of the site. Our site may use cookies such as
              Google Analytics to carry out some analytics on and enhance our
              site. These cookies help us understand, for example, how you use
              our site, which pages are the most visited or where people
              visiting our Sites are located. We may also use cookies to enable
              you to share content on social networking sites or for marketing
              and promotional purposes where you have subscribed to our
              marketing communications from Honeysys. You can choose to have
              your computer warn you each time a cookie is being
              <br />
              <br />
              <p
                style={{
                  textTransform: "uppercase",
                  letterSpacing: "0.4rem",
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
              >
                Data Sharing
              </p>
              <br />
              Carefully selected third party service providers, such as
              marketing service, IT systems providers, recruitment service
              providers, data cleansing providers or event organizers, so they
              can provide services to Honeysys. Our advisors, Other alumni
              members if you have chosen to take part in the Alumni network; and
              law enforcement authorities or other government bodies when we are
              required to do so. Third parties other than law enforcement
              authorities or government bodies, will only handle your personal
              data on behalf of Honeysys for the purposes described in this
              Policy. Honeysys will not sell or otherwise share your personal
              data with such third parties for their own use. The companies of
              the Honeysys group and other third parties we share your
              information with may be located in other countries, including
              countries that do not offer the same protection to your personal
              data as the country in which you reside. Where that is the case,
              Honeysys ensures that your personal data is protected by
              <br />
              <br />
              <p
                style={{
                  textTransform: "uppercase",
                  letterSpacing: "0.4rem",
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
              >
                Data Security
              </p>
              <br />
              Honeysys has implemented appropriate technical and organizational
              security measures in order to protect your personal data from
              loss, misuse, alteration, or destruction. Honeysys has implemented
              appropriate technical and organizational security measures in
              order to protect your personal data from loss, misuse, alteration,
              or destruction. Such measures include anonymizing data where
              possible. Authorized Honeysys personal and third parties mentioned
              above will only have access to your personal data to the extent
              they need it to do their job or provide their services. Despite
              these precautions, however, Honeysys cannot guarantee that
              unauthorized persons will not obtain access to your information.
              <br />
              <br />
              <p
                style={{
                  textTransform: "uppercase",
                  letterSpacing: "0.4rem",
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
              >
                Rights
              </p>
              <br />
              Depending on where you reside you might have the right to request
              access to, rectification or erasure of your personal data and to
              object to or ask for the restriction of the processing of your
              personal data. You might also have the right to withdraw your
              consent and request that we stop processing or delete your
              personal data. Depending on where you reside, you may be entitled
              to certain rights regarding the personal data Honeysys holds about
              you. For instance, you may have the right to request access to
              your information, obtain a copy of it as well as information about
              how we use it. In the event that you have registered on our Alumni
              site or have applied for a consulting role at Honeysys and you no
              longer wish your data to be processed as described in this Policy,
              or if you wish to access, rectify, cancel or oppose such
              processing, please contact us at info@honeysys.com. If you receive
              marketing communications from Honeysys, you can opt-out of
              receiving future emails from Honeysys
              <br />
              <br />
              <p
                style={{
                  textTransform: "uppercase",
                  letterSpacing: "0.4rem",
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
              >
                Jobs
              </p>
              <br />
              If you apply for a job at Honeysys, you will be asked to submit
              information to Honeysys such as your name, contact details,
              information about your education and work history and any other
              information that might be relevant for your application or that
              you choose to share with Honeysys. If you do not provide this
              information to us, we might not be able to process your
              application. Honeysys will use this information to assess your
              application and candidacy for the position to which you have
              applied. Without limiting the generality of the foregoing, this
              may include: (i) evaluating your skills and experience in relation
              to the qualifications required for the job to which you have
              applied. (ii) tracking feedback and interactions we have with you
              throughout the Hiring. The information you provide may also be
              used to communicate with you about Honeysys events and to send you
              publications that we think may be of interest to you. We might
              share your personal data with other companies
              <br />
              <br />
              <p
                style={{
                  textTransform: "uppercase",
                  letterSpacing: "0.4rem",
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
              >
                Target audience Info
              </p>
              <br />
              Our Sites are not directed at children 16 years of age or younger.
              Honeysys, therefore, will not intentionally collect information
              about anyone under the age of 16, and requests that no such
              information be submitted to us.
            </ActionLinks>
          </DetailedSayingWrapper>
        </InsideSectionWrapper>
      </SectionWrapper>
    </>
  )
}

export default PrivacyPolicyContext


const BannerOverlayText = styled.h1`
  color: white;
  font-family: graphik;
  font-size: 4rem;
  letter-spacing: 0.6rem;
  font-weight: 300;
  margin-top: 8rem;
  width: 40%;
  text-align: center;
  @media ${({ theme }) => theme.mediaQueries.tablet}{
    width: 70%;
    padding: 20% 0%;

  }
  @media ${({ theme }) => theme.mediaQueries.tablet_landscape}{
    width: 70%;
    padding: 20% 0%;
    letter-spacing: 0.9rem;

  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro}{
    width: 70%;
    padding: 20% 0%;
    letter-spacing: 0.9rem;
    font-size: 5rem;
  }

  `