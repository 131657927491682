import styled from "styled-components"
import { Link } from "react-router-dom"

export const BannerOverlayLink = styled.a`
  color: white;
  font-family: graphik;
  font-size: 3.5rem;
  letter-spacing: 0.1rem;
  font-weight: 700;
  width: 100%;
  margin-top: 4rem;
  text-decoration: none;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    color: black;
    width: 85%;
    font-size: 2.4rem;
    font-weight: 800;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    color: black;
    font-size: 2rem;
    font-weight: 800;
    line-height: 2rem;
    margin: 0%;
  }
`

export const BannerOverlayLink2 = styled.a`
  color: white;
  font-family: graphik;
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
  font-weight: 600;
  width: 100%;
  margin-top: 1rem;
  text-decoration: none;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    color: black;
    font-size: 0.8rem;
    font-weight: 700;
    margin-top: 1.5rem;
    width: 65%;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    color: black;
    font-size: 0.85rem;
    font-weight: 600;
    line-height: 2rem;
    margin: 0%;
    margin-top: 2%;
  }
`
