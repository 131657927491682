import { Link } from "react-router-dom"
import styled from "styled-components"

export const BannerOverlaySubtext = styled.p`
  color: white;
  font-family: roboto;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  font-weight: 600;
`

export const BannerOverlayText = styled.h1`
  color: white;
  font-family: graphik;
  font-size: 3.5rem;
  letter-spacing: 0.1rem;
  font-weight: 700;
  width: 40%;
  margin-top: 4rem;
`

export const BannerOverlayTextLink = styled(Link)`
  color: white;
  font-family: graphik;
  font-size: 3.5rem;
  letter-spacing: 0.1rem;
  font-weight: 700;
  width: 40%;
  margin-top: 4rem;
  text-decoration: none;
`

export const MenuTextLink = styled(Link)`
  color: white;
  font-family: roboto;
  font-size: 1.8rem;
  letter-spacing: 0.1rem;
  font-weight: 300;
  width: 100%;
  margin-top: 1.5rem;
  text-decoration: none;
  text-transform: capitalize;
`

export const BannerOverlayLink = styled(Link)`
  color: white;
  font-family: graphik;
  font-size: 3.5rem;
  letter-spacing: 0.1rem;
  font-weight: 700;
  width: 40%;
  margin-top: 4rem;
  text-decoration: none;
`

export const ActionLinks = styled.p`
  color: white;
  font-size: 0.8rem;
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;
  z-index: 1;

  letter-spacing: 0.28rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 0.7rem;
    opacity: 0.8;
    letter-spacing: 0.28rem;
    font-weight: 400;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    font-size: 0.7rem;
    opacity: 0.8;
    -webkit-letter-spacing: 0.28rem;
    -moz-letter-spacing: 0.28rem;
    -ms-letter-spacing: 0.28rem;
    letter-spacing: 0.28rem;
    font-weight: 400;
  }
`

export const SubActionLinks = styled.p`
  color: white;
  font-size: 0.8rem;
  font-family: "Roboto";

  text-transform: none;
  line-height: 2;
  font-weight: 400;
  width: 48%;
  letter-spacing: 0.08rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    text-transform: none;
    line-height: 2;
    font-weight: 300;
    width: 92%;
    letter-spacing: 0.08rem;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    text-transform: none;
    line-height: 2;
    font-weight: 300;
    width: 92%;
    -webkit-letter-spacing: 0.08rem;
    -moz-letter-spacing: 0.08rem;
    -ms-letter-spacing: 0.08rem;
    letter-spacing: 0.08rem;
    font-size: 0.75rem;
  }
`

export const SubActionLink = styled.p`
  color: white;
  font-size: 0.8rem;
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;

  transition: all var(--speed);

  &:hover {
    color: var(--color-pri);
  }

  &:focus {
    color: var(--color-pri);
  }

  &:active {
    color: var(--color-pri);
  }
`

export const ActionLink = styled(Link)`
  color: ${(props) => (props.black ? "var(--color-blk)" : "var(--color-wht)")};
  font-size: 0.8rem;
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;
  text-decoration: none;

  transition: all var(--speed);

  &:hover {
    color: var(--color-pri);
  }

  &:focus {
    color: var(--color-pri);
  }

  &:active {
    color: var(--color-pri);
  }

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 0.7rem;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    font-size: 0.7rem;
  }
`

export const SayingText = styled.h2`
  color: white;
  font-family: graphik;
  font-size: 2rem;
  letter-spacing: 0.1rem;
  font-weight: 600;
  width: 65%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 1.5rem;
    font-weight: 800;
    width: 100%;
    line-height: 2.2rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    font-size: 1.5rem;
    font-weight: 800;
    width: 100%;
    line-height: 2.5rem;
    margin-top: 2rem;
    margin-bottom: 3rem;
  }
`

export const CardNumberText = styled.p`
  color: white;
  font-size: 0.8rem;
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;
  width: 12%;
  margin: 0rem;
`

export const CardNumberText2 = styled.p`
  font-size: 0.8rem;
  font-family: "Roboto";
  // text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;
  width: 12%;
  margin: 0rem;
  color: var(--color-pri);

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 65%;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    text-transform: none;
    line-height: 2;
    font-weight: 300;
    width: 25%;
    -webkit-letter-spacing: 0.08rem;
    -moz-letter-spacing: 0.08rem;
    -ms-letter-spacing: 0.08rem;
    -webkit-letter-spacing: 0.08rem;
    -moz-letter-spacing: 0.08rem;
    -ms-letter-spacing: 0.08rem;
    letter-spacing: 0.08rem;
    font-size: 0.75rem;

    padding-top: 3%;
  }
`

export const CardNumberTextBackground = styled.p`
  color: white;
  font-size: 0.8rem;
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 600;
  -webkit-letter-spacing: 0.1rem;
  -moz-letter-spacing: 0.1rem;
  -ms-letter-spacing: 0.1rem;
  letter-spacing: 0.1rem;
  margin: 0rem;
  background: var(--color-pri);
  padding: 0.5rem;
  margin-left: -1.5rem;
`

export const CardHeaderText = styled.h2`
  color: white;
  font-family: graphik;
  font-size: 1.6rem;
  font-weight: 400;
  margin: 0rem;
`

export const CardHeaderText2 = styled.h2`
  color: white;
  font-family: graphik;
  font-size: 1.6rem;
  font-weight: 400;
  margin: 0rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 1rem;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    text-transform: none;
    line-height: 2;
    font-weight: 400;
    width: 75%;
    -webkit-letter-spacing: 0.08rem;
    -moz-letter-spacing: 0.08rem;
    -ms-letter-spacing: 0.08rem;
    -webkit-letter-spacing: 0.08rem;
    -moz-letter-spacing: 0.08rem;
    -ms-letter-spacing: 0.08rem;
    letter-spacing: 0.08rem;
    font-size: 1.2rem;
  }
`

export const CardHeaderText3 = styled.h2`
  color: white;
  font-family: graphik;
  font-size: 3.5rem;
  letter-spacing: 0.1rem;
  font-weight: 700;
`

export const CardSubText = styled.p`
  color: white;
  font-size: 0.8rem;
  font-family: "Roboto";
  text-transform: capitalize;
  font-weight: 400;
  letter-spacing: 0.1rem;
  padding: 0% 12%;
  margin: 0rem;
`

export const CardSubText2 = styled.p`
  color: white;
  font-size: 0.8rem;
  font-family: "Roboto";
  // text-transform: capitalize;
  font-weight: 400;
  letter-spacing: 0.1rem;
  padding: 0% 12%;
  margin: 0rem;

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    text-transform: none;
    line-height: 1.6;
    font-weight: 300;
    width: 92%;
    -webkit-letter-spacing: 0.08rem;
    -moz-letter-spacing: 0.08rem;
    -ms-letter-spacing: 0.08rem;
    -webkit-letter-spacing: 0.08rem;
    -moz-letter-spacing: 0.08rem;
    -ms-letter-spacing: 0.08rem;
    letter-spacing: 0.08rem;
    font-size: 0.75rem;
    padding: 0%;
  }
`

export const CardNumberText3 = styled.p`
margin-left: 1%;
  font-size: 1rem;
  font-family: "Roboto";
  // text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;
  width: 12%;
  margin: 0rem;
  color: var(--color-pri);

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    text-transform: none;
    line-height: 2;
    font-weight: 300;
    width: 25%;
    -webkit-letter-spacing: 0.08rem;
    -moz-letter-spacing: 0.08rem;
    -ms-letter-spacing: 0.08rem;
    -webkit-letter-spacing: 0.08rem;
    -moz-letter-spacing: 0.08rem;
    -ms-letter-spacing: 0.08rem;
    letter-spacing: 0.08rem;
    font-size: 0.75rem;

    padding-top: 3%;
  }
`

export const CardHeaderText7 = styled.h2`
  color: white;
  font-family: graphik;
  font-size: 3.6rem;
  font-weight: 600;
  margin: 0rem;

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    text-transform: none;
    line-height: 2;
    font-weight: 400;
    width: 75%;
    -webkit-letter-spacing: 0.08rem;
    -moz-letter-spacing: 0.08rem;
    -ms-letter-spacing: 0.08rem;
    -webkit-letter-spacing: 0.08rem;
    -moz-letter-spacing: 0.08rem;
    -ms-letter-spacing: 0.08rem;
    letter-spacing: 0.08rem;
    font-size: 1.2rem;
  }
`

export const CardNumberText7 = styled.p`
  font-size: 0.8rem;
  font-family: "Roboto";
  // text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;
  width: 50%;
  margin: 0rem;
  color: var(--color-pri);

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    text-transform: none;
    line-height: 2;
    font-weight: 300;
    width: 25%;
    -webkit-letter-spacing: 0.08rem;
    -moz-letter-spacing: 0.08rem;
    -ms-letter-spacing: 0.08rem;
    -webkit-letter-spacing: 0.08rem;
    -moz-letter-spacing: 0.08rem;
    -ms-letter-spacing: 0.08rem;
    letter-spacing: 0.08rem;
    font-size: 0.75rem;

    padding-top: 3%;
  }
`