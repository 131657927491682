import { Link } from "react-router-dom"
import styled from "styled-components"

export const BannerOverlayText = styled.h1`
  color: white;
  font-family: graphik;
  font-size: 3.5rem;
  letter-spacing: 0.1rem;
  font-weight: 700;
  width: 75%;
  margin-top: 4rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-weight: 800;
    font-size: 2rem;
    width: 100%;
    line-height: 3.2rem;
    margin-top: 2rem;
    text-transform: capitalize;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    font-size: 1.5rem;
    font-weight: 800;
    width: 100%;
    line-height: 2.5rem;
    margin-top: 2rem;
    margin-bottom: 3rem;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet} {
    width: 65%;
  }

`

export const ActionLinks = styled.p`
  color: white;
  font-size: 0.8rem;
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;
  z-index: 1;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 0.7rem;
    opacity: 0.8;
    letter-spacing: 0.28rem;
    font-weight: 400;
  }
`

export const ActionLinks2 = styled.p`
  color: white;
  font-size: 0.8rem;
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;
  z-index: 1;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 0.7rem;
    opacity: 0.8;
    letter-spacing: 0.28rem;
    font-weight: 400;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    font-size: 0.7rem;
    opacity: 0.8;
    -webkit-letter-spacing: 0.28rem;
    -moz-letter-spacing: 0.28rem;
    -ms-letter-spacing: 0.28rem;
    -webkit-letter-spacing: 0.28rem;
    -moz-letter-spacing: 0.28rem;
    -ms-letter-spacing: 0.28rem;
    letter-spacing: 0.28rem;
    font-weight: 400;
  }
`

export const ActionLink = styled(Link)`
  color: ${(props) => (props.black ? "var(--color-blk)" : "var(--color-wht)")};
  font-size: 0.8rem;
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;
  text-decoration: none;

  transition: all var(--speed);

  &:hover {
    color: var(--color-pri);
  }

  &:focus {
    color: var(--color-pri);
  }

  &:active {
    color: var(--color-pri);
  }

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 0.7rem;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    font-size: 0.7rem;
  }
`
