import React from "react"
import styled from "styled-components"
import {
  SectionWrapper,
  SayWrapper,
  DetailedSayingWrapper,
  SubActWrapper,
  ServicesWrapper,
  TitleBg,
  SubActWrapper1,
} from "../../building-blocks/atoms/containers/OurTeamContainer"
import {
  ActionLink,
  // LogoLink,
  ActionLinks,
  BannerOverlayText,
  SubActionLinks,
  ActionLink1,
} from "../../building-blocks/atoms/texts/OurTeamText"
import Team1 from "../../assets/images/team (1).png"
// import Team2 from "../../assets/images/team (2).png"
import Team3 from "../../assets/images/team (3).jpg"
import Team4 from "../../assets/images/team (4).jpg"
// import LinkedinImg from "../../assets/images/Linked-in-Icon.png"
import RightBlack from "../../assets/icons/right_black.png"

const OurTeam = () => {
  const [isMobile, setisMobile] = React.useState(null)
  const [isTablet, setisTablet] = React.useState(null)

  const changeMobile = () => {
    window.matchMedia("(max-width: 37.5em)").matches
      ? setisMobile(true)
      : setisMobile(false)
  }

  const changeTablet = () => {
    window.matchMedia("(min-device-width: 760px) and (max-device-width: 810px)").matches
      ? setisTablet(true)
      : setisTablet(false)
  }

  React.useEffect(() => {
    changeMobile()
    changeTablet()
    console.log(isTablet)
    window.addEventListener("resize", changeMobile)
    return () => window.removeEventListener("resize", changeMobile)
  }, [])

  return (
    <SectionWrapper data-scroll-section>
      {isMobile ? (
        <>
          <div
            style={{
              height: "80vh",
              width: "100%",
              padding: "9%",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <img
              style={{
                width: "40%",
                position: "relative",
                top: "13%",
                left: "20%",
                height: "55%",
                objectFit: "cover",
              }}
              src={Team1}
              alt=""
            />
            {/* <img
              style={{
                width: "32%",
                position: "relative",
                top: "56%",
                left: "6%",
                height: "30%",
                objectFit: "cover",
              }}
              src={}
              alt=""
            /> */}
            <img
              style={{
                width: "45%",
                position: "relative",
                top: "1%",
                left: "25%",
                height: "35%",
                objectFit: "cover",
              }}
              src={Team3}
              alt=""
            />
            <img
              style={{
                width: "68%",
                position: "relative",
                top: "39%",
                left: "-20%",
                height: "58%",
                objectFit: "cover",
              }}
              src={Team4}
              alt=""
            />
          </div>
        </>
      ) : (
        <></>
      )}

      <ServicesWrapper style={{zIndex: '4', padding : isTablet ? "10% 12% -1% 12%" : ""}}>
        <SayWrapper>
          <ActionLinks>our team</ActionLinks>

          <TitleBg />
        </SayWrapper>

        <>
          <BannerOverlayText data-scroll data-scroll-speed="1">
            Cohesive Community
          </BannerOverlayText>
        </>

        <DetailedSayingWrapper>
          <SubActionLinks data-scroll data-scroll-speed="1">
          Our team firmly believes to be a well-connected and cohesive community. We push each other to get better; we could never be as good alone as we are together. We are a team of talented individuals, putting best results to the table with teamwork.
          </SubActionLinks>
        </DetailedSayingWrapper>

        {isMobile ? (
          <>
            <SubActWrapper1 data-scroll data-scroll-speed="2">
              <ActionLink1 to="/our-team" black zIndex="4">
                explore more
                <img
                  src={RightBlack}
                  style={{
                    marginLeft: "0.5rem",
                    width: "7%",
                    height: "auto",
                    objectFit: "cover",
                  }}
                  alt=""
                />
              </ActionLink1>
            </SubActWrapper1>
          </>
        ) : (
          <>
            <SubActWrapper data-scroll data-scroll-speed="2" style={{zIndex:"9",}}>
              <div
                style={{
                  background: "#94e2ff",
                  height: "1px",
                  width: "3.5rem",
                  marginRight: "5px",
                }}
              ></div>
              <ActionLink to="/our-team" black  >
                explore more
              </ActionLink>
            </SubActWrapper>
          </>
        )}
      </ServicesWrapper>

      {isMobile ? (
        <></>
      ) : (
        <>
          <ServicesWrapper
            style={{
              backgroundColor: "transparent",
              height: "110vh",
              padding: "0% 0%",
              zIndex: "3",
              
            }}
          >
             <Img1 src={Team1} alt="" data-scroll data-scroll-speed="3" />
            {/* <div >
            <picture style={{position:"initial"}}>
              <Img1 src={Team1} alt="" data-scroll data-scroll-speed="3" />
            </picture>
              <LogoLink to={"www.linkdin.com"} ><LinkedinLogo src={LinkedinImg}></LinkedinLogo></LogoLink>
            </div> */}
            {/* <img
              style={{
                position: "absolute",
                width: "12%",
                top: "28rem",
                left: "0rem",
              }}
              src={}
              alt=""
              data-scroll
              data-scroll-speed="2"
            /> */}

                <Img3 src={Team3} alt="" data-scroll data-scroll-speed="2" />
                <Img4 src={Team4} alt="" data-scroll data-scroll-speed="3" />
             {/* <div >
              <picture style={{position:"initial"}}>
               <Img3 src={Team3} alt="" data-scroll data-scroll-speed="2" />
             </picture>
              <LogoLink to={"www.linkdin.com"} ><LinkedinLogo src={LinkedinImg}></LinkedinLogo></LogoLink>
            </div>
            <div >
            <picture style={{position:"initial"}}>
            <Img4 src={Team4} alt="" data-scroll data-scroll-speed="3" />
            </picture>
              <LogoLink to={"www.linkdin.com"} ><LinkedinLogo src={LinkedinImg}></LinkedinLogo></LogoLink>
            </div> */}
            
            
          </ServicesWrapper>
        </>
      )}
    </SectionWrapper>
  )
}

export default OurTeam

// const LinkedinLogo = styled.img`
// position: absolute;
//  hight: 3rem;
//  width : 3rem
// `

const Img1 = styled.img`
  position: absolute;
  width: 12%;
  top: -1rem;
  left: 0rem;

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    position: absolute;
    width: 10%;
    top: 15%;
    left: 10%;

  }
    @media ${({ theme }) => theme.mediaQueries.tablet} {
      width: 20%;
    top: 6rem;
    left: -24rem;
    }
    @media ${({ theme }) => theme.mediaQueries.tablet_landscape}{
      width: 18%;
      left: 1rem;
    }

    @media ${({ theme }) => theme.mediaQueries.ipad_pro}{
      width: 18%;
      left: 1rem;
      top: 15%;
    }
   
  
`

const Img3 = styled.img`
  position: absolute;
  width: 22%;
  top: -6rem;
  left: 13rem;

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    position: absolute;
    width: 15%;
    top: -5%;
    left: 22%;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet}{
    width: 34%;
    top: -3rem;
    left: -14rem;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet_landscape}{
    width: 31%;
  }
  
  @media ${({ theme }) => theme.mediaQueries.ipad_pro} {
    width:  30%;
    top: 10%;
  }
`

const Img4 = styled.img`
  position: absolute;
  width: 28%;
  top: 15rem;
  height: 80%;
  left: 13rem;
  object-fit: cover;

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    position: absolute;
    width: 22%;
    top: 44%;
    left: 22%;
    object-fit: cover;
    height: 70%;
  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro} {
    height: 60%;
    width:  36%;
    top: 34%;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet}{
  width: 38%;
  top: 24rem;
  height: 50%;
  left: -14rem;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet_landscape}{
    width: 35%;
  }

`
 