import styled from "styled-components"

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: flex-start;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 0 15vw 5%;
  }
`
