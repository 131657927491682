// import { Link } from "react-router-dom"
import styled from "styled-components"

export const SectionWrapper = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);
`

export const SectionWrapper3 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: auto;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: var(--color-comp);
  flex-direction: column;
  z-index: 2;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column;
    height: auto;
    justify-content: flex-start;
    align-items: center;
    z-index: 2;
  }
`

export const SectionWrapper2 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 80vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 40vh;
  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro}{
    height: 40vh;
    margin-top: 2rem;
  }
`

export const SectionWrapper1 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);
  z-index: 2;
  justify-content: flex-end;
  height: auto;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 110vh;
    justify-content: flex-end;
  }
`

export const InsideSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 6% 18%;
`

export const InsideSectionWrapper3 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 3% 36% 9% 36%;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 0% 12%;
    align-items: flex-start;
    justify-content: flex-start;
    height: auto;
  }
`

export const InsideSectionWrapper2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 6% 18%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    align-items: center;
    justify-content: center;
    padding: 12%;
  }
`

export const InsideSectionWrapper1 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 0% 45% 0% 12%;
  align-items: flex-start;
  justify-content: flex-start;
  height: 60vh;
  position: absolute;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    position: absolute;
    padding: 0% 12%;
    align-items: flex-start;
    justify-content: flex-start;
    height: 60vh;
  }
`

export const DetailedSayingWrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 2rem;
`

export const DetailedSayingWrapper2 = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 4rem;
  margin-bottom: 5rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    margin-top: 0rem;
    margin-bottom: 5rem;
    justify-content: flex-start;
  }
`

export const DetailedSayingWrapper1 = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 3rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
    height: auto;
  }
`

export const ServicesWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6% 12%;
`

export const ServicesWrapper1 = styled.div`
  position: absolute;
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0% 14%;
  z-index: 2;
  background-color: transparent;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    background-color: transparent;
    justify-content: center;
    z-index: 2;
    padding: 0% 12%;
  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro}{
    // padding: 20% 10%;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet}{
    align-items: flex-start;
    padding: 0% 14%;
  }
`
