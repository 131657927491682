import React from "react"
import Header from "../../common-components/Header/Header"
import MshoppiContext from "../../common-components/SectionComponents/MshoppiContext"
import MenuLink from "../../common-components/Navigation/MenuLink"
import MenuPage from "../menu-page/MenuPage"
import { scroll } from "../../utils/locomotiveScroll"
import PortfolioContext from "../../common-components/SectionComponents/PortfolioContext"
import WhatWeDo from "../../common-components/SectionComponents/WhatWeDo"

function MshoppiPage() {
  const [menuOpen, setMenuOpen] = React.useState(false)
  const [isMobile, setisMobile] = React.useState(null)

  const changeMobile = () => {
    window.matchMedia("(max-width: 37.5em)").matches
      ? setisMobile(true)
      : setisMobile(false)
  }

  React.useEffect(() => {
    window.scroll(0, 0)
    scroll()
    changeMobile()
    window.addEventListener("resize", changeMobile)
    return () => window.removeEventListener("resize", changeMobile)
  }, [])

  return (
    <>
      {isMobile ? (
        <>
          <Header />
          {!menuOpen && (
            <>
              <MshoppiContext />
              <WhatWeDo />
            </>
          )}
          {/* <MenuPage setMenuOpen={setMenuOpen} menuOpen={menuOpen} /> */}
                    {/* <MenuLink setMenuOpen={setMenuOpen} menuOpen={menuOpen} /> */}

        </>
      ) : (
        <>
          <Header />
          <MshoppiContext />
          <WhatWeDo />
          {/* <MenuPage setMenuOpen={setMenuOpen} menuOpen={menuOpen} /> */}
                    {/* <MenuLink setMenuOpen={setMenuOpen} menuOpen={menuOpen} /> */}

        </>
      )}
    </>
  )
}

export default MshoppiPage
