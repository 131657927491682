import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { SectionFlexWrapper } from "../ui/StyledContainer";
import { StyledForm } from "../ui/StyledForm";
import { StyledText } from "../ui/StyledText";
import { StyledInput } from "../ui/StyledInput";
import { StyledButton } from "../ui/StyledButton";
import { Text } from "./Text";
import { signinApi, signupApi } from "../apis/apis";
import { MessageService } from "../utils/runEvent";

export const AuthForm = ({
  setStatus,
  toggleSignin,
  setToggleSignin,
}) => {
  const [toggleSignup, setToggleSignup] = useState(false);
  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
    reset,
  } = useForm();

  const handleSignin = async (data) => {
    if (data?.UserId !== "" && data?.password !== "") {
      signinApi(data).then((res) => {
        if (
          res &&
          res.data &&
          res.data.status &&
          res.data.token &&
          res.data.user
        ) {
          MessageService.authDataToXData(
            res.data.token,
            res.data.user,
            res.data.status
          );

          setStatus(`Loggedin successfully`, "success");
          setToggleSignin(!toggleSignin);
        } else if (res && res.data && !res.data.status) {
          setStatus(`${res.data.message}`, "error");
        } else {
          setStatus("Unable to find account", "error");
        }
      });
    }
  };

  const handleSignup = async (data) => {
    if (data?.UserId !== "" && data?.password !== "") {
      signupApi(data).then((res) => {
        if (res && res.data && res.data.status) {
          setToggleSignup(!toggleSignup);
          setStatus(`Signedup successfully`, "success");
        } else if (res && res.data && !res.data.status) {
          setStatus(`${res.data.message}`, "error");
        }
      });
    }
  };

  const SigninForm = () => {
    return (
      <SectionFlexWrapper
        width="100%"
        height="auto"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        margin="0"
        padding="0"
      >
        <Text
          fontSize="1rem"
          fontWeight="700"
          padding="0"
          margin="0 0 1rem"
          width="auto"
          height="auto"
          color="var(--blk1)"
          textAlign="start"
          lineHeight="1.2rem"
          letterSpacing="unset"
          wordBreak="unset"
          textTransformation="capitalize"
          label={"Login"}
          fontSizeMobile="0.8rem"
          marginMobile="0 0.4rem 0"
        />
        <StyledForm
          onSubmit={handleSubmit(handleSignin)}
          style={{
            alignItems: "center",
          }}
        >
          <StyledInput
            fontSize={"0.7rem"}
            fontWeight={"500"}
            padding={"0 2%"}
            margin={"0 0 4%"}
            width={"100%"}
            height={"5vh"}
            backgroundColor={"var(--wht)"}
            color={"var(--blk1)"}
            border={"1px solid var(--blk4)"}
            backgroundColorHover={"var(--blk4)"}
            colorHover={"var(--blk1)"}
            borderHover={"1px solid var(--blk3)"}
            type="text"
            placeholder="Enter UserId"
            {...register("UserId")}
            autocomplete="off"
          />

          <StyledInput
            fontSize={"0.7rem"}
            fontWeight={"500"}
            padding={"0 2%"}
            margin={"0 0 4%"}
            width={"100%"}
            height={"5vh"}
            backgroundColor={"var(--wht)"}
            color={"var(--blk1)"}
            border={"1px solid var(--blk4)"}
            backgroundColorHover={"var(--blk4)"}
            colorHover={"var(--blk1)"}
            borderHover={"1px solid var(--blk3)"}
            type="password"
            placeholder="Enter Password"
            {...register("password")}
            autocomplete="off"
          />

          <StyledButton
            fontSize={"0.7rem"}
            fontWeight={"500"}
            padding={"0"}
            width={"60%"}
            height={"6vh"}
            backgroundColor={"var(--blk1)"}
            color={"var(--wht)"}
            border={"none"}
            backgroundColorHover={"var(--gold1)"}
            colorHover={"white"}
            borderHover={"none"}
            backgroundColorFocus={"var(--gold3)"}
            colorFocus={"white"}
            borderFocus={"var(--gold1)"}
            style={{
              marginTop: "5%",
            }}
            fontSizeMobile={"0.7rem"}
            paddingMobile={"0 7%"}
            marginMobile={"0"}
            widthMobile={"auto"}
            heightMobile={"5vh"}
          >
            Login
          </StyledButton>

          <StyledButton
            fontSize={"0.7rem"}
            fontWeight={"500"}
            padding={"0"}
            width={"60%"}
            height={"6vh"}
            backgroundColor={"var(--wht)"}
            color={"var(--blk1)"}
            border={"none"}
            backgroundColorHover={"var(--gold1)"}
            colorHover={"white"}
            borderHover={"none"}
            backgroundColorFocus={"var(--gold3)"}
            colorFocus={"white"}
            borderFocus={"var(--gold1)"}
            style={{
              marginTop: "2%",
            }}
            fontSizeMobile={"0.7rem"}
            paddingMobile={"0 7%"}
            marginMobile={"0"}
            widthMobile={"auto"}
            heightMobile={"5vh"}
            onClick={() => setToggleSignup(!toggleSignup)}
          >
            Signup
          </StyledButton>
        </StyledForm>
      </SectionFlexWrapper>
    );
  };

  const SignupForm = () => {
    return (
      <SectionFlexWrapper
        width="100%"
        height="auto"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        margin="0"
        padding="0"
      >
        <Text
          fontSize="1rem"
          fontWeight="700"
          padding="0"
          margin="0 0 1rem"
          width="auto"
          height="auto"
          color="var(--blk1)"
          textAlign="start"
          lineHeight="1.2rem"
          letterSpacing="unset"
          wordBreak="unset"
          textTransformation="capitalize"
          label={"Signup"}
          fontSizeMobile="0.8rem"
          marginMobile="0 0.4rem 0"
        />
        <StyledForm
          onSubmit={handleSubmit(handleSignup)}
          style={{
            alignItems: "center",
          }}
        >
          <StyledInput
            fontSize={"0.7rem"}
            fontWeight={"500"}
            padding={"0 2%"}
            margin={"0 0 4%"}
            width={"100%"}
            height={"5vh"}
            backgroundColor={"var(--wht)"}
            color={"var(--blk1)"}
            border={"1px solid var(--blk4)"}
            backgroundColorHover={"var(--blk4)"}
            colorHover={"var(--blk1)"}
            borderHover={"1px solid var(--blk3)"}
            type="text"
            placeholder="Enter UserId"
            {...register("UserId")}
            autocomplete="off"
          />

          <StyledInput
            fontSize={"0.7rem"}
            fontWeight={"500"}
            padding={"0 2%"}
            margin={"0 0 4%"}
            width={"100%"}
            height={"5vh"}
            backgroundColor={"var(--wht)"}
            color={"var(--blk1)"}
            border={"1px solid var(--blk4)"}
            backgroundColorHover={"var(--blk4)"}
            colorHover={"var(--blk1)"}
            borderHover={"1px solid var(--blk3)"}
            type="password"
            placeholder="Enter Password"
            {...register("password")}
            autocomplete="off"
          />

          <StyledButton
            fontSize={"0.7rem"}
            fontWeight={"500"}
            padding={"0"}
            width={"60%"}
            height={"6vh"}
            backgroundColor={"var(--blk1)"}
            color={"var(--wht)"}
            border={"none"}
            backgroundColorHover={"var(--gold1)"}
            colorHover={"white"}
            borderHover={"none"}
            backgroundColorFocus={"var(--gold3)"}
            colorFocus={"white"}
            borderFocus={"var(--gold1)"}
            style={{
              marginTop: "5%",
            }}
            fontSizeMobile={"0.7rem"}
            paddingMobile={"0 7%"}
            marginMobile={"0"}
            widthMobile={"auto"}
            heightMobile={"5vh"}
          >
            Signup
          </StyledButton>

          <StyledButton
            fontSize={"0.7rem"}
            fontWeight={"500"}
            padding={"0"}
            width={"60%"}
            height={"6vh"}
            backgroundColor={"var(--wht)"}
            color={"var(--blk1)"}
            border={"none"}
            backgroundColorHover={"var(--gold1)"}
            colorHover={"white"}
            borderHover={"none"}
            backgroundColorFocus={"var(--gold3)"}
            colorFocus={"white"}
            borderFocus={"var(--gold1)"}
            style={{
              marginTop: "2%",
            }}
            fontSizeMobile={"0.7rem"}
            paddingMobile={"0 7%"}
            marginMobile={"0"}
            widthMobile={"auto"}
            heightMobile={"5vh"}
            onClick={() => setToggleSignup(!toggleSignup)}
          >
            Login
          </StyledButton>
        </StyledForm>
      </SectionFlexWrapper>
    );
  };

  return (
    <>{toggleSignup === false ? <SigninForm /> : <SignupForm />}</>
  );
};
