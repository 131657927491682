import React from "react"
import styled from "styled-components"
import { v4 as uuidv4 } from "uuid"
import {
  SectionWrapper,
  SectionWrapper2,
  InsideSectionWrapper,
  SayWrapper,
  SayingWrapper,
  DetailedSayingWrapper,
  ActionSecond,
  ActionSecond2,
  SubActWrapper,
  CardImageWrapper,
  CardTextWrapper,
  CardHeaderWrapper3,
  CardSubtextWrapper,
  ContentWrapperLink,BannerOverlayText
} from "../../building-blocks/atoms/containers/PortfolioContext2"
import { CardImg } from "../../building-blocks/atoms/images/images"
import {
  ActionLink,
  ActionLinks,
  CardHeaderText7,
  CardNumberText7,
  CardSubText2,
  SayingText,
  SubActionLinks,
} from "../../building-blocks/atoms/texts/WhatWeDoText"
import cashew from "../../assets/images/cashew.jpeg"
import ScrollImg2 from "../../assets/images/scroll2.jpg"
import ScrollImg3 from "../../assets/images/scroll3.jpg"
import ScrollImg4 from "../../assets/images/scroll4.jpg"
import ScrollImg5 from "../../assets/images/scroll5.jpg"
import ScrollImg6 from "../../assets/images/scroll6.jpg"
import RightWhite from "../../assets/icons/right_white.png"

const LIST = [
  {
    id: uuidv4(),
    img: cashew,
    title: "Dr J's Cashew",
    sector: "Agro - Retail",
    context:
      "We supply variety of agriculture product which includes cashew nuts under the brand name Dr J’s Cashew with good quality. ",
  },
]

const PortfolioContext2 = () => {
  const [isMobile, setisMobile] = React.useState(null)
  const [isIpad, setisIpad] = React.useState(null)


  const changeIpad = () => {
    window.matchMedia("(max-width: 1024px)").matches
      ? setisIpad(true)
      : setisIpad(false)
  }

  const changeMobile = () => {
    window.matchMedia("(max-width: 37.5em)").matches
      ? setisMobile(true)
      : setisMobile(false)
  }

  React.useEffect(() => {
    changeMobile()
    changeIpad()
    window.addEventListener("resize", changeMobile)
    return () => window.removeEventListener("resize", changeMobile)
  }, [])

  const parseStep = (steps) => {
    steps = steps.split("/")
    steps = (
      <span>
        <span style={{ color: "white" }}>{steps[0]}</span>/{steps[1]}
      </span>
    )
    return steps
  }

  return (
    <>
      <SectionWrapper data-scroll-section style={{ zIndex: 2 }}>
        <InsideSectionWrapper>
        <BannerOverlayText
              style={{ }}
              data-scroll
              data-scroll-speed="-2"
            >
              We back a vast range <br /> of enterprises pioneering <br /> new
              spaces.
            </BannerOverlayText>

          {/* {isMobile ? (
            <>
              <ActionSecond
                style={{
                  justifyContent: "flex-start",
                  padding: "0%",
                  marginTop: "0rem",
                }}
              >
                <SubActWrapper
                  data-scroll
                  data-scroll-speed="2"
                  style={{
                    width: "100%",
                    justifyContent: "flex-start",
                  }}
                >
                  <ActionLink
                    to="/what-we-do"
                    style={{
                      alignItems: "center",
                      justifyContent: "flex-start",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    explore more{" "}
                    <img
                      src={RightWhite}
                      style={{
                        marginLeft: "0.5rem",
                        width: "7%",
                        height: "auto",
                        objectFit: "cover",
                      }}
                      alt=""
                    />
                  </ActionLink>
                </SubActWrapper>
              </ActionSecond>
            </>
          ) : (
            <>
              <ActionSecond2>
                <SubActWrapper data-scroll data-scroll-speed="2">
                  <div
                    style={{
                      background: "#94e2ff",
                      height: "1px",
                      width: "3.5rem",
                      marginRight: "5px",
                    }}
                  ></div>
                  <ActionLink to="/what-we-do">explore more</ActionLink>
                </SubActWrapper>
              </ActionSecond2>
            </>
          )} */}
        </InsideSectionWrapper>
      </SectionWrapper>

      {isMobile ? (
        <></>
      ) : (
        <>
          <SectionWrapper2 data-scroll-section>
            <HorizontalScrollableSectionWrapper2 style={{}}>
              {LIST.map((item) => (
                <ContentWrapperLink
                  data-scroll
                  data-scroll-speed="3"
                  data-scroll-direction="horizontal"
                  to={item.title.replace(/\s+/g, "-").toLowerCase()}
                >
                  <CardTextWrapper>
                    <CardHeaderWrapper3>
                      <CardNumberText7>{item.sector}</CardNumberText7>
                      <CardHeaderText7>{item.title}</CardHeaderText7>
                    </CardHeaderWrapper3>
                    <CardSubtextWrapper>
                      <CardSubText2>{item.context}</CardSubText2>
                    </CardSubtextWrapper>
                  </CardTextWrapper>
                  <CardImageWrapper>
                    <CardImg src={item.img} alt="" />
                  </CardImageWrapper>
                </ContentWrapperLink>
              ))}
            </HorizontalScrollableSectionWrapper2>
          </SectionWrapper2>
        </>
      )}
    </>
  )
}

export default PortfolioContext2


const HorizontalScrollableSectionWrapper2 = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(75,calc(100% - 50%));
  column-gap: 22%;
  padding-left: 25rem;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  
  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
  margin-bottom: 4rem;
  cursor: grab;
  padding-left: 18%;
  column-gap: 8%;
  grid-template-columns: repeat(75,calc(100% - 68%));
 
  }
  @media ${({ theme }) => theme.mediaQueries.tablet}{
    grid-template-columns: repeat(75,calc(135%));
    padding-left: 20rem;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet_landscape}{
    grid-template-columns: repeat(75,calc(135%));
    padding-left: 35rem;

  }

  @media ${({ theme }) => theme.mediaQueries.ipad_pro}{
    grid-template-columns: repeat(75,calc(135%));
    column-gap: 43%;
    padding-left: 30rem;
  }

`