import React from "react"
import styled from "styled-components"
import Footer from "../../common-components/Footer/Footer"
import Header from "../../common-components/Header/Header"
import SendRequest from "../../common-components/SectionComponents/SendRequest"
import WhatWeDo from "../../common-components/SectionComponents/WhatWeDo"
import DigitizationContext from "../../common-components/SectionComponents/DigitizationContext."
import MenuLink from "../../common-components/Navigation/MenuLink"
import MenuPage from "../menu-page/MenuPage"
import { scroll } from "../../utils/locomotiveScroll"
import PortfolioContext2 from "../../common-components/SectionComponents/PortfolioContext2"
// import { BackImg } from "../../building-blocks/atoms/images/images"
import IMG1 from "../../assets/images/fmcg-converted-1.png"
import IMG2 from "../../assets/images/fmcg-converted-2.png"
import IMG3 from "../../assets/images/fmcg-converted-3.png"
import IMG4 from "../../assets/images/fmcg-converted-4.png"
import AILOVIMG from "../../assets/images/ai-lov-images.jpeg"
import VOIMG from "../../assets/images/vo-image.png"
import GLORYIMG from "../../assets/images/glory-img.png"
import ScrollImg7 from "../../assets/images/scroll7.jpg"

export const BannerWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--color-blk);
`

export const BannerOverlayWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to left, rgba(18, 0, 255, 0.1), #ad5541);

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    justify-content: flex-start;
    padding: 40% 0%;
  }
`

export const BannerOverlayTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 38vh;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0% 12%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    justify-content: center;
    height: 30vh;
  }
`
export const ActionFirst = styled.div`
  display: flex;
  width: 100%;
  height: 28vh;
  align-items: center;
  justify-content: space-between;
  padding: 0% 12%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-weight: 400;
    font-family: roboto;
    font-size: 1.5rem;
    width: 100%;
    line-height: 2.4rem;
    margin: 0%;
  }
`

export const ActionSecond = styled.div`
  display: flex;
  width: 100%;
  height: 15vh;
  align-items: center;
  justify-content: center;
  padding: 0% 12%;
`

export const PrevNextWrapper = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 0% 12%;
  }
`

export const SubActWrapper = styled.div`
  // display: flex;
  width: auto;
  align-items: center;
  justify-content: center;
`

export const TitleBg = styled.div`
  background: var(--color-sec);
  width: 6%;
  height: 1.2rem;
  position: relative;
  top: -3.5%;
  left: -1.5%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: none;
  }
`

///////////////////////////////////////////////

export const BannerOverlaySubtext = styled.p`
  color: white;
  font-family: roboto;
  font-size: 0.8rem;
  text-transform: capitalize;
  letter-spacing: 0.3rem;
  font-weight: 600;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    z-index: 1;
    font-size: 0.7rem;
    letter-spacing: 0.2rem;
    opacity: 0.8;
  }
`

export const BannerOverlayText = styled.h1`
  color: white;
  font-family: graphik;
  font-size: 3.2rem;
  letter-spacing: 0.1rem;
  font-weight: 700;
  width: 60%;
  margin-top: 2rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-weight: 800;
    font-size: 2rem;
    width: 100%;
    line-height: 3.5rem;
    margin-top: 2rem;
  }
`

export const BannerOverlayText2 = styled.h1`
  width: 100%;
  font-weight: 300;
  font-family: roboto;
  font-size: 3.2rem;
  color: black;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-weight: 400;
    font-family: roboto;
    font-size: 1.2rem;
    width: 100%;
    line-height: 2.4rem;
    margin: 0%;
    letter-spacing: 0.08rem;
  }
`

export const ActionLinks = styled.p`
  color: white;
  font-size: 0.8rem;
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;
  line-height: 1.3rem;
`

export const ActionLinks2 = styled.p`
  color: white;
  font-family: "Roboto";
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  margin-right: ${(props) => (props.left ? "7rem" : "0rem")};
  font-size: 0.7rem;
  font-weight: 400;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    text-transform: none;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 2;
    width: 92%;
    letter-spacing: 0.08rem;
  }
`

////////////////////////////////////////////

export const BannerImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 100%;
    width: 100%;
    margin-top: 0%;
    object-fit: cover;
    margin-right: 0%;
  }
`

/////////////////////////////////////////////////

export const SectionWrapper1 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);
  height: 90vh;
  z-index: 2;
`

export const SectionWrapper2 = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 400vh;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: var(--color-comp);

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 400vh;
  }
`

export const BackImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 100%;
    width: auto;
  }
`

export const InsideSectionWrapper1 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 15% 18% 0% 15%;
`

export const BannerOverlayText1 = styled.h1`
  color: white;
  font-family: graphik;
  font-size: 3.5rem;
  letter-spacing: 0.1rem;
  font-weight: 700;
  width: 90%;
  margin-top: 6rem;
`

export const BannerOverlaySubtext1 = styled.p`
  color: white;
  font-family: roboto;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  font-weight: 600;
  padding: 0% 3%;
`

export const SectionWrapper3 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);
  height: 60vh;
  z-index: 2;
  position: relative;

  padding: 12% 14%;
  background-color: rgb(225, 230, 236);
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 30% 12%;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column-reverse;
  }
`

export const ServicesWrapper1 = styled.div`
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6% 30% 14%;
  background-color: var(--color-wht);
  position: absolute;

  padding: 0;
  width: 40%;
  position: unset;
  background-color: transparent;
  justify-content: center;
  align-items: flex-start;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
    height: auto;
    margin-bottom: 12vh;
  }
`

export const BannerOverlayText3 = styled.h1`
  color: white;
  font-family: roboto;
  font-size: 2.5rem;
  letter-spacing: 0.1rem;
  font-weight: 300;
  margin-top: 5rem;
  width: 100%;
  color: black;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-weight: 400;
    font-family: roboto;
    font-size: 1.2rem;
    width: 60%;
    line-height: 2.4rem;
    margin: 0%;
    letter-spacing: 0.08rem;
  }
`

export const ActionLinks1 = styled.p`
  color: white;
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;

  text-transform: none;
  line-height: 2;
  font-weight: 300;
  width: 100%;
  letter-spacing: 0.02rem;
  color: black;
  margin-top: 3rem;
  font-size: 1rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    text-transform: none;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 2;
    font-weight: 300;
    width: 92%;
    letter-spacing: 0.08rem;
  }
`

export const SectionWrapper = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);
`

export const SectionWrapper10 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);

  height: 100vh;
  z-index: 6;
  position: relative;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 70vh;
    z-index: 6;
    position: relative;
  }
`

export const SectionWrapper9 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 160vh;
  align-items: center;
  justify-content: center;
  background-color: rgb(225, 230, 236);

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    align-items: flex-start;
    height: 110vh;
  }
`

export const SectionWrapper6 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);

  height: 172vh;
  z-index: 2;
  position: relative;
`

export const SectionWrapper7 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);

  height: 530vh;
  z-index: 6;
  position: relative;
`

export const SectionWrapper4 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: rgb(225, 230, 236);

  height: 78vh;
  z-index: 2;
  position: relative;
  height: auto @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 55vh;
  }
`

export const SectionWrapper5 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: rgb(225, 230, 236);

  height: 100vh;
  z-index: 2;
  position: relative;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 80vh;
  }
`

export const SectionWrapper8 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: rgb(225, 230, 236);

  height: 40vh;
  z-index: 2;
  position: relative;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 55vh;
  }
`

export const ServicesWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  background-color: rgb(225, 230, 236);
  padding: 10% 18% 0%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 30% 12%;
    justify-content: center;
  }
`

export const ServicesWrapper3 = styled.div`
  position: absolute;
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  background-color: rgb(225, 230, 236);
  padding: 5% 7% 5%;
  flex-direction: row;
  justify-content: space-between;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 30% 12%;
    justify-content: center;
  }
`

export const ServicesWrapper2 = styled.div`
  position: absolute;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  background-color: rgb(225, 230, 236);
  padding: 0% 12% 14%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 10% 12%;
    justify-content: center;
  }
`

const GridWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: auto;
  z-index: 2;
  flex-wrap: wrap;
  flex-direction: column;
  position: absolute;
  background-color: rgb(225, 230, 236);

  height: 100%;
  padding: 0% 18%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 7%;
  }
`

const Img = styled.img`
  width: 30%;
  padding: 2%;
  height: auto;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 48%;
    padding: 4%;
    height: auto;
  }
`

// const ImgWrapper = styled.div`
//   display: flex;
//   width: 100%;
//   z-index: 2;
//   position: absolute;
//   background-color: rgb(225, 230, 236);
//   height: 100%;
//   padding: 0% 10%;
//   flex-direction: column;
//   align-items: flex-start;
//   justify-content: flex-start;
//   position: absolute;

//   display: flex;
//   width: 100%;
//   z-index: 2;
//   position: absolute;
//   background-color: rgb(225, 230, 236);
//   height: 100%;
//   padding: 0% 10%;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   position: absolute;

//   @media ${({ theme }) => theme.mediaQueries.mobile} {
//     align-items: center;
//     justify-content: center;
//   }
// `

// const Img2 = styled.img`
//   width: 38%;
//   height: auto;
//   margin-bottom: 9%;

//   @media ${({ theme }) => theme.mediaQueries.mobile} {
//     width: 100%;
//     height: auto;
//     margin-bottom: 9%;
//   }
// `

const LogoImage = styled.img`
  width: 30%;
  height: auto;
  object-fit: cover;
  object-fit: cover;
  border-radius: 70%;
  margin: 0 1rem;

  // &:hover {
  //   background-color: #b4632a;
  // }

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 60%;
    height: auto;
    object-fit: cover;
  }
`

const ActionButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: auto;
  margin-top: 3%;
`

const ActionButton = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 19%;
  height: auto;
  padding: 4%;
  background-color: var(--color-sec);
  font-size: 1rem;
  font-weight: 500;
  color: white;
  padding: 2% 3%;
  width: auto;
  align-items: center;
  font-family: Graphik;
  cursor: pointer;
`

const Modal = styled.div`
  position: fixed;
  top: 14vh;
  left: 15vw;
  width: 70vw;
  height: 80vh;
  background-color: var(--color-wht);
  z-index: 120;
  display: flex;
  box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 20%);

  align-items: center;
  justify-content: center;
  padding: 2%;
`

const Spans = styled.span`
  font-weight: 500;
  font-size: 1.2rem;
`

function DigitizationPage2() {
  const [menuOpen, setMenuOpen] = React.useState(false)
  const [isMobile, setisMobile] = React.useState(null)

  const changeMobile = () => {
    window.matchMedia("(max-width: 37.5em)").matches
      ? setisMobile(true)
      : setisMobile(false)
  }

  React.useEffect(() => {
    window.scroll(0, 0)
    scroll()
    changeMobile()
    window.addEventListener("resize", changeMobile)
    return () => window.removeEventListener("resize", changeMobile)
  }, [])

  return (
    <>
      {isMobile ? (
        <>
          <Header />
          {!menuOpen && (
            <>
              <>
                <BannerWrapper data-scroll-section>
                  <BannerImg
                    src={ScrollImg7}
                    alt=""
                    data-scroll
                    data-scroll-speed="-3"
                  />
                  <BannerOverlayWrapper>
                    <BannerOverlayTextWrapper
                      data-scroll
                      data-scroll-speed="-2"
                    >
                      <BannerOverlayText>
                        Fast Moving Consumer Goods | FMCG
                      </BannerOverlayText>
                    </BannerOverlayTextWrapper>
                    <>
                      <ActionFirst data-scroll data-scroll-speed="-1">
                        {/* <SubActWrapper>
                        <ActionLinks
                          style={{
                            width: "50%",
                          }}
                        >
                          Maintaining our capability in Investment, Destribution,
                          Supply and Inculcate with Fast Moving Consumer Goods.
                        </ActionLinks>
                      </SubActWrapper> */}
                        {/* <PrevNextWrapper>
                    <ActionLinks2 left>prev</ActionLinks2>
                    <ActionLinks2>next</ActionLinks2>
                  </PrevNextWrapper> */}
                      </ActionFirst>
                      <ActionSecond
                        style={{
                          position: "absolute",
                          bottom: "-5rem",
                        }}
                        data-scroll
                        data-scroll-speed="-2"
                      >
                        <SubActWrapper>
                          <ActionLinks style={{ letterSpacing: "0.28rem" }}>
                            scroll down
                          </ActionLinks>
                        </SubActWrapper>
                      </ActionSecond>
                    </>
                  </BannerOverlayWrapper>
                </BannerWrapper>

                <SectionWrapper3 data-scroll-section>
                  <ServicesWrapper1>
                    <>
                      <BannerOverlayText3 data-scroll data-scroll-speed="1">
                        Official channel partners,
                      </BannerOverlayText3>
                    </>
                    {/* <ActionLinks1 data-scroll data-scroll-speed="1">
                    Our factories are located in raw material areas so that we
                    purchase materials directly from farmers.
                  </ActionLinks1> */}
                  </ServicesWrapper1>
                  <a
                    href="http://www.smartoeat.com/"
                    style={{
                      width: "60%",
                    }}
                  >
                    <LogoImage src={AILOVIMG} alt="" />
                    <LogoImage src={VOIMG} alt="" />
                  </a>
                </SectionWrapper3>

                <SectionWrapper3 data-scroll-section>
                  <ServicesWrapper1>
                    <>
                      <BannerOverlayText3 data-scroll data-scroll-speed="1">
                        Official energy drink importers{" "}
                        <span
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          (coming soon)
                        </span>
                      </BannerOverlayText3>
                    </>
                    {/* <ActionLinks1 data-scroll data-scroll-speed="1">
        Our factories are located in raw material areas so that we
        purchase materials directly from farmers.
      </ActionLinks1> */}
                  </ServicesWrapper1>
                  <a
                    href="#"
                    style={{
                      width: "60%",
                    }}
                  >
                    <LogoImage src={GLORYIMG} alt="" />
                  </a>
                </SectionWrapper3>

                <SectionWrapper2
                  data-scroll-section
                  style={{
                    height: "auto",
                  }}
                >
                  <BackImg
                    src={IMG1}
                    style={{ width: "100%", height: "auto" }}
                    alt=""
                    data-scroll
                    data-scroll-speed="0"
                  />
                  <BackImg
                    src={IMG2}
                    style={{ width: "100%", height: "auto" }}
                    alt=""
                    data-scroll
                    data-scroll-speed="0"
                  />
                  <BackImg
                    src={IMG3}
                    style={{ width: "100%", height: "auto" }}
                    alt=""
                    data-scroll
                    data-scroll-speed="0"
                  />
                  <BackImg
                    src={IMG4}
                    style={{ width: "100%", height: "auto" }}
                    alt=""
                    data-scroll
                    data-scroll-speed="0"
                  />
                </SectionWrapper2>

                {/* <SectionWrapper8
                data-scroll-section
                style={{ marginBlock: "-0.1rem" }}
              >
                <ServicesWrapper>
                  <>
                    <BannerOverlayText2 data-scroll data-scroll-speed="1">
                      Process of Producing Cashew Nuts in India.
                    </BannerOverlayText2>
                  </>
                </ServicesWrapper>
              </SectionWrapper8> */}
              </>
              <Footer />
            </>
          )}
          {/* <MenuPage setMenuOpen={setMenuOpen} menuOpen={menuOpen} /> */}
          {/* <MenuLink setMenuOpen={setMenuOpen} menuOpen={menuOpen} /> */}
        </>
      ) : (
        <>
          <Header />
          <>
            <BannerWrapper data-scroll-section>
              <BannerImg
                src={ScrollImg7}
                alt=""
                data-scroll
                data-scroll-speed="-3"
              />
              <BannerOverlayWrapper>
                <BannerOverlayTextWrapper data-scroll data-scroll-speed="-2">
                  <BannerOverlayText>
                    Fast Moving Consumer Goods | FMCG
                  </BannerOverlayText>
                </BannerOverlayTextWrapper>
                <>
                  <ActionFirst data-scroll data-scroll-speed="-1">
                    {/* <SubActWrapper>
                      <ActionLinks
                        style={{
                          width: "50%",
                        }}
                      >
                        Maintaining our capability in Investment, Destribution,
                        Supply and Inculcate with Fast Moving Consumer Goods.
                      </ActionLinks>
                    </SubActWrapper> */}
                    {/* <PrevNextWrapper>
                  <ActionLinks2 left>prev</ActionLinks2>
                  <ActionLinks2>next</ActionLinks2>
                </PrevNextWrapper> */}
                  </ActionFirst>
                  <ActionSecond
                    style={{
                      position: "absolute",
                      bottom: "-5rem",
                    }}
                    data-scroll
                    data-scroll-speed="-2"
                  >
                    <SubActWrapper>
                      <ActionLinks style={{ letterSpacing: "0.28rem" }}>
                        scroll down
                      </ActionLinks>
                    </SubActWrapper>
                  </ActionSecond>
                </>
              </BannerOverlayWrapper>
            </BannerWrapper>

            <SectionWrapper3 data-scroll-section>
              <ServicesWrapper1>
                <>
                  <BannerOverlayText3 data-scroll data-scroll-speed="1">
                    Official channel partners,
                  </BannerOverlayText3>
                </>
                {/* <ActionLinks1 data-scroll data-scroll-speed="1">
                  Our factories are located in raw material areas so that we
                  purchase materials directly from farmers.
                </ActionLinks1> */}
              </ServicesWrapper1>
              <a
                href="http://www.smartoeat.com/"
                style={{
                  width: "60%",
                }}
              >
                <LogoImage src={AILOVIMG} alt="" />
                <LogoImage src={VOIMG} alt="" />
              </a>
            </SectionWrapper3>

            <SectionWrapper3 data-scroll-section>
              <ServicesWrapper1>
                <>
                  <BannerOverlayText3 data-scroll data-scroll-speed="1">
                    Official energy drink importers{" "}
                    <span
                      style={{
                        fontSize: "0.8rem",
                      }}
                    >
                      (coming soon)
                    </span>
                  </BannerOverlayText3>
                </>
                {/* <ActionLinks1 data-scroll data-scroll-speed="1">
      Our factories are located in raw material areas so that we
      purchase materials directly from farmers.
    </ActionLinks1> */}
              </ServicesWrapper1>
              <a
                href="#"
                style={{
                  width: "60%",
                }}
              >
                <LogoImage src={GLORYIMG} alt="" />
              </a>
            </SectionWrapper3>

            <SectionWrapper2 data-scroll-section>
              <BackImg
                src={IMG1}
                style={{ height: "100%" }}
                alt=""
                data-scroll
                data-scroll-speed="0"
              />
              <BackImg
                src={IMG2}
                style={{ height: "100%" }}
                alt=""
                data-scroll
                data-scroll-speed="0"
              />
              <BackImg
                src={IMG3}
                style={{ height: "100%" }}
                alt=""
                data-scroll
                data-scroll-speed="0"
              />
              <BackImg
                src={IMG4}
                style={{ height: "100%" }}
                alt=""
                data-scroll
                data-scroll-speed="0"
              />
            </SectionWrapper2>

            {/* <SectionWrapper8
              data-scroll-section
              style={{ marginBlock: "-0.1rem" }}
            >
              <ServicesWrapper>
                <>
                  <BannerOverlayText2 data-scroll data-scroll-speed="1">
                    Process of Producing Cashew Nuts in India.
                  </BannerOverlayText2>
                </>
              </ServicesWrapper>
            </SectionWrapper8> */}
          </>
          <Footer />
        </>
      )}
    </>
  )
}

export default DigitizationPage2
