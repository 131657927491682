import React from "react"
import styled from "styled-components"
import {
  // InsideSectionWrapper,
  SectionWrapper,
} from "../../building-blocks/atoms/containers/containers"
import { BackImg } from "../../building-blocks/atoms/images/images"
// import {
//   BannerOverlayText,
//   ActionLinks,
// } from "../../building-blocks/atoms/texts/texts"
import Workwithus1 from "../../assets/images/workwithus1.jpg"
import { scroll } from "../../utils/locomotiveScroll"
import RightWhite from "../../assets/icons/right_white.png"

export const ServicesWrapper4 = styled.div`
  position: absolute;
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6% 12%;

  z-index: 2;
  position: relative;
  padding: 12% 12%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    z-index: 2;
    position: relative;
    padding: 24% 12%;
  }
`

const BannerOverlaySubtext2 = styled.p`
  color: white;
  font-family: roboto;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  font-weight: 600;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 0.7rem;
    opacity: 0.8;
    -webkit-letter-spacing: 0.28rem;
    -moz-letter-spacing: 0.28rem;
    -ms-letter-spacing: 0.28rem;
    letter-spacing: 0.28rem;
  }
`

const SectionWrapper1 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);
  height: 90vh;
  z-index: 2;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 75vh;
  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro} {
    height: 75vh;
  }
`

const InsideSectionWrapper1 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 15% 18% 0% 15%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    align-items: flex-start;
    justify-content: flex-start;
    padding: 60% 12% 30% 12%;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet} {
    align-items: flex-start;
    justify-content: flex-start;
    padding: 35% 12% 30% 12%;
  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro}{
    padding: 35% 12% 30% 12%;
  }
`

const BannerOverlaySubtext1 = styled.p`
  color: white;
  font-family: roboto;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  font-weight: 600;
  padding: 0% 3%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 0% 3%;
    font-size: 0.7rem;
    opacity: 0.8;
    letter-spacing: 0.28rem;
  }
`

const BannerOverlayText1 = styled.h1`
  color: white;
  font-family: graphik;
  font-size: 3.5rem;
  letter-spacing: 0.1rem;
  font-weight: 700;
  margin-top: 6rem;

  width: 74%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 1.5rem;
    font-weight: 800;
    width: 100%;
    line-height: 2.5rem;
    margin-top: 3rem;
    margin-bottom: 5rem;
  }
`

const SectionWrapper2 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 120vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 100vh;
  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro} {
    height: 80vh;
  }
`

const SectionWrapper3 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);

  height: 120vh;
  z-index: 2;
  position: relative;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: auto;
    min-height: 80vh;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet} {
    min-height: 60vh;
  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro} {
  height: 80vh;
  }
`

// const SectionWrapper4 = styled.div`
//   color: white;
//   display: flex;
//   width: 100%;
//   height: auto;
//   align-items: center;
//   justify-content: center;
//   background-color: var(--color-comp);

//   height: 240vh;
//   z-index: 2;
//   position: relative;
//   margin-bottom: -0.1rem;

//   @media ${({ theme }) => theme.mediaQueries.mobile} {
//     height: auto;
//     min-height: 100vh;
//   }
// `

// const SectionWrapper5 = styled.div`
//   color: white;
//   display: flex;
//   width: 100%;
//   height: 420vh;
//   align-items: center;
//   justify-content: center;
//   background-color: var(--color-comp);

//   @media ${({ theme }) => theme.mediaQueries.mobile} {
//     height: 140vh;
//     align-items: center;
//   }
// `

// const ServicesWrapper3 = styled.div`
//   position: absolute;
//   width: 100%;
//   height: inherit;
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   justify-content: flex-start;

//   background-color: var(--color-comp);
//   padding: 12% 15%;
//   height: auto;

//   @media ${({ theme }) => theme.mediaQueries.mobile} {
//     padding: 24% 12%;
//     height: auto;
//   }
// `

const BannerOverlayText2 = styled.h1`
  color: white;
  font-family: roboto;
  font-size: 3.2rem;
  letter-spacing: 0.1rem;
  font-weight: 300;
  margin-top: 5rem;
  width: 100%;
  color: black;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    color: black;
    font-weight: 400;
    font-family: roboto;
    font-size: 1.2rem;
    width: 100%;
    line-height: 2rem;
    margin-top: 0rem;
    letter-spacing: 0.04rem;
    margin-bottom: 3rem;
  }
`

const BannerOverlayText4 = styled.h1`
  color: white;
  font-family: roboto;
  font-size: 2.4rem;
  -webkit-letter-spacing: 0.1rem;
  -moz-letter-spacing: 0.1rem;
  -ms-letter-spacing: 0.1rem;
  letter-spacing: 0.1rem;
  font-weight: 300;
  margin-top: 5rem;
  width: 100%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-weight: 400;
    font-family: roboto;
    font-size: 1.2rem;
    width: 100%;
    line-height: 2rem;
    margin-top: 0rem;
    letter-spacing: 0.04rem;
    margin-bottom: 3rem;
  }
`

const ActionLinks1 = styled.p`
  color: white;
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;

  text-transform: none;
  line-height: 2;
  font-weight: 300;
  width: 65%;
  letter-spacing: 0.02rem;
  color: black;
  margin-top: 3rem;
  font-size: 1rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    color: black;
    margin-top: 0rem;
    text-transform: none;
    line-height: 2;
    font-weight: 400;
    width: 92%;
    letter-spacing: 0.04rem;
    margin-bottom: 3rem;
    font-size: 0.8rem;
  }
`

const ActionLinks3 = styled.p`
  color: white;
  font-family: "Roboto";
  /* text-transform: uppercase; */
  /* font-weight: 600; */
  -webkit-letter-spacing: 0.1rem;
  -moz-letter-spacing: 0.1rem;
  -ms-letter-spacing: 0.1rem;
  /* letter-spacing: 0.1rem; */
  text-transform: none;
  line-height: 2;
  font-weight: 300;
  /* width: 65%; */
  -webkit-letter-spacing: 0.02rem;
  -moz-letter-spacing: 0.02rem;
  -ms-letter-spacing: 0.02rem;
  letter-spacing: 0.02rem;
  /* color: black; */
  margin-top: 3rem;
  font-size: 1rem;
  width: 50%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    color: white;
    margin-top: 0rem;
    text-transform: none;
    line-height: 2;
    font-weight: 400;
    width: 92%;
    letter-spacing: 0.04rem;
    margin-bottom: 3rem;
    font-size: 0.8rem;
  }
`

const TechStackWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 3rem;

  flex-wrap: wrap;
  width: 70%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    margin-top: 1rem;
    width: 100%;
  }
`

const TechsReq = styled.p`
  color: white;
  font-family: "Roboto";
  text-transform: none;
  line-height: 2;
  font-weight: 300;
  letter-spacing: 0.02rem;
  font-size: 1rem;
  padding: 0.5rem 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 2rem;
  margin-right: 1rem;
  margin-bottom: 1.5rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 0.2rem 1.2rem;
    margin-right: 0.7rem;
  }
`

const OpeningCard = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  margin-top: 5vh;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 6rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column;
  }
`

const OpeningCardDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  height: auto;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
  }
`

const OpeningCardActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  height: auto;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
  }
`

// const BannerOverlayText3 = styled.h1`
//   color: white;
//   font-family: roboto;
//   font-size: 3.2rem;
//   letter-spacing: 0.1rem;
//   font-weight: 300;
//   margin-top: 5rem;
//   width: 100%;
//   color: black;

//   @media ${({ theme }) => theme.mediaQueries.mobile} {
//     color: black;
//     font-weight: 400;
//     font-family: roboto;
//     font-size: 1.2rem;
//     width: 100%;
//     line-height: 2rem;
//     margin-top: 0rem;
//     letter-spacing: 0.04rem;
//     margin-bottom: 2rem;
//   }
// `

// const ActionLinks2 = styled.p`
//   color: white;
//   font-family: "Roboto";
//   text-transform: uppercase;
//   font-weight: 600;
//   letter-spacing: 0.1rem;

//   text-transform: none;
//   line-height: 2;
//   font-weight: 300;
//   width: 65%;
//   letter-spacing: 0.02rem;
//   color: black;
//   margin-top: 3rem;
//   font-size: 1rem;

//   @media ${({ theme }) => theme.mediaQueries.mobile} {
//     color: black;
//     margin-top: 0rem;
//     text-transform: none;
//     line-height: 2;
//     font-weight: 400;
//     width: 92%;
//     letter-spacing: 0.04rem;
//     margin-bottom: 5rem;
//     font-size: 0.8rem;
//   }
// `

const ServicesWrapper1 = styled.div`
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6% 30% 14%;
  background-color: var(--color-wht);

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    background-color: white;
    padding: 18% 12%;
    position: unset;
    height: auto;
    min-height: 80vh;
  }
  @media ${({ theme }) => theme.mediaQueries.mobile}{
    padding: 35% 12%;
  }
`

// const ServicesWrapper2 = styled.div`
//   width: 100%;
//   height: inherit;
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   justify-content: flex-start;
//   padding: 6% 30% 14%;
//   background-color: var(--color-wht);

//   @media ${({ theme }) => theme.mediaQueries.mobile} {
//     background-color: white;
//     padding: 18% 12%;
//     position: unset;
//     height: auto;
//     min-height: 100vh;
//   }
// `

// const BannerOverlayText8 = styled.h1`
//   color: white;
//   font-family: graphik;
//   font-size: 3.5rem;
//   letter-spacing: 0.1rem;
//   font-weight: 700;
//   width: 40%;
//   margin-top: 4rem;

//   width: 100%;
//   margin: 0rem 0rem 7%;
//   font-weight: 600;
//   font-family: roboto;
//   font-size: 1rem;
//   letter-spacing: 0.4rem;
//   text-transform: uppercase;

//   @media ${({ theme }) => theme.mediaQueries.mobile} {
//     width: 100%;
//     margin: 0rem 0rem 12%;
//     font-family: roboto;
//     text-transform: uppercase;
//     font-size: 0.7rem;
//     opacity: 1;
//     letter-spacing: 0.28rem;
//     font-weight: 400;
//   }
// `

// const BannerOverlayText9 = styled.h1`
//   color: white;
//   font-family: graphik;
//   font-size: 3.5rem;
//   letter-spacing: 0.1rem;
//   font-weight: 700;
//   width: 40%;
//   margin-top: 4rem;

//   width: 100%;
//   margin: 0rem;
//   font-weight: 300;
//   font-family: roboto;
//   font-size: 3rem;

//   @media ${({ theme }) => theme.mediaQueries.mobile} {
//     margin: 0rem;
//     font-family: roboto;
//     margin-top: 0rem;
//     text-transform: none;
//     line-height: 2;
//     font-weight: 400;
//     width: 92%;
//     letter-spacing: 0.04rem;
//     margin-bottom: 0rem;
//     font-size: 0.72rem;
//   }
// `

const ActionCall = styled.p`
  color: white;
  font-family: "Roboto";
  -webkit-letter-spacing: 0.1rem;
  -moz-letter-spacing: 0.1rem;
  -ms-letter-spacing: 0.1rem;
  text-transform: none;
  line-height: 2;
  font-weight: 500;
  -webkit-letter-spacing: 0.02rem;
  -moz-letter-spacing: 0.02rem;
  -ms-letter-spacing: 0.02rem;
  -webkit-letter-spacing: 0.02rem;
  -moz-letter-spacing: 0.02rem;
  -ms-letter-spacing: 0.02rem;
  letter-spacing: 0.02rem;
  margin-top: 3rem;
  font-size: 1rem;
  width: 100%;
  margin-top: 5rem;
  text-transform: uppercase;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    margin-top: 3rem;
    justify-content: flex-start;
    align-items: center;
    display: flex;
  }
`

function WorkWithUsContext() {
  React.useEffect(() => {
    scroll()
  }, [])

  return (
    <>
      <SectionWrapper1 data-scroll-section>
        <InsideSectionWrapper1>
          <BannerOverlaySubtext1>work with us</BannerOverlaySubtext1>
          <>
            <BannerOverlayText1 data-scroll data-scroll-speed="-2">
              Join our Cohesive <br />
              Community at Honeysys.
            </BannerOverlayText1>
          </>
        </InsideSectionWrapper1>
      </SectionWrapper1>

      <SectionWrapper2 data-scroll-section>
        <BackImg
          src={Workwithus1}
          style={{ height: "130vh" }}
          alt=""
          data-scroll
          data-scroll-speed="-5"
        />
      </SectionWrapper2>

      <SectionWrapper3 data-scroll-section>
        <ServicesWrapper1>
          <>
            <BannerOverlayText2 data-scroll data-scroll-speed="1">
              We are always looking for talented people that move us bolder,
              dream bigger and perform better.
            </BannerOverlayText2>
          </>
          <ActionLinks1 data-scroll data-scroll-speed="1">
            Since day one, our formula has been simple - Developing Great
            Products for Bright Future to create a great impact where immensely
            talented people are encouraged to be brilliantat what they do.
          </ActionLinks1>
        </ServicesWrapper1>
      </SectionWrapper3>

      <SectionWrapper
        data-scroll-section
        style={{
          height: "auto",
          alignItems: "flex-start",
        }}
      >
        <ServicesWrapper4>
          <>
            <BannerOverlaySubtext2>openings</BannerOverlaySubtext2>
          </>
          <>
            <OpeningCard>
              <OpeningCardDetails>
                <BannerOverlayText4>UI/UX, Visual Designer</BannerOverlayText4>
                <ActionLinks3>
                  Since day one, our formula has been simple - Developing Great
                  Products for Bright Future to create a great impact where
                  immensely talented people are encouraged to be brilliantat
                  what they do.
                </ActionLinks3>

                <TechStackWrapper>
                  <TechsReq>Sketch / XD</TechsReq>
                  <TechsReq>Wireframes and User flows</TechsReq>
                  <TechsReq>Prototypes</TechsReq>
                  <TechsReq>InDesign</TechsReq>
                  <TechsReq>UX Research</TechsReq>
                  <TechsReq>Design Systems</TechsReq>
                </TechStackWrapper>
              </OpeningCardDetails>
              <OpeningCardActionWrapper>
                <ActionCall>
                  Apply{" "}
                  <img
                    src={RightWhite}
                    style={{
                      marginLeft: "0.5rem",
                      width: "7%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                    alt=""
                  />
                </ActionCall>
              </OpeningCardActionWrapper>
            </OpeningCard>
          </>

          <>
            <OpeningCard>
              <OpeningCardDetails>
                <BannerOverlayText4>Front End Developer</BannerOverlayText4>
                <ActionLinks3>
                  Since day one, our formula has been simple - Developing Great
                  Products for Bright Future to create a great impact where
                  immensely talented people are encouraged to be brilliantat
                  what they do.
                </ActionLinks3>

                <TechStackWrapper>
                  <TechsReq>Sketch / XD</TechsReq>
                  <TechsReq>Wireframes and User flows</TechsReq>
                  <TechsReq>Prototypes</TechsReq>
                  <TechsReq>InDesign</TechsReq>
                  <TechsReq>UX Research</TechsReq>
                  <TechsReq>Design Systems</TechsReq>
                </TechStackWrapper>
              </OpeningCardDetails>
              <OpeningCardActionWrapper>
                <ActionCall>
                  Apply{" "}
                  <img
                    src={RightWhite}
                    style={{
                      marginLeft: "0.5rem",
                      width: "7%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                    alt=""
                  />
                </ActionCall>
              </OpeningCardActionWrapper>
            </OpeningCard>
          </>
        </ServicesWrapper4>
      </SectionWrapper>
    </>
  )
}

export default WorkWithUsContext
