// import { Link } from "react-router-dom"
import styled from "styled-components"

export const SectionWrapper = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);
`

export const SectionWrapper3 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);
  height: auto;
  flex-direction: column;
`

export const SectionWrapper2 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 120vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 100vh;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet} {
    height: 100vh;
  }
`

export const SectionWrapper1 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);
  height: 90vh;
  z-index: 2;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 75vh;
  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro}{
    height: 75vh;
  }
`

export const InsideSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 6% 18%;
`

export const InsideSectionWrapper2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: flex-start;
  padding: 9% 12% 2% 12%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 30% 12%;
    height: auto;
  }
`

export const InsideSectionWrapper1 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 15% 18% 0% 15%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    align-items: flex-start;
    justify-content: flex-start;
    padding: 60% 12% 30% 12%;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet} {
    align-items: flex-start;
    justify-content: flex-start;
    padding: 40% 12% 30% 12%;
  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro} {
    align-items: flex-start;
    justify-content: flex-start;
    padding: 40% 12% 30% 12%;
  }
`

export const DetailedSayingWrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 2rem;
`

export const DetailedSayingWrapper1 = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 2rem;
  padding: 0% 10%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    justify-content: flex-start;
    padding: 0%;
    width: 100%;
  }
`

export const ZigZagWrapper = styled.div`
  display: grid;
  column-gap: 12%;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: auto;
  padding: 0% 18%;

  div:nth-of-type(even) {
    margin-top: 15rem;
    margin-bottom: 9rem;
    float: right;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
    height: auto;
    /* padding: 0% 18%; */
    padding: 0% 12%;
    display: flex;
    flex-direction: column;
    margin-bottom: 9rem;
    margin-top: -11rem;

    div:nth-of-type(even) {
      margin-top: 0rem;
      margin-bottom: 0rem;
      float: none;
    }
  }

  @media ${({ theme }) => theme.mediaQueries.ipad_pro}{
    padding: 0% 4%;
  }

  @media ${({ theme }) => theme.mediaQueries.tablet_landscape}{
    padding: 0% 8%;
  }
`

export const CardWrapper = styled.div`
  width: 28vw;
  height: 90vh;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
    height: 90vh;
    margin: 9rem 0rem;
  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro}{
    height: 60vh;
  }
`

export const ImageWrapper = styled.div`
  width: 100%;
  height: 75%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
    height: 75%;
  }
`
