import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
    * {
      outline: none;
      box-sizing: inherit;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
    }

    *,
    *::before,
    *::after {
      box-sizing: inherit;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    html {
      box-sizing: border-box;
      width: 100%;
      line-height: 1.2;
      font-size: 100%;
      font-weight: 400;

      --color-pri: ${(props) => props.theme.colors.pri};
      --color-sec: ${(props) => props.theme.colors.sec};
      --color-comp: ${(props) => props.theme.colors.comp};
      --color-wht: ${(props) => props.theme.colors.wht};
      --color-blk: ${(props) => props.theme.colors.blk};
      --color-strk: ${(props) => props.theme.colors.strk};

      --speed: 480ms;
    }

    body {
      background-color: #eee;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      min-height: 100%;
      overflow-x: hidden;
      overflow: visible;
    }
      
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 0 0 1rem 0;
      }
      
      p {
        margin: 0 0 0.5rem 0;
      }

      form,
      input,
      textarea,
      select,
      a {
        -webkit-tap-highlight-color: rgba(0,0,0,0);
      }

      button {
        outline: none;
        cursor: pointer;
      }
`
