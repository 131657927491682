import styled from "styled-components"
import { Link } from "react-router-dom"

export const StyledLink = styled(Link)`
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  color: ${(props) => props.color};
  text-align: ${(props) => props.textAlign};
  line-height: ${(props) => props.lineHeight};
  letter-spacing:  ${(props) => props.letterSpacing};
  word-break:  ${(props) => props.wordBreak};
  text-decoration: none;
  text-transform:  ${(props) => props.textTransformation};

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    color: rgba(var(--color-drk), 1);
    text-transform: initial;
    line-height: 1.4rem;
    font-size: 1.2rem;
    font-weight: 500;
    width: 90%;
    margin: 0% 0% 5% 0%;
`

export const StyledText = styled.h1`
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  color: ${(props) => props.color};
  text-align: ${(props) => props.textAlign};
  line-height: ${(props) => props.lineHeight};
  letter-spacing: ${(props) => props.letterSpacing};
  word-break: ${(props) => props.wordBreak};
  text-decoration: none;
  text-transform: ${(props) => props.textTransformation};
  background-color: ${(props) => props.backgroundColor};
  border-radius: ${(props) => props.borderRadius};
  border: ${(props) => props.border};

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: ${(props) => props.fontSizeMobile};
    margin: ${(props) => props.marginMobile};
    font-weight: ${(props) => props.fontWeightMobile};
    padding: ${(props) => props.paddingMobile};
    text-align: ${(props) => props.textAlignMobile};
    border-radius: ${(props) => props.borderRadiusMobile};
    width: ${(props) => props.widthMobile};
  }
`

export const StyledAnchor = styled.a`
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  color: ${(props) => props.color};
  text-align: ${(props) => props.textAlign};
  line-height: ${(props) => props.lineHeight};
  letter-spacing: ${(props) => props.letterSpacing};
  word-break: ${(props) => props.wordBreak};
  text-decoration: none;
  text-transform: ${(props) => props.textTransformation};
  background-color: ${(props) => props.backgroundColor};
  border-radius: ${(props) => props.borderRadius};
  border: ${(props) => props.border};
  cursor: pointer;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: ${(props) => props.fontSizeMobile};
    margin: ${(props) => props.marginMobile};
    font-weight: ${(props) => props.fontWeightMobile};
    padding: ${(props) => props.paddingMobile};
    text-align: ${(props) => props.textAlignMobile};
    border-radius: ${(props) => props.borderRadiusMobile};
    width: ${(props) => props.widthMobile};
  }
`
