import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import reportWebVitals from "./reportWebVitals"

// Styles
import { ThemeProvider } from "styled-components"
import theme from "./utils/theme"
import { GlobalStyle } from "./utils/global"
import "./assets/fonts/Roboto/stylesheet.css"
import "./assets/fonts/Graphik/stylesheet.css"
import "./utils/locomotive-scroll.css"

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <App />
      </>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
