import { useState } from "react"
import Layout from "./utils/Layout"
import SnackbarComponent from "./components/Snackbar"
import LedgerScreen from "./screen/ledgerScreen"
import useXData from "./utils/useXData"

import { ThemeProvider } from "styled-components"
import { GlobalStyle } from "./utils/global"
import theme from "./utils/theme"

export default function LedgerApp() {
  const {
    isLoggedIn,
    userId,
    userToken,
    clearToken,
    clearUserId,
    clearIsLoggedIn,
  } = useXData()
  const [istatus, setStatusBase] = useState(null)
  const setStatus = (msg, prostats) =>
    setStatusBase({ msg, prostats, date: new Date() })

  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        {istatus !== null ? (
          <>
            <SnackbarComponent
              key={istatus.date}
              prostats={istatus.prostats}
              msg={istatus.msg}
              setStatusBase={setStatusBase}
            />
          </>
        ) : null}

        <Layout
          isLoggedIn={isLoggedIn}
          userId={userId}
          userToken={userToken}
          clearToken={clearToken}
          clearUserId={clearUserId}
          clearIsLoggedIn={clearIsLoggedIn}
          setStatus={setStatus}
        >
          <LedgerScreen
            isLoggedIn={isLoggedIn}
            userId={userId}
            userToken={userToken}
            setStatus={setStatus}
          />
        </Layout>
      </ThemeProvider>
    </>
  )
}
