import React from "react"
import styled from "styled-components"
import goshoppi1 from "../../assets/images/Goshoppi1.png"
import goshoppi2 from "../../assets/images/Goshoppi2.png"
import goshoppi3 from "../../assets/images/Goshoppi3.png"
import cashewBanner from "../../assets/images/cashewBanner.jpeg"
import goshoppi5 from "../../assets/images/goshoppi5.png"
import goshoppi6 from "../../assets/images/goshoppi6.png"
import drjcashewlogo from "../../assets/images/drjcashewlogo.png"
import { scroll } from "../../utils/locomotiveScroll"
import cashew_banner from "../../assets/images/cashew_banner.jpeg"
import cashew5 from "../../assets/images/cashew5.jpg"
import right_arr from "../../assets/images/right_arr.png"
import { AddressContactText } from "../../building-blocks/atoms/containers/FooterContainer"
import TypeOfNutrients from "../../assets/images/TypeOfNutrients.jpg"
import WW180 from "../../assets/images/WW180.jpg"
import WW240 from "../../assets/images/WW240.jpg"
import WW320 from "../../assets/images/WW320.jpg"
import LWP from "../../assets/images/LWP.jpg"
import SWP from "../../assets/images/SWP.jpg"

const LIST_IMAGE = [
    {
      src: goshoppi1,
    },
    {
      src: goshoppi2,
    },
    {
      src: goshoppi3,
    },
    
  ]
  
//   const LIST_IMAGE2 = [
//     {
//       src: kirana10,
//     },
//   ]
  
  export const BannerWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--color-blk);
  `
  
  export const BannerOverlayWrapper = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to left,rgba(18,0,255,0.1),#ad5541);
  
    @media ${({ theme }) => theme.mediaQueries.mobile} {
      justify-content: flex-start;
      padding: 40% 0%;
    }
  `
  
  export const BannerOverlayTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 38vh;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0% 12%;
  
    @media ${({ theme }) => theme.mediaQueries.mobile} {
      justify-content: center;
      height: 30vh;
    }
  `
  export const ActionFirst = styled.div`
    display: flex;
    width: 100%;
    height: 28vh;
    align-items: center;
    justify-content: space-between;
    padding: 0% 12%;
  
    @media ${({ theme }) => theme.mediaQueries.mobile} {
      font-weight: 400;
      font-family: roboto;
      font-size: 1.5rem;
      width: 100%;
      line-height: 2.4rem;
      margin: 0%;
    }
  `
  
  export const ActionSecond = styled.div`
    display: flex;
    width: 100%;
    height: 15vh;
    align-items: center;
    justify-content: center;
    padding: 0% 12%;
  `
  
  export const PrevNextWrapper = styled.div`
    display: flex;
    width: auto;
    align-items: center;
    justify-content: center;
  
    @media ${({ theme }) => theme.mediaQueries.mobile} {
      padding: 0% 12%;
    }
  `
  
  export const SubActWrapper = styled.div`
    // display: flex;
    width: auto;
    align-items: center;
    justify-content: center;
  `
  
  export const TitleBg = styled.div`
    background: var(--color-sec);
    width: 6%;
    height: 1.2rem;
    position: relative;
    top: -3.5%;
    left: -1.5%;
  
    @media ${({ theme }) => theme.mediaQueries.mobile} {
      display: none;
    }
  `
  
  ///////////////////////////////////////////////
  
  export const BannerOverlaySubtext = styled.p`
    color: white;
    font-family: roboto;
    font-size: 0.8rem;
    text-transform: capitalize;
    letter-spacing: 0.3rem;
    font-weight: 600;
  
    @media ${({ theme }) => theme.mediaQueries.mobile} {
      z-index: 1;
      font-size: 0.7rem;
      letter-spacing: 0.2rem;
      opacity: 0.8;
    }
  `
  
  export const BannerOverlayText = styled.h1`
    color: white;
    font-family: graphik;
    font-size: 3.2rem;
    letter-spacing: 0.1rem;
    font-weight: 700;
    width: 40%;
    margin-top: 2rem;
  
    @media ${({ theme }) => theme.mediaQueries.mobile} {
      font-weight: 800;
      font-size: 2rem;
      width: 100%;
      line-height: 3.5rem;
      margin-top: 2rem;
    }
  `
  
  export const BannerOverlayText2 = styled.h1`
    width: 100%;
    font-weight: 300;
    font-family: roboto;
    font-size: 3.2rem;
    color: black;
  
    @media ${({ theme }) => theme.mediaQueries.mobile} {
      font-weight: 400;
      font-family: roboto;
      font-size: 1.2rem;
      width: 100%;
      line-height: 2.4rem;
      margin: 0%;
      letter-spacing: 0.08rem;
    }
  `
  
  export const ActionLinks = styled.p`
    color: white;
    font-size: 0.8rem;
    font-family: "Roboto";
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.1rem;
    line-height: 1.3rem;
  `
  
  export const ActionLinks2 = styled.p`
    color: white;
    font-family: "Roboto";
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    margin-right: ${(props) => (props.left ? "7rem" : "0rem")};
    font-size: 0.7rem;
    font-weight: 400;
  
    @media ${({ theme }) => theme.mediaQueries.mobile} {
      text-transform: none;
      font-size: 0.8rem;
      font-weight: 400;
      line-height: 2;
      width: 92%;
      letter-spacing: 0.08rem;
    }
  `
  
  ////////////////////////////////////////////
  
  export const BannerImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
  
    @media ${({ theme }) => theme.mediaQueries.mobile} {
      height: 100%;
      width: 100%;
      margin-top: 0%;
      object-fit: cover;
      margin-right: 0%;
    }
  `
  
  /////////////////////////////////////////////////
  
  export const SectionWrapper1 = styled.div`
    color: white;
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: var(--color-comp);
    height: 90vh;
    z-index: 2;
  `
  
  export const SectionWrapper2 = styled.div`
    color: white;
    display: flex;
    width: 100%;
    height: 120vh;
    align-items: center;
    justify-content: center;
    background-color: var(--color-comp);
  
    @media ${({ theme }) => theme.mediaQueries.mobile} {
      height: 100vh;
    }
  `
  
  export const BackImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
  
    @media ${({ theme }) => theme.mediaQueries.mobile} {
      height: 100%;
      width: auto;
    }
  `
  
  export const InsideSectionWrapper1 = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 15% 18% 0% 15%;
  `
  
  export const BannerOverlayText1 = styled.h1`
    color: white;
    font-family: graphik;
    font-size: 3.5rem;
    letter-spacing: 0.1rem;
    font-weight: 700;
    width: 90%;
    margin-top: 6rem;
  `
  
  export const BannerOverlaySubtext1 = styled.p`
    color: white;
    font-family: roboto;
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    font-weight: 600;
    padding: 0% 3%;
  `
  
  export const SectionWrapper3 = styled.div`
    color: white;
    display: flex;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
    background-color: var(--color-comp);
    height: 120vh;
    z-index: 2;
    position: relative;
  
    padding: 12% 14%;
    background-color: rgb(225, 230, 236);
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  
    @media ${({ theme }) => theme.mediaQueries.mobile} {
      padding: 30% 12%;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column-reverse;
    }
  `
  
  export const ServicesWrapper1 = styled.div`
    width: 100%;
    height: inherit;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 6% 30% 14%;
    background-color: var(--color-wht);
    position: absolute;
  
    padding: 0;
    width: 65%;
    position: unset;
    background-color: transparent;
    justify-content: center;
    align-items: flex-start;
  
    @media ${({ theme }) => theme.mediaQueries.mobile} {
      width: 100%;
      height: auto;
      margin-bottom: 12vh;
    }
  `
  
  export const BannerOverlayText3 = styled.h1`
    color: white;
    font-family: roboto;
    font-size: 3.2rem;
    letter-spacing: 0.1rem;
    font-weight: 300;
    margin-top: 5rem;
    width: 100%;
    color: black;
  
    @media ${({ theme }) => theme.mediaQueries.mobile} {
      font-weight: 400;
      font-family: roboto;
      font-size: 1.2rem;
      width: 60%;
      line-height: 2.4rem;
      margin: 0%;
      letter-spacing: 0.08rem;
    }
  `
  
  export const ActionLinks1 = styled.p`
    color: white;
    font-family: "Roboto";
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.1rem;
  
    text-transform: none;
    line-height: 2;
    font-weight: 300;
    width: 100%;
    letter-spacing: 0.02rem;
    color: black;
    margin-top: 3rem;
    font-size: 1rem;
  
    @media ${({ theme }) => theme.mediaQueries.mobile} {
      text-transform: none;
      font-size: 0.8rem;
      font-weight: 400;
      line-height: 2;
      font-weight: 300;
      width: 92%;
      letter-spacing: 0.08rem;
    }
  `
  
  export const SectionWrapper = styled.div`
    color: white;
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: var(--color-comp);
  `
  
  export const SectionWrapper10 = styled.div`
    color: white;
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: var(--color-comp);
  
    height: 100vh;
    z-index: 6;
    position: relative;
  
    @media ${({ theme }) => theme.mediaQueries.mobile} {
      height: 70vh;
      z-index: 6;
      position: relative;
    }
  `
  
  export const SectionWrapper9 = styled.div`
    color: white;
    display: flex;
    width: 100%;
    height: 160vh;
    align-items: center;
    justify-content: center;
    background-color: rgb(225, 230, 236);
  
    @media ${({ theme }) => theme.mediaQueries.mobile} {
      align-items: flex-start;
      height: 110vh;
    }
  `
  
  export const SectionWrapper6 = styled.div`
    color: white;
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: var(--color-comp);
  
    height: 172vh;
    z-index: 2;
    position: relative;
  `
  
  export const SectionWrapper7 = styled.div`
    color: white;
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: var(--color-comp);
  
    height: 530vh;
    z-index: 6;
    position: relative;
  `
  
  export const SectionWrapper4 = styled.div`
    color: white;
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: rgb(225, 230, 236);
  
    height: 78vh;
    z-index: 2;
    position: relative;
    height: auto
  
    @media ${({ theme }) => theme.mediaQueries.mobile} {
      height: 55vh;
    }
  `
  
  export const SectionWrapper5 = styled.div`
    color: white;
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: rgb(225, 230, 236);
  
    height: 100vh;
    z-index: 2;
    position: relative;
  
    @media ${({ theme }) => theme.mediaQueries.mobile} {
      height: 80vh;
    }
  `
  
  export const SectionWrapper8 = styled.div`
    color: white;
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: rgb(225, 230, 236);
  
    height: 40vh;
    z-index: 2;
    position: relative;
  
    @media ${({ theme }) => theme.mediaQueries.mobile} {
      height: 55vh;
    }
  `
  
  export const ServicesWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: inherit;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  
    background-color: rgb(225, 230, 236);
    padding: 10% 18% 0%;
  
    @media ${({ theme }) => theme.mediaQueries.mobile} {
      padding: 30% 12%;
      justify-content: center;
    }
  `
  
  export const ServicesWrapper3 = styled.div`
    position: absolute;
    width: 100%;
    height: inherit;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  
    background-color: rgb(225, 230, 236);
    padding: 5% 7% 5%;
    flex-direction: row;
    justify-content: space-between;
  
    @media ${({ theme }) => theme.mediaQueries.mobile} {
      padding: 30% 12%;
      justify-content: center;
    }
  `
  
  export const ServicesWrapper2 = styled.div`
    position: absolute;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  
    background-color: rgb(225, 230, 236);
    padding: 0% 12% 14%;
  
    @media ${({ theme }) => theme.mediaQueries.mobile} {
      padding: 10% 12%;
      justify-content: center;
    }
  `
  
  const GridWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: auto;
    z-index: 2;
    flex-wrap: wrap;
    flex-direction: column;
    position: absolute;
    background-color: rgb(225, 230, 236);
  
    height: 100%;
    padding: 0% 18%;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
  
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  
    @media ${({ theme }) => theme.mediaQueries.mobile} {
      padding: 7%;
    }
  `
  
  const Img = styled.img`
    width: 30%;
    padding: 2%;
    height: auto;
  
    @media ${({ theme }) => theme.mediaQueries.mobile} {
      width: 48%;
      padding: 4%;
      height: auto;
    }
  `
  
  // const ImgWrapper = styled.div`
  //   display: flex;
  //   width: 100%;
  //   z-index: 2;
  //   position: absolute;
  //   background-color: rgb(225, 230, 236);
  //   height: 100%;
  //   padding: 0% 10%;
  //   flex-direction: column;
  //   align-items: flex-start;
  //   justify-content: flex-start;
  //   position: absolute;
  
  //   display: flex;
  //   width: 100%;
  //   z-index: 2;
  //   position: absolute;
  //   background-color: rgb(225, 230, 236);
  //   height: 100%;
  //   padding: 0% 10%;
  //   flex-direction: column;
  //   align-items: center;
  //   justify-content: center;
  //   position: absolute;
  
  //   @media ${({ theme }) => theme.mediaQueries.mobile} {
  //     align-items: center;
  //     justify-content: center;
  //   }
  // `
  
  // const Img2 = styled.img`
  //   width: 38%;
  //   height: auto;
  //   margin-bottom: 9%;
  
  //   @media ${({ theme }) => theme.mediaQueries.mobile} {
  //     width: 100%;
  //     height: auto;
  //     margin-bottom: 9%;
  //   }
  // `
  
  const LogoImage = styled.img`
    width: 30%;
    height: auto;
    object-fit: cover;
    object-fit: cover;
    
    // &:hover {
    //   background-color: #b4632a;
    // }
  
    @media ${({ theme }) => theme.mediaQueries.mobile} {
      width: 60%;
      height: auto;
      object-fit: cover;
    }
  `

  const ActionButtonWrapper = styled.div`    display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: auto;
  margin-top: 3%;
  `


  const ActionButton = styled.div`display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 19%;
    height: auto;
    padding: 4%;
    background-color: var(--color-sec);
    font-size: 1rem;
    font-weight: 500;
    color: white;
    padding: 2% 3%;
    width: auto;
    align-items: center;
    font-family: Graphik;
    cursor: pointer;
    `

const Modal = styled.div`
    position: fixed;
    top: 14vh;
    left: 15vw;
    width: 70vw;
    height: 80vh;
    background-color: var(--color-wht);
    z-index: 120;
    display: flex;
    box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 20%);
    
    align-items: center;
    justify-content: center;
    padding: 2%;
    `

const Spans = styled.span`
font-weight: 500;
font-size: 1.2rem;    
`
  
  function MoshoppiContext() {
    React.useEffect(() => {
      scroll()
    }, [])
  
    const Data = [
      {
        id: "1",
        img: TypeOfNutrients
      },
      {
        id: "2",
        img: WW180
      },
      {
        id: "3",
        img: WW240
      },
      {
        id: "4",
        img: WW320
      },
      {
        id: "5",
        img: LWP
      },
      {
        id: "6",
        img: SWP
      }
    ]

    const [onShow, setOnShow] = React.useState({
      show: false,
      data: null
    })

    const handleShow = (id) => {
      const findFrom = Data.find(
        (item) => id === item.id
      )
      console.log(findFrom)
      setOnShow({
        show: true,
        data: findFrom
      })
    }

    return (
      <>
        <BannerWrapper data-scroll-section>
          <BannerImg src={cashew_banner} alt="" data-scroll data-scroll-speed="-3" />
          <BannerOverlayWrapper>
            <BannerOverlayTextWrapper data-scroll data-scroll-speed="-2">
              <BannerOverlaySubtext style={{ zIndex: "2" }}>
              Agro - Retail
              </BannerOverlaySubtext>
  
              <BannerOverlayText>Dr J's Cashew</BannerOverlayText>
            </BannerOverlayTextWrapper>
            <>
              <ActionFirst data-scroll data-scroll-speed="-1">
                <SubActWrapper>
                  <ActionLinks style={{
                        width: "50%"
                  }}>Dr. Jʼs Cashew, established by a founder with deep experience in the agricultural products industry,
                    <br />
                  Dr. Jʼs Cashew has been well-known as one of the Indiaʼs leading and most prominent manufacturer, 
                  exporter and distributor of cashew nuts & products.</ActionLinks>
                  <ActionLinks style={{
                        marginTop: "2rem"
                  }}>India</ActionLinks>
                </SubActWrapper>
                {/* <PrevNextWrapper>
                  <ActionLinks2 left>prev</ActionLinks2>
                  <ActionLinks2>next</ActionLinks2>
                </PrevNextWrapper> */}
              </ActionFirst>
              <ActionSecond
                style={{
                  position: "absolute",
                  bottom: "-5rem",
                }}
                data-scroll
                data-scroll-speed="-2"
              >
                <SubActWrapper>
                  <ActionLinks style={{ letterSpacing: "0.28rem" }}>
                    scroll down
                  </ActionLinks>
                </SubActWrapper>
              </ActionSecond>
            </>
          </BannerOverlayWrapper>
        </BannerWrapper>
  
        <SectionWrapper3 data-scroll-section>
          <LogoImage src={drjcashewlogo} alt="" />
          <ServicesWrapper1>
            <>
              <BannerOverlayText3 data-scroll data-scroll-speed="1">
              Dr. Jʼs Cashew – is a brand specializing in the production, distribution and export of cashew 
nuts.
              </BannerOverlayText3>
            </>
            <ActionLinks1 data-scroll data-scroll-speed="1">
            Our factories are located in raw material areas so that we purchase materials directly from farmers. 
Besides, we also save cost of transportation thus we give competitive price to our customers. Our 
factories comply with quality management and food safety management system, so that we process 
              and deliver the food of safety and high quality products, which meets customers requirement.
<br />
<br />
              With
well-trained experienced staff and modern equipment’s we provide high quality products to our 
customers. Our factories with modern machinery and to fulfil the safety and quality requirements 
includes X-ray, color sorter, metal detector and grading lines controlled by professionals. We can 
produce the cleanest cashew suitable for the customer’s demands.
            </ActionLinks1>
            {/* <AddressContactText target="_blank" href="https://www.mshopi.com/" style={{color: '#333', fontWeight: 'bold'}}>
              VISIT WEBSITE &rarr;
            </AddressContactText> */}
          </ServicesWrapper1>
        </SectionWrapper3>
  
        <SectionWrapper2 data-scroll-section>
          <BackImg
            src={cashewBanner}
            style={{ height: "130vh" }}
            alt=""
            data-scroll
            data-scroll-speed="-5"
          />
        </SectionWrapper2>
  
        <SectionWrapper8 data-scroll-section style={{ marginBlock: "-0.1rem" }}>
          <ServicesWrapper>
            <>
              <BannerOverlayText2 data-scroll data-scroll-speed="1">
              Process of Producing Cashew Nuts in India.
              </BannerOverlayText2>
            </>
          </ServicesWrapper>
        </SectionWrapper8>
  
       
  
        <SectionWrapper4 data-scroll-section style={{ marginBlock: "-0.5rem" }}>
          <ServicesWrapper3>
          <ActionLinks1 data-scroll data-scroll-speed="1">
          <Spans>Collection & Drying:</Spans> The raw cashew nuts (RCN) are collected from the farmers and dried in the sun 
to remove excess moisture. It is then subjected to four-step processing before packing, which includes 
              the steam roasting, shell cutting, peeling, and grading.
                <br />
                <br />
              <Spans>Shell Cutting:</Spans> After Steam Roasting is complete, the cashew nuts are peeled off quickly to remove the
outer shell to get the inner kernel. This is a very difficult stage, requiring skilled workers to separate 
the shell without breaking the inner core. After the peeling process, we will get 2 products: raw 
cashew shell, cashew nut kernel with soft shell (husk testa).
            </ActionLinks1>

            <img
              style={{ width: "50%", height: "100%", objectFit: "cover", marginLeft: "10%" }}
              src={cashew5}
              alt=""
            />
          </ServicesWrapper3>
        </SectionWrapper4>

        
  
  <SectionWrapper4 data-scroll-section style={{ marginBlock: "-0.5rem" }}>
    <ServicesWrapper3>
    <ActionLinks1 data-scroll data-scroll-speed="1">
    <Spans>Steam Roasting:</Spans> The outer shell of the raw cashew nuts has to be removed to produce the edible 
cashew kernel. The outer shell of the cashew is very hard and it contains a corrosive oil that is harmful 
for human consumption. The process of steam roasting helps in the removal of this hard-shell with 
minimal effort. The raw cashew nuts are put in a drum connected to a mini boiler. The steam from this 
mini boiler is passed over the cashew nuts placed in the drum for a period of 10 -15 minutes. These 
cashew nuts are left in the drum for 20 minutes for proper roasting. The roasted cashew nuts are then 
taken out of the drum and placed in the open air for a period of around 12 hours to let them cool down 
and help in the removal of the cashew shells. 
      </ActionLinks1>

      <ActionLinks1 data-scroll data-scroll-speed="1" 
              style={{ marginLeft: "10%" }}>
      <Spans>Peeling:</Spans> a process where the inner shell of the kernel is removed so as to produce white nuts. The 
peeling process is designed to remove the softshell, which is enhanced by the cold treatment. This step 
of agro-processing will give out the white-colored cashew nuts that are sent to the grading 
department. In which, the Raw Cashew Kernels are passed through the classification & grading 
              department to consider their level.
            <br />
            <br />
              <Spans>Grading:</Spans> The cashew nut classification process is designed to classify cashew nuts kernel nuts into
different grades for export. Kernel nuts are divided into two main categories: “Whole Nuts” and 
“Broken Nuts”. In the export of whole cashew nuts kernel, whole nuts are divided into 15 different 
grades and broken nuts are divided into 9 levels.
      </ActionLinks1>
    </ServicesWrapper3>
  </SectionWrapper4>

<SectionWrapper5 data-scroll-section style={{ marginBlock: "-0.1rem" }}>
          <ServicesWrapper2>
            <>
              <BannerOverlayText2 style={{fontSize:"2rem"}} data-scroll data-scroll-speed="1">
              Following are 
the Nutrition In Cashew,
                <br />
                <ActionButtonWrapper>
                  <ActionButton onClick={() => handleShow("1")}>Nutrition <img style={{
                    width: "100%",
                    height: "2rem"
                  }} src={right_arr} alt="" /></ActionButton> 
                </ActionButtonWrapper>
                <br />
                <br />
              Following are 
the Types Of Cashews we produce, <br />
                <ActionButtonWrapper>
                  <ActionButton onClick={() => handleShow("2")}>WW180 Cashew</ActionButton>
                  <ActionButton onClick={() => handleShow("3")}>WW240 Cashew</ActionButton>
                  <ActionButton onClick={() => handleShow("4")}>WW320 Cashew</ActionButton>
                  <ActionButton onClick={() => handleShow("5")}>LWP Cashew</ActionButton>
                  <ActionButton onClick={() => handleShow("6")}>SWP Cashew</ActionButton>
                </ActionButtonWrapper><br />
              </BannerOverlayText2>
             
            </>
          </ServicesWrapper2>
        </SectionWrapper5>
        
        {onShow.show && onShow.data !== null && <Modal><img src={onShow.data.img} alt="" style={{
          width: "100%",
    height: "100%",
    objectFit: "contain"
          }} />
           <ActionLinks style={{ letterSpacing: "0.28rem", color: "black", position: "absolute",
    top: "6%",
    right: "4%",
    zIndex: 3,
    cursor: "pointer" }} onClick={() => setOnShow({
            show: false,
            data: null
        })}>close
                  </ActionLinks>
        </Modal>}
      </>
    )
  }
  
  export default MoshoppiContext