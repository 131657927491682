import styled from "styled-components";

export const ProfileHeader = styled.div`
  position: absolute;
  width: auto;
  height: auto;
  top: 3%;
  right: 4%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;

  @media ${({ theme }) => theme.mediaQueries.small} {
    right: 6%;
    top: 7%;
  }
`;

export const ProfileIcons = styled.div`
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(var(--color-drk), 1);
  cursor: pointer;
  transition: all var(--speed);
  opacity: 0.7;
  font-size: 1rem;
  font-weight: 500;

  &:hover {
    opacity: 1;
  }
`;

export const ProfileNotifsDrop = styled.div``;

export const ProfileDrop = styled.div`
  position: absolute;
  width: 18vw;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.7rem 1rem;
  z-index: 120;
  background-color: white;
  border: 1px solid var(--blk4);
  border-radius: 0.2rem;
  box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 8%);

  @media ${({ theme }) => theme.mediaQueries.small} {
    top: 13%;
    right: 6%;
  }
`;

export const DropTexts = styled.p`
  cursor: pointer;
  color: rgba(var(--color-drk), 1);
  // font-family: Poppins;
  font-size: 0.78rem;
  text-transform: initial;
  font-weight: 400;
  line-height: 1.8rem;
  margin: 0%;

  @media ${({ theme }) => theme.mediaQueries.small} {
    font-weight: 600;
  }
`;
