import styled from "styled-components"

export const SectionWrapper = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);
`

export const SectionWrapper1 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);

  z-index: 2;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 100vh;
  }
`

export const InsideSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 6% 18%;
`

export const InsideSectionWrapper2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12% 24%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    align-items: flex-start;
    padding: 0% 12%;
    justify-content: center;
  }
`

export const InsideSectionWrapper1 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 12% 15% 0%;
  align-items: flex-start;
  justify-content: flex-start;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 50% 12% 30%;
    align-items: flex-start;
    justify-content: flex-start;
  }
`
