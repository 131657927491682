import { Subject } from "rxjs"

const subject = new Subject()

export const MessageService = {
  authDataToXData: (token, userData, status) =>
    subject.next({
      eventType: "AUTH_TO_XDATA",
      token,
      userData,
      status,
    }),
  initializeEvent: () => subject.asObservable(),
}
