import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
  * {
    outline: none;
    box-sizing: inherit;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  }
  *::-webkit-scrollbar {
    width: 0.3vw;
    height: 0.3vw;
  }
  *::-webkit-scrollbar-track {
    background: transparent;
  }
  *::-webkit-scrollbar-thumb {
    background: rgba(var(--color-drk),0.2);
    border-radius: 5rem;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    box-sizing: border-box;
    width: 100%;
    line-height: 1.2;
    font-size: 100%;
    font-weight: 400;
    --wht: ${(props) => props.theme.colors.wht};
    --blk1: ${(props) => props.theme.colors.blk1};
    --blk2: ${(props) => props.theme.colors.blk2};
    --blk3: ${(props) => props.theme.colors.blk3};
    --blk4: ${(props) => props.theme.colors.blk4};
    --blk5: ${(props) => props.theme.colors.blk5};
    --gold1: ${(props) => props.theme.colors.gold1};
    --gold2: ${(props) => props.theme.colors.gold2};
    --gold3: ${(props) => props.theme.colors.gold3};
    --gold4: ${(props) => props.theme.colors.gold4};
    
    font-family: Poppins;
  }

  body {
    // user-select: none;
    background-color: ${(props) => props.theme.colors.wht};
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    min-height: 100%;
    overflow-x: hidden;
    overflow: visible;
    scroll-behavior: smooth;
    font-family: Poppins, Arial, Helvetica, sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 1rem 0;
    color:  ${(props) => props.theme.colors.blk3};
    font-family: Poppins;
  }

  p {
    margin: 0 0 0.5rem 0;
    color:  ${(props) => props.theme.colors.blk1};
    font-family: Poppins;
  }

  form,
  input,
  textarea,
  select,
  a {
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-family: Poppins;
  }

  button {
    outline: none;
    cursor: pointer;
    font-family: Poppins;
    background-color: ${(props) => props.theme.colors.wht};
  }
`
