import styled from "styled-components"

export const SectionWrapper = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 80vh;
  }

  @media ${({ theme }) => theme.mediaQueries.ipad_pro} {
    height: 70vh; 
  }
  @media ${({ theme }) => theme.mediaQueries.tablet} {
    height: 80vh; 
  }
`

export const ServicesWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 18% 22% 0% 22%;
  background-color: transparent;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 0% 12%;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    padding: 8% 18%;
  }
`

export const BackImg = styled.img`
  height: auto;
  position: absolute;
  top: 0%;
  left: 0%;
  opacity: 0.05;
  background-image: linear-gradient(transparent, rgb(255, 255, 255));

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 80vh;
    object-fit: cover;
    width: 100%;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    width: 100vw;
    height: 100vh;
  }

  @media ${({ theme }) => theme.mediaQueries.ipad_pro} {
    height: 55vh; 
  }
  @media ${({ theme }) => theme.mediaQueries.tablet} {
    height: 80vh; 
  }
`

export const FooterWrapper1 = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  z-index: 2;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet} {
    flex-direction: row;
  }
`
export const FooterWrapper2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 40%;
  margin-top: 4rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
    flex-direction: unset;
    flex-wrap: wrap;
    height: auto;
    justify-content: space-between;
    align-items: center;

    display: grid;
    grid-template-columns: 50% 50%;

    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    margin-top: 2%;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet}{
    display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 40%;
  margin-top: 1.2rem;
  } 
`
export const FooterWrapper3 = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10%;
  @media ${({ theme }) => theme.mediaQueries.mobile}{
    display: block;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    align-items: flex-start;
  }
`
export const CopyrightSectionWrapper = styled.p`
  transform: rotate(-90deg);
  position: absolute;
  left: 0%;
  @media ${({ theme }) => theme.mediaQueries.mobile} {
    transform: rotate(0deg);
    margin-top: 178%;
    margin-left: 3rem;
    font-size: 0.7rem

  }
  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    font-size: 0.7rem;
    margin-left: 3rem;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet} {
  transform: rotate(90deg);
  margin-top: -9.5rem;
  margin-left: -1rem;
  font-size: 0.7rem;
  }

`
export const FooterWrapper4 = styled.div`
  // display: flex;
  padding-bottom: 4rem;
  @media ${({ theme }) => theme.mediaQueries.mobile}{
    display: block;
    padding-top: 1rem;
    padding-bottom: 0rem;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet}{
    display: block;
    padding-top: 0rem;
  }
  
`
export const AddressContactText = styled.a`
  color: white;
  font-size: 0.8rem;
  font-family: "Roboto";
  font-weight: 400;
  letter-spacing: 0rem;
  opacity: 0.5;
  line-height: 1.2rem;
  opacity: 0.9;
  padding-right: 1rem;
  text-decoration: none;
  &:hover {
    color: var(--color-pri);
  }

  &:focus {
    color: var(--color-pri);
  }

  &:active {
    color: var(--color-pri);
  }
`
export const FooterLogo = styled.div`
display: none;
@media ${({ theme }) => theme.mediaQueries.mobile}{
  display: block;
  padding-top: 2rem;
}
@media ${({ theme }) => theme.mediaQueries.tablet} {
  display: none; 
}
`