import { Link } from "react-router-dom"
import styled from "styled-components"

export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: var(--color-comp);
`

export const BannerWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--color-blk);
`

export const SectionWrapper = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 128vh;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    height: 140vh;
  }

  @media ${({ theme }) => theme.mediaQueries.ipad_pro} {
    height: 70vh;
  }

  @media ${({ theme }) => theme.mediaQueries.tablet} {
    height: 70vh;
  }

`

export const SectionWrapper2 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);

  height: auto;
  margin-top: -2rem;
  z-index: 2;

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    margin-top: 0%;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet}{

  }

`

export const SectionWrapperImg = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  min-height: 100%;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-position: center;
  background-size: cover;
`

export const HorizontalScrollableSectionWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(75, calc(100% - 36%));
  column-gap: 9%;
  padding-left: 35rem;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  
`

export const HorizontalScrollableSectionWrapper2 = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(75, calc(100% - 36%));
  column-gap: 9%;
  padding-left: 35rem;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  
  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
  margin-bottom: 4rem;
  cursor: grab;
  padding-left: 18%;
  column-gap: 8%;
  grid-template-columns: repeat(75,calc(100% - 68%));
 
  }
  @media ${({ theme }) => theme.mediaQueries.tablet}{
    grid-template-columns: repeat(75,calc(135%));
    padding-left: 20rem;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet_landscape}{
    grid-template-columns: repeat(75,calc(135%));
  }

  @media ${({ theme }) => theme.mediaQueries.ipad_pro}{
    grid-template-columns: repeat(75,calc(135%));
  }

`

export const InsideSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 6% 18%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 30% 12%;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    align-items: flex-start;
  }
`

export const BannerOverlayWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`

export const BannerOverlayTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60vh;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0% 12%;
`
export const ActionFirst = styled.div`
  display: flex;
  width: 100%;
  height: 30vh;
  align-items: center;
  justify-content: space-between;
  padding: 0% 12%;
`

export const ActionSecond = styled.div`
  display: flex;
  width: 100%;
  height: 15vh;
  align-items: center;
  justify-content: center;
  padding: 0% 12%;
`

export const ActionSecond2 = styled.div`
  display: flex;
  width: 100%;
  height: 15vh;
  align-items: center;
  justify-content: center;
  padding: 0% 12%;

  justify-content: flex-start;
  padding: 0% 22%;
  margin-top: -1rem;
  margin-left: 4rem;

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    justify-content: flex-start;
    margin-top: -1rem;
    padding: 0% 8%;
  }
`

export const PrevNextWrapper = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;
`

export const SubActWrapper = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;
`

export const SubActWrapper2 = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;

  margin-left: 5rem;

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    margin-left: 0%;
  }
`

export const SayWrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: flex-start;
`

export const SayingWrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: flex-start;
  margin: 6rem 0rem 4rem 0rem;
  width: 100%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    margin: 0%;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    margin: 0%;
  }
`
export const DetailedSayingWrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 2rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    justify-content: flex-start;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    justify-content: flex-start;
  }
`

export const ContentWrapper = styled.div`
  width: 100%;
  height: auto;
  display: block;
`

export const ContentWrapperLink = styled(Link)`
  width: 100%;
  height: auto;
  display: block;
  text-decoration: none;
  @media ${({ theme }) => theme.mediaQueries.tablet}{
    height: auto;
    width: 540px;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet_landscape}{
    width: 550px;
  }
  
`

export const CardImageWrapper = styled.div`
  width: 100%;
  height: auto;
`

export const CardTextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  align-items: flex-start;
  margin: 6rem 0rem 8rem 0rem;

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    margin: 3rem 0rem 2rem 0rem;
    
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    margin: 0%;
    margin-top: 12%;

  }
`

export const CardHeaderWrapper = styled.div`
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  margin-bottom: 3rem;
`

export const CardHeaderWrapper2 = styled.div`
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  margin-bottom: 3rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    margin-bottom: 2rem;
    align-items: flex-start;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    margin-bottom: 0%;
    align-items: flex-start;
  }
`

export const CardSubtextWrapper = styled.div`
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  display: flex;
`

export const ServicesWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6% 12%;
`


export const BannerOverlayText = styled.h1`
  color: white;
  font-family: graphik;
  font-size: 3.5rem;
  letter-spacing: 0.1rem;
  font-weight: 700;
  // width: 40%;
  margin-top: 12%;
  width: 75%;
  margin-top: 6rem;
  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 1.3rem;
    width: 100%;
    margin-top: 3rem;
    line-height: 3rem;

  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    // display: none;
    font-size: 1.3rem;
    width: auto;
    margin-top: 3rem;
    line-height: 3rem;

  }

  @media ${({ theme }) => theme.mediaQueries.ipad_pro}{
    width: 110%;
    font-size: 3.5rem;
    margin-top: 30%;
  }

  @media ${({ theme }) => theme.mediaQueries.tablet}{
    font-size: 2.8rem;
    width: 100%;
    margin-top: 3rem;

  }
  @media ${({ theme }) => theme.mediaQueries.tablet_landscap}{
    font-size: 3rem;
    width: 108%;
  }

`