import React from "react"
import styled from "styled-components"
import {
  InsideSectionWrapper,
  SectionWrapper,
  ServicesWrapper,
} from "../../building-blocks/atoms/containers/containers"
import { BackImg } from "../../building-blocks/atoms/images/images"
import {
  BannerOverlayText,
  BannerOverlaySubtext,
  // ActionLinks,
} from "../../building-blocks/atoms/texts/texts"
import Potential from "../../assets/images/potential.jpg"
import { scroll } from "../../utils/locomotiveScroll"

const text = () => {
  return (
    <>
      Omni online channel for your food brand <br />
      
    </>
  )
}

const ActionLinks1 = styled.p`
  color: white;
  font-size: 0.8rem;
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;
  z-index: 1;

  letter-spacing: 0.28rem;

  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12% 19% 0%;
`

const LIST = [
  {
    id: "1/7",
    p1: "Grocery",
    p2: "Create your peripheries and build your staple store",
  },
  {
    id: "2/7",
    p1: "Beauty & Cosmetics",
    p2:
      "Grow with our commerce platform to scale and offer amazing shopping experience",
  },
  {
    id: "3/7",
    p1: "Fashion",
    p2:
      "Run..Chase..Create your own footprints! Beat the blues with right shoes. You can have anything in life, if you dress for it.Take your brand to a different level of luxury and lavishness with us.",
  },
  {
    id: "4/7",
    p1: "Books, Stationery & Toys",
    p2: "Reinvent Book beyond paperbacks!",
  },
  {
    id: "5/7",
    p1: "Sports",
    p2:
      "Emerge as a champion. Create your own dream digital store with our exclusive tools.",
  },
  {
    id: "6/7",
    p1: "Electronics",
    p2: "Build your online brand, create your identity and let the customers know that you are in business.",
  },
  {
    id: "7/7",
    p1: "F&B",
    p2: text(),
  },
]

const TitleBg = styled.div`
  background: var(--color-sec);
  color:var(--color-sec);
  position: absolute;
  top: 33.5%;
  left: 15.8%;
  z-index: -1;
  width: 5%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: none;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    display: none;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet_landscape}{
    top:23%;
    left : 16.5%;
    width : 8%;
  }
`

function PotentialContext() {

  const [isMobile, setisMobile] = React.useState(null)

const changeMobile = () => {
  window.matchMedia("(max-width: 37.5em)").matches
    ? setisMobile(true)
    : setisMobile(false)
}

React.useEffect(() => {
  window.scroll(0, 0)
  scroll()
  changeMobile()
  window.addEventListener("resize", changeMobile)
  return () => window.removeEventListener("resize", changeMobile)
}, [])

  React.useEffect(() => {
    scroll()
  }, [])

  return (
    <>
      <SectionWrapper style={{ height: isMobile? "70vh" :"90vh", zIndex: 2 }} data-scroll-section>
        <InsideSectionWrapper
          style={{
            padding: isMobile ? "48% 0% 0% 8%" : "15% 18% 0% 15%",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <BannerOverlaySubtext style={{ padding: "0% 3%" }}>
            Potential
          </BannerOverlaySubtext>
          <TitleBg>jhllmlm;;,</TitleBg>
          <>
            <BannerOverlayText
              style={{  }}
              data-scroll
              data-scroll-speed="-2"
            >
              Ability to empower online & offline business with tools and
              traction
            </BannerOverlayText>
          </>
        </InsideSectionWrapper>
      </SectionWrapper>

      <SectionWrapper style={{ }} data-scroll-section>
        <BackImg
          src={Potential}
          style={{ height: "150vh" }}
          alt=""
          data-scroll
          data-scroll-speed="-5"
        />
      </SectionWrapper>

      <SectionWrapper style={{ height: "auto" }} data-scroll-section>
        <ServicesWrapper
          style={{
            backgroundColor: "white",
            padding:isMobile? "1% 20% 23%": "6% 30% 14%",
            position: "unset",
          }}
        >
          <>
            <BannerOverlayText
              style={{
                // width: "100%",
                // marginTop: "5rem",
                // fontWeight: "300",
                // fontFamily: "roboto",
                fontSize: isMobile ? "0.9rem" : "3.2rem",
                color: "black",
              }}
              data-scroll
              data-scroll-speed="1"
            >
              Creating unique experiences while promoting and aligning holistic
              experiences globally and helping businesses increase efficiencies
              and transform as a whole.
            </BannerOverlayText>
          </>
        </ServicesWrapper>
      </SectionWrapper>

      <SectionWrapper
        style={{ height: "auto", flexDirection: "column" }}
        data-scroll-section
      >
        <ActionLinks1>INDUSTRY</ActionLinks1>
        <InsideSectionWrapper
          data-scroll
          data-scroll-speed="1"
          style={{
            padding: isMobile ? "0% 4% 0": "0% 18% 15%",
            marginTop: "-2%",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          {LIST.map((i) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                width: "100%",
                borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
                padding: "7% 2% 9%",
                height: "auto",
              }}
            >
              <div>
                <BannerOverlayText
                  style={{
                    // position: "absolute",
                    // top: "10%",
                    // left: "2%",
                    fontSize: "0.7rem",
                    fontWeight: "400",
                    opacity: "0.5",
                    marginTop: "0%",
                  }}
                >
                  {i.id}
                </BannerOverlayText>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                <BannerOverlayText
                  style={{
                    width: isMobile ? "100%" : "27%",
                    fontWeight: "300",
                    fontFamily: "roboto",
                    fontSize: isMobile ? "1.5rem" : "2.8rem",
                    lineHeight: "4.2rem",
                    margin: "0%",
                  }}
                >
                  {i.p1}
                </BannerOverlayText>

                <BannerOverlayText
                  style={{
                    width: isMobile ? "100%" :"50%",
                    fontWeight: "300",
                    fontFamily: "roboto",
                    fontSize: isMobile? "1rem" : "1.4rem",
                    lineHeight: "2.5rem",
                    margin: "0%",
                  }}
                >
                  {i.p2}
                </BannerOverlayText>
              </div>
            </div>
          ))}
        </InsideSectionWrapper>
      </SectionWrapper>
    </>
  )
}

export default PotentialContext
