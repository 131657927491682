import React, { useState } from "react"
import { useForm } from "react-hook-form"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import DatePicker from "@mui/lab/DatePicker"
import TextField from "@mui/material/TextField"
import { SectionFlexWrapper } from "../ui/StyledContainer"
import { StyledForm } from "../ui/StyledForm"
import { StyledText } from "../ui/StyledText"
import { StyledInput } from "../ui/StyledInput"
import { StyledButton } from "../ui/StyledButton"
import { createLedgerApi } from "../apis/apis"
import { Text } from "../components/Text"

export const LedgerForm = ({ setStatus }) => {
  const [date, setDate] = React.useState(null)
  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
    reset,
  } = useForm()

  const handleLedgerFormSubmit = async (data) => {
    if (
      data._dateOfTrade !== "" &&
      data._totalUnits !== "" &&
      data._dateOfTrade !== null &&
      data._totalUnits !== null &&
      data._SRinvolved !== "" &&
      data._productList !== "" &&
      data._SRinvolved !== null &&
      data._productList !== null &&
      data._totalEarning !== "" &&
      data._totalEarning !== null
    ) {
      createLedgerApi(data).then((res) => {
        if (res && res.data && res.data.status && res.data.message) {
          setStatus(`Ledger created successfully`, "success")
        }
      })
    } else {
      setStatus(`All fields are mandatory`, "error")
    }
  }

  return (
    <>
      <SectionFlexWrapper
        width="100%"
        height="auto"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        margin="0"
        padding="0"
      >
        <Text
          fontSize="1rem"
          fontWeight="700"
          padding="0"
          margin="0 0 1rem"
          width="auto"
          height="auto"
          color="var(--blk1)"
          textAlign="start"
          lineHeight="1.2rem"
          letterSpacing="unset"
          wordBreak="unset"
          textTransformation="capitalize"
          label={"Create Ledger Data"}
          fontSizeMobile="0.8rem"
          marginMobile="0 0.4rem 0"
        />
        <StyledForm onSubmit={handleSubmit(handleLedgerFormSubmit)}>
          <StyledText
            fontSize={"0.7rem"}
            fontWeight={"600"}
            color={"var(--blk3)"}
            textAlign={"center"}
            lineHeight={"0.7rem"}
            letterSpacing={"0.01rem"}
            margin={"0 2% 1%"}
          >
            Date Of Trade
          </StyledText>

          {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              inputFormat="dd/MM/yyyy"
              value={date}
              onChange={(newValue) => {
                setDate(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="outlined-basic fullWidth"
                  variant="outlined"
                  size="small"
                  style={{
                    fontSize: "0.7rem",
                    fontWeight: "500",
                    padding: "0",
                    margin: "0 0 4%",
                    width: "90%",
                    height: "7vh",
                    backgroundColor: "var(--wht)",
                    color: "var(--blk1)",
                    backgroundColorHover: "var(--blk4)",
                    colorHover: "var(--blk1)",
                  }}
                />
              )}
            />
          </LocalizationProvider> */}

          <StyledInput
            fontSize={"0.7rem"}
            fontWeight={"500"}
            padding={"0 2%"}
            margin={"0 0 4%"}
            width={"90%"}
            height={"7vh"}
            backgroundColor={"var(--wht)"}
            color={"var(--blk1)"}
            border={"1px solid var(--blk4)"}
            backgroundColorHover={"var(--blk4)"}
            colorHover={"var(--blk1)"}
            borderHover={"1px solid var(--blk3)"}
            type="text"
            placeholder="Enter the date of trade ( DD-MM-YYYY )"
            {...register("_dateOfTrade")}
            autocomplete="off"
          />

          <StyledText
            fontSize={"0.7rem"}
            fontWeight={"600"}
            color={"var(--blk3)"}
            textAlign={"center"}
            lineHeight={"0.7rem"}
            letterSpacing={"0.01rem"}
            margin={"0 2% 1%"}
          >
            Total Units
          </StyledText>
          <StyledInput
            fontSize={"0.7rem"}
            fontWeight={"500"}
            padding={"0 2%"}
            margin={"0 0 4%"}
            width={"90%"}
            height={"7vh"}
            backgroundColor={"var(--wht)"}
            color={"var(--blk1)"}
            border={"1px solid var(--blk4)"}
            backgroundColorHover={"var(--blk4)"}
            colorHover={"var(--blk1)"}
            borderHover={"1px solid var(--blk3)"}
            type="text"
            placeholder="Enter total number of units ( in Numbers )"
            {...register("_totalUnits")}
            autocomplete="off"
          />

          <StyledText
            fontSize={"0.7rem"}
            fontWeight={"600"}
            color={"var(--blk3)"}
            textAlign={"center"}
            lineHeight={"0.7rem"}
            letterSpacing={"0.01rem"}
            margin={"0 2% 1%"}
          >
            SR Invloved
          </StyledText>
          <StyledInput
            fontSize={"0.7rem"}
            fontWeight={"500"}
            padding={"0 2%"}
            margin={"0 0 4%"}
            width={"90%"}
            height={"7vh"}
            backgroundColor={"var(--wht)"}
            color={"var(--blk1)"}
            border={"1px solid var(--blk4)"}
            backgroundColorHover={"var(--blk4)"}
            colorHover={"var(--blk1)"}
            borderHover={"1px solid var(--blk3)"}
            type="text"
            placeholder="Enter SR invloved"
            {...register("_SRinvolved")}
            autocomplete="off"
          />

          <StyledText
            fontSize={"0.7rem"}
            fontWeight={"600"}
            color={"var(--blk3)"}
            textAlign={"center"}
            lineHeight={"0.7rem"}
            letterSpacing={"0.01rem"}
            margin={"0 2% 1%"}
          >
            product list
          </StyledText>
          <StyledInput
            fontSize={"0.7rem"}
            fontWeight={"500"}
            padding={"0 2%"}
            margin={"0 0 4%"}
            width={"90%"}
            height={"7vh"}
            backgroundColor={"var(--wht)"}
            color={"var(--blk1)"}
            border={"1px solid var(--blk4)"}
            backgroundColorHover={"var(--blk4)"}
            colorHover={"var(--blk1)"}
            borderHover={"1px solid var(--blk3)"}
            type="text"
            placeholder="Enter the product list"
            {...register("_productList")}
            autocomplete="off"
          />

          <StyledText
            fontSize={"0.7rem"}
            fontWeight={"600"}
            color={"var(--blk3)"}
            textAlign={"center"}
            lineHeight={"0.7rem"}
            letterSpacing={"0.01rem"}
            margin={"0 2% 1%"}
          >
            Total Earning
          </StyledText>
          <StyledInput
            fontSize={"0.7rem"}
            fontWeight={"500"}
            padding={"0 2%"}
            margin={"0 0 4%"}
            width={"90%"}
            height={"7vh"}
            backgroundColor={"var(--wht)"}
            color={"var(--blk1)"}
            border={"1px solid var(--blk4)"}
            backgroundColorHover={"var(--blk4)"}
            colorHover={"var(--blk1)"}
            borderHover={"1px solid var(--blk3)"}
            type="text"
            placeholder="Enter the total earning"
            {...register("_totalEarning")}
            autocomplete="off"
          />

          <StyledButton
            fontSize={"0.7rem"}
            fontWeight={"500"}
            padding={"0"}
            width={"12vw"}
            height={"7vh"}
            backgroundColor={"var(--blk1)"}
            color={"var(--wht)"}
            border={"none"}
            backgroundColorHover={"var(--gold1)"}
            colorHover={"white"}
            borderHover={"none"}
            backgroundColorFocus={"var(--gold3)"}
            colorFocus={"white"}
            borderFocus={"var(--gold1)"}
            style={{
              marginTop: "5%",
            }}
            fontSizeMobile={"0.7rem"}
            paddingMobile={"0 7%"}
            marginMobile={"0"}
            widthMobile={"auto"}
            heightMobile={"5vh"}
          >
            Submit Data
          </StyledButton>
        </StyledForm>
      </SectionFlexWrapper>
    </>
  )
}
