import React from "react"
import styled from "styled-components"
// import {
//   InsideSectionWrapper,
//   SectionWrapper,
//   ServicesWrapper,
// } from "../../building-blocks/atoms/containers/containers"
import { BackImg } from "../../building-blocks/atoms/images/images"
// import {
//   BannerOverlayText,
//   BannerOverlaySubtext,
//   ActionLinks,
// } from "../../building-blocks/atoms/texts/texts"

import Services from "../../assets/images/services.jpg"
import Services1 from "../../assets/images/services1.jpg"
import { scroll } from "../../utils/locomotiveScroll"

export const SectionWrapper1 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);
  height: 90vh;
  z-index: 2;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 75vh;
  }
`

export const InsideSectionWrapper1 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 15% 18% 0% 15%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    align-items: flex-start;
    justify-content: flex-start;
    padding: 60% 12% 30% 12%;
  }
`

export const BannerOverlaySubtext1 = styled.p`
  color: white;
  font-family: roboto;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  font-weight: 600;
  padding: 0% 3%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 0% 3%;
    font-size: 0.7rem;
    opacity: 0.8;
    letter-spacing: 0.28rem;
  }
`

export const BannerOverlayText1 = styled.h1`
  color: white;
  font-family: graphik;
  font-size: 3.5rem;
  letter-spacing: 0.1rem;
  font-weight: 700;
  margin-top: 6rem;

  width: 74%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 1.5rem;
    font-weight: 800;
    width: 100%;
    line-height: 2.5rem;
    margin-top: 3rem;
    margin-bottom: 5rem;
  }
`

export const SectionWrapper2 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 120vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 100vh;
  }
`

export const SectionWrapper3 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);

  height: 120vh;
  z-index: 2;
  position: relative;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: auto;
    min-height: 100vh;
  }
`

export const SectionWrapper4 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);

  height: auto;
  z-index: 2;
  position: relative;
  margin-bottom: -0.1rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: auto;
    min-height: 100vh;
  }
`

export const SectionWrapper5 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 420vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 140vh;
    align-items: center;
  }
`

export const ServicesWrapper3 = styled.div`
  position: absolute;
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  background-color: var(--color-comp);
  padding: 12% 15%;
  height: auto;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 24% 12%;
    height: auto;
  }
`

export const BannerOverlayText2 = styled.h1`
  color: white;
  font-family: roboto;
  font-size: 3.2rem;
  letter-spacing: 0.1rem;
  font-weight: 300;
  margin-top: 5rem;
  width: 100%;
  color: black;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    color: black;
    font-weight: 400;
    font-family: roboto;
    font-size: 1.2rem;
    width: 100%;
    line-height: 2rem;
    margin-top: 0rem;
    letter-spacing: 0.04rem;
    margin-bottom: 3rem;
  }
`

export const ActionLinks1 = styled.p`
  color: white;
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;

  text-transform: none;
  line-height: 2;
  font-weight: 300;
  width: 65%;
  letter-spacing: 0.02rem;
  color: black;
  margin-top: 3rem;
  font-size: 1rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    color: black;
    margin-top: 0rem;
    text-transform: none;
    line-height: 2;
    font-weight: 400;
    width: 92%;
    letter-spacing: 0.04rem;
    margin-bottom: 3rem;
    font-size: 0.8rem;
  }
`

export const BannerOverlayText3 = styled.h1`
  color: white;
  font-family: roboto;
  font-size: 3.2rem;
  letter-spacing: 0.1rem;
  font-weight: 300;
  margin-top: 5rem;
  width: 100%;
  color: black;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    color: black;
    font-weight: 400;
    font-family: roboto;
    font-size: 1.2rem;
    width: 100%;
    line-height: 2rem;
    margin-top: 0rem;
    letter-spacing: 0.04rem;
    margin-bottom: 2rem;
  }
`

export const ActionLinks2 = styled.p`
  color: white;
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;

  text-transform: none;
  line-height: 2;
  font-weight: 300;
  width: 65%;
  letter-spacing: 0.02rem;
  color: black;
  margin-top: 3rem;
  font-size: 1rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    color: black;
    margin-top: 0rem;
    text-transform: none;
    line-height: 2;
    font-weight: 400;
    width: 92%;
    letter-spacing: 0.04rem;
    margin-bottom: 5rem;
    font-size: 0.8rem;
  }
`

export const ServicesWrapper1 = styled.div`
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6% 30% 14%;
  background-color: var(--color-wht);

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    background-color: white;
    padding: 18% 12%;
    position: unset;
    height: auto;
    min-height: 100vh;
  }
`

export const ServicesWrapper2 = styled.div`
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6% 30% 14%;
  background-color: var(--color-wht);

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    background-color: white;
    padding: 18% 12%;
    position: unset;
    height: auto;
    min-height: 100vh;
  }
`

export const BannerOverlayText8 = styled.h1`
  color: white;
  font-family: graphik;
  font-size: 3.5rem;
  letter-spacing: 0.1rem;
  font-weight: 700;
  width: 40%;
  margin-top: 4rem;

  width: 100%;
  margin: 0rem 0rem 7%;
  font-weight: 600;
  font-family: roboto;
  font-size: 1rem;
  letter-spacing: 0.4rem;
  text-transform: uppercase;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
    margin: 0rem 0rem 12%;
    font-family: roboto;
    text-transform: uppercase;
    font-size: 0.7rem;
    opacity: 1;
    letter-spacing: 0.28rem;
    font-weight: 400;
  }
`

export const BannerOverlayText9 = styled.h1`
  color: white;
  font-family: graphik;
  font-size: 3.5rem;
  letter-spacing: 0.1rem;
  font-weight: 700;
  width: 40%;
  margin-top: 4rem;

  width: 100%;
  margin: 0rem;
  font-weight: 300;
  font-family: roboto;
  font-size: 3rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    margin: 0rem;
    font-family: roboto;
    margin-top: 0rem;
    text-transform: none;
    line-height: 2;
    font-weight: 400;
    width: 92%;
    letter-spacing: 0.04rem;
    margin-bottom: 0rem;
    font-size: 0.72rem;
  }
`

const ColoredWrapper = styled.div`
  width: 100%;
  height: 110vh;
  background: ${(props) => (props.Red ? "#E93C38" : "#634488")};
  padding: 14% 10% 14% 12%;
  margin-bottom: ${(props) => (props.Last ? "0%" : "12%")};

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: auto;
    margin-bottom: ${(props) => (props.Last ? "0%" : "20%")};
  }
`

function ConsultingServicesContext() {
  React.useEffect(() => {
    scroll()
  }, [])

  return (
    <>
      <SectionWrapper1 data-scroll-section>
        <InsideSectionWrapper1>
          <BannerOverlaySubtext1>Consulting Services</BannerOverlaySubtext1>
          <>
            <BannerOverlayText1 data-scroll data-scroll-speed="-2">
              Passionate Manpower Providers
            </BannerOverlayText1>
          </>
        </InsideSectionWrapper1>
      </SectionWrapper1>

      <SectionWrapper2 data-scroll-section>
        <BackImg
          src={Services}
          style={{ height: "130vh" }}
          alt=""
          data-scroll
          data-scroll-speed="-5"
        />
      </SectionWrapper2>

      <SectionWrapper3 data-scroll-section>
        <ServicesWrapper1>
          <>
            <BannerOverlayText2 data-scroll data-scroll-speed="1">
              Shubhartha Facilities & Services focuses majorly in providing the best services along with
              utmost customer satisfaction.
            </BannerOverlayText2>
          </>
          <ActionLinks1 data-scroll data-scroll-speed="1">
          Shubhartha Facilities & Services provides a full range of solutions including
temporary and permanent staffing, outsourcing and other workforce solutions in
the areas of human resources, manufacturing and logistics, engineering, IT,
            finance and accounting, sales and marketing.
            <br />
          Our company is specialized in
Manpower Services, Import-Export, Outsourced Manufacturing Services (Job
Works), Payroll, Statutory Compliance & much more facilities are indulged by our
organization.
          </ActionLinks1>
        </ServicesWrapper1>
      </SectionWrapper3>

      <SectionWrapper2 data-scroll-section>
        <BackImg
          src={Services1}
          style={{ height: "130vh" }}
          alt=""
          data-scroll
          data-scroll-speed="-3"
        />
      </SectionWrapper2>

      <SectionWrapper4 data-scroll-section>
        <ServicesWrapper2>
          <>
            <BannerOverlayText3 data-scroll data-scroll-speed="1">
            Manpower Services
            </BannerOverlayText3>
          </>
          <ActionLinks2 data-scroll data-scroll-speed="1">
          We at Shubhartha Facilities & Services offer our services in the field of manpower planning 
and recruitment. Considering the needs of organization, because we feel that the needs of 
every organization differs with its nature of business and size etc.
          </ActionLinks2>

          <>
            <BannerOverlayText3 data-scroll data-scroll-speed="1">
            Recruitment Process 
            </BannerOverlayText3>
          </>
          <ActionLinks2 data-scroll data-scroll-speed="1">
          We carefully select and place our candidates in co-ordination with HR/Personnel 
department and place the right candidate at right job. We not only select the suitable 
candidate while placing them we also consider the organization’s culture and working 
conditions. The candidates offered by us are always in line with company’s recruitment 
policy. We pay most attention to Education level, Experience, physical fitness and attitude 
of the candidate.
          </ActionLinks2>

          <>
            <BannerOverlayText3 data-scroll data-scroll-speed="1">
            Housekeeping Service
            </BannerOverlayText3>
          </>
          <ActionLinks2 data-scroll data-scroll-speed="1">
          Housekeeping refers to day-to-day cleanliness, tidiness and good order in all parts of the 
office. Good housekeeping provides a clean and pleasant working environment. It also 
helps prevent accidents in the workplace and aids the efficient operation of the office. 
Good housekeeping can usually be achieved by routine cleaning of the premises, proper 
storage of materials, periodic disposal of rubbish, regular maintenance of facilities and full 
staff co-operation. Now a days every organization is committed to provide neat and clean 
            work conditions.
            <br />
            In order to provide Housekeeping services at most efficient level we also provide 
Mechanized Housekeeping services also.
          </ActionLinks2>

          <>
            {/* <BannerOverlayText3 data-scroll data-scroll-speed="1">
              End-To-End Staffing Solutions
            </BannerOverlayText3> */}
          </>
          {/* <ActionLinks2 data-scroll data-scroll-speed="1">
            Our comprehensive staffing solutions are aimed at meeting the
            requirements of Hire, Train, Deploy, Build, Operate, and Transfer.
          </ActionLinks2> */}
        </ServicesWrapper2>
      </SectionWrapper4>

      {/* <SectionWrapper5 data-scroll-section>
        <ServicesWrapper3>
          <ColoredWrapper Red>
            <>
              <BannerOverlayText8 data-scroll data-scroll-speed="1">
                Insights
              </BannerOverlayText8>
            </>
            <>
              <BannerOverlayText9 data-scroll data-scroll-speed="1">
                Reach New And Game-Changing Levels Of Performance By Modernizing
                Your IT Capabilities, Including Your Systems Architecture,
                Operating Model And Cost Structure, So Your Company Is Ready To
                Pursue Its Digital Future.
              </BannerOverlayText9>
            </>
          </ColoredWrapper>
          <ColoredWrapper>
            <>
              <BannerOverlayText8 data-scroll data-scroll-speed="1">
                WHAT WE DO
              </BannerOverlayText8>
            </>
            <>
              <BannerOverlayText9 data-scroll data-scroll-speed="1">
              Reinvent your operations to uncover a new competitive edge. Shubhartha Facilities & Services helps you transform your operations by taking a holistic view that connects operations to strategy, transcends siloes, and focuses on the opportunities that will create the greatest value.
              </BannerOverlayText9>
            </>
          </ColoredWrapper>
          <ColoredWrapper Red Last>
            <>
              <BannerOverlayText8 data-scroll data-scroll-speed="1">
                Insights
              </BannerOverlayText8>
            </>
            <>
              <BannerOverlayText9 data-scroll data-scroll-speed="1">
              Develop digital strategies that deliver on the promise of your core strategy, with a unique approach that combines near-term, high-impact initiatives with a bold vision for how your company will compete as digital reshapes your industry.
              </BannerOverlayText9>
            </>
          </ColoredWrapper>
        </ServicesWrapper3>
      </SectionWrapper5> */}
    </>
  )
}

export default ConsultingServicesContext
