import { useEffect, useState } from "react"
import { SectionFlexWrapper, SectionGridWrapper } from "../ui/StyledContainer"
import { Text } from "../components/Text"
import { LedgerList } from "./ledgerList"
import { LedgerForm } from "./ledgerForm"
import { getTransactionHeadersApi, getPortfolioApi } from "../apis/apis"

const LedgerScreen = ({ userToken, userId, isLoggedIn, setStatus }) => {
  const [transactionHeaders, setTransactionHeaders] = useState(null)
  const [portfolio, setPortfolio] = useState(null)
  // const [isAuth, setIsAuth] = useState(null);

  useEffect(() => {
    // getTransactionHeadersApi().then((res) => {
    //   if (res && res.data && res.data.status && res.data.message) {
    //     // console.log(res.data.message);
    //     setTransactionHeaders(res.data.message);
    //   } else console.log(res.data);
    // });

    getPortfolioApi().then((res) => {
      if (res && res.data && res.data.status && res.data.message) {
        // console.log(res.data.message);
        setPortfolio(res.data.message)
      } else console.log(res.data)
    })
  }, [])

  // useEffect(() => {
  //   if (userToken && userId && isLoggedIn) {
  //     setIsAuth(true)
  //   } else {
  //     setIsAuth(false)
  //   }
  // }, [userToken, userId, isLoggedIn])

  return (
    <>
      <SectionFlexWrapper
        width="100%"
        height="auto"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
        margin="2% 0 0"
        padding="0 1% 2%"
        style={{
          borderBottom: "1px solid var(--blk4)",
        }}
      >
        <Text
          fontSize="0.8rem"
          fontWeight="500"
          padding="0"
          margin="0 0.8rem 0"
          width="auto"
          height="auto"
          color="var(--blk1)"
          textAlign="start"
          lineHeight="1.2rem"
          letterSpacing="unset"
          wordBreak="unset"
          textTransformation="capitalize"
          label={`trade count: ${portfolio ? portfolio?.tradeCount : ""}`}
          fontSizeMobile="0.8rem"
          marginMobile="0 0.4rem 0"
        />
        <Text
          fontSize="0.8rem"
          fontWeight="500"
          padding="0"
          margin="0 0.8rem 0"
          width="auto"
          height="auto"
          color="var(--blk1)"
          textAlign="start"
          lineHeight="1.2rem"
          letterSpacing="unset"
          wordBreak="unset"
          textTransformation="capitalize"
          label={`total Valuation: ${
            portfolio ? portfolio?.totalValuation : ""
          }`}
          fontSizeMobile="0.8rem"
          marginMobile="0 0.4rem 0"
        />
        <Text
          fontSize="0.8rem"
          fontWeight="500"
          padding="0"
          margin="0 0.8rem 0"
          width="auto"
          height="auto"
          color="var(--blk1)"
          textAlign="start"
          lineHeight="1.2rem"
          letterSpacing="unset"
          wordBreak="unset"
          textTransformation="capitalize"
          label={`owner Address: ${portfolio ? portfolio?.ownerAddress : ""}`}
          fontSizeMobile="0.8rem"
          marginMobile="0 0.4rem 0"
        />
      </SectionFlexWrapper>
      <SectionGridWrapper
        width={"100%"}
        height={"auto"}
        gridTemplateComlumns={"1fr 1fr"}
        gridTemplateRows={"1fr"}
        gap={"0 2%"}
        gridAutoRows={"minmax(auto, auto)"}
        gridAutoFlow={"row"}
        justifyContent={"center"}
        alignItems={"flex-start"}
        margin={"2% 0"}
        padding={"0 4%"}
        paddingMobile={"0"}
        gapMobile={"0"}
        heightMobile={"fit-content"}
        gridTemplateColumnsMobile={"1fr"}
        gridTemplateRowsMobile={"auto"}
        marginMobile={"0"}
      >
        <LedgerList
          title={"transactions"}
          transactionHeaders={transactionHeaders}
        />
        {userToken && isLoggedIn && userId ? (
          <LedgerForm setStatus={setStatus} />
        ) : null}
      </SectionGridWrapper>
    </>
  )
}

export default LedgerScreen
