import React from "react"
import {
  SectionWrapper,
  CopyrightSectionWrapper,
  ServicesWrapper,
  BackImg,
  AddressContactText,
  FooterWrapper1,
  FooterWrapper2,
  FooterWrapper3,
  FooterWrapper4,
  FooterLogo,
} from "../../building-blocks/atoms/containers/FooterContainer"
import {
  ActionLinks,
  BannerOverlayText,
  ActionLinks1,
  ActionLink,
} from "../../building-blocks/atoms/texts/FooterText"

import Footer_img from "../../assets/images/footer_img.png"
import footerlogo from "../../assets/images/footerlogo.png"
import styled from "styled-components"
import { Link } from "react-router-dom"
// import MenuLink from "../Navigation/MenuLink"
// import MenuPage from "../../components/menu-page/MenuPage"

const LINKS = [
  {
    id: 1,
    link: "/",
    text: "home",
  },
  {
    id: 2,
    link: "/about-us",
    text: "about us",
  },
  {
    id: 3,
    link: "/portfolio",
    text: "portfolio",
  },
]

const Footer = (props) => {
  const [isMobile, setisMobile] = React.useState(null)
  const [isTablet, setisTablet] = React.useState(null)

  const changeMobile = () => {
    window.matchMedia("(max-width: 37.5em)").matches
      ? setisMobile(true)
      : setisMobile(false)
  }
  const changeTablet = () => {
    window.matchMedia("(min-device-width: 760px) and (max-device-width: 810px)")
      .matches
      ? setisTablet(true)
      : setisTablet(false)
  }

  React.useEffect(() => {
    changeTablet()
    changeMobile()
    console.log(props)
    window.addEventListener("resize", changeMobile)
    return () => window.removeEventListener("resize", changeMobile)
  }, [])

  return (
    <SectionWrapper data-scroll-section>
      {/* <CopyrightSectionWrapper>
        Copyright &#169;2020 All Rights are Reserved.
      </CopyrightSectionWrapper> */}
      <ServicesWrapper>
        <BackImg src={Footer_img} alt="" />
        <FooterWrapper1>
          <>
            <BannerOverlayText
              data-scroll
              data-scroll-speed="1"
              data-scroll-direction="horizontal"
            >
              Leave It To
              <br />
              US.
            </BannerOverlayText>
          </>

          <FooterWrapper2 data-scroll data-scroll-speed="3">
            {LINKS.map((i) => (
              <ActionLink
                to={i.link}
                key={i.id}
                style={{ marginBottom: isTablet ? "1.5rem" : "" }}
              >
                {i.text}
              </ActionLink>
            ))}
            {/* {isMobile ? <ActionLink >OTHER BRANCHES</ActionLink> : <><TabletLink >OTHER BRANCHES</TabletLink></>} */}
          </FooterWrapper2>
        </FooterWrapper1>
        <FooterWrapper3 data-scroll data-scroll-speed="0">
          <p
            style={{
              color: "white",
              fontSize: "0.7rem",
              fontWeight: "400",
              fontFamily: "Graphik",
              textDecoration: "none",
            }}
          >
            Copyright @ 2022 Shubhartha Facilities & Services LLP
          </p>
          <a
            style={{
              color: "white",
              fontSize: "0.7rem",
              fontWeight: "400",
              fontFamily: "Graphik",
              textDecoration: "none",
            }}
            target="_blank"
            href="https://www.polytopenetworks.com/"
            rel="noreferrer"
          >
            Designed and Developed by Polytope Networks Pvt Ltd
          </a>
          {/* <FooterWrapper4>
            <ActionLinks >
              #231, 13th Cross Road, Indiranagar 2nd Stage Bengaluru, Karnataka.
            </ActionLinks>
            <ActionLinks>info@Shubhartha Facilities & Services.com <span style={{fontSize:"1.5rem",margin:"0.5rem"}}> | </span>   080-42156999</ActionLinks>
           
            <ActionLinks >
               <br/><br/>
              # 9980 S 300 W Suite 200, Sandy, UT.84070
            </ActionLinks>
            
            <ActionLinks>Hr@Shubhartha Facilities & Services.com <span style={{fontSize:"1.5rem",margin:"0.5rem"}}> | </span>   (385) 275 2777</ActionLinks>
          </FooterWrapper4> */}
          {/* {isMobile ? (
            <FooterWrapper4>
            <ActionLinks1 style={{display:'none'}}>OTHER BRANCHES</ActionLinks1>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "85%",
              }}
            >
              <AddressContactText target="_blank" href="https://www.facebook.com/Shubhartha Facilities & Services-1525727621034411/about/">
                Fb
              </AddressContactText>
              <AddressContactText target="_blank" href="">Ig</AddressContactText>
              <AddressContactText target="_blank" href="">Tw</AddressContactText>
              <AddressContactText target="_blank" href="https://www.linkedin.com/company/Shubhartha Facilities & Services/?originalSubdomain=in">
                In
              </AddressContactText>
              <AddressContactText target="_blank" href="">Be.</AddressContactText>
              <AddressContactText target="_blank" href=" https://Shubhartha Facilities & Services.com/terms-of-use">
                T&C
              </AddressContactText>
            </div>
            
          </FooterWrapper4>
          
          ) : (
            <FooterWrapper4>
              <ActionLinks1 target="" href="" onClick={ ()=> {
                props.setMenuOpen(true)
              }} >OTHER BRANCHES</ActionLinks1>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "65%",
                }}
              >
                <AddressContactText target="_blank" href="https://www.facebook.com/Shubhartha Facilities & Services-1525727621034411/about/">
                  Fb
                </AddressContactText>
                <AddressContactText target="_blank" href="">Ig</AddressContactText>
                <AddressContactText target="_blank" href="">Tw</AddressContactText>
                <AddressContactText target="_blank" href="https://www.linkedin.com/company/Shubhartha Facilities & Services/?originalSubdomain=in">
                  In
                </AddressContactText>
                <AddressContactText target="_blank" href="">Be.</AddressContactText>
                <AddressContactText target="_blank" href=" https://Shubhartha Facilities & Services.com/terms-of-use">
                  T&C
                </AddressContactText>
              </div>
            </FooterWrapper4>
          )} */}
        </FooterWrapper3>
        {/* <FooterLogo><img alt='' src={footerlogo} style={{height:"1.7rem",width:"45%",position:"absolute"}}/></FooterLogo> */}
      </ServicesWrapper>
    </SectionWrapper>
  )
}

export default Footer

const TabletLink = styled(Link)`
  display: none;
  color: ${(props) => (props.black ? "var(--color-blk)" : "var(--color-wht)")};

  @media ${({ theme }) => theme.mediaQueries.tablet} {
    display: block;
    font-size: 0.8rem;
    font-family: "Roboto";
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.12rem;
    text-decoration: none;
    margin-bottom: 3.2rem;
  }
`
