import React from "react"
import styled from "styled-components"
// import {
//   InsideSectionWrapper,
//   SectionWrapper,
//   ServicesWrapper,
// } from "../../building-blocks/atoms/containers/containers"
// import { BackImg } from "../../building-blocks/atoms/images/images"
// import {
//   BannerOverlayText,
//   BannerOverlaySubtext,
//   ActionLinks,
// } from "../../building-blocks/atoms/texts/texts"
import ScrollImg4 from "../../assets/images/scroll4.jpg"
import { scroll } from "../../utils/locomotiveScroll"

export const SectionWrapper1 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);
  height: 90vh;
  z-index: 2;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 75vh;
  }
  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 75vh;
  }

  @media ${({ theme }) => theme.mediaQueries.tablet} {
    height: 75vh;
  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro} {
    height: 75vh;
  }
`

export const SectionWrapper2 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 120vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 100vh;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet} {
    height: 80vh;
  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro} {
    height: 80vh;
  }
`

export const InsideSectionWrapper1 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 15% 18% 0% 15%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    align-items: flex-start;
    justify-content: flex-start;
    padding: 60% 12% 30% 12%;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet}{
    padding: 40% 12% 30% 12%;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet_landscape}{
    padding: 20% 12% 30% 12%;
  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro} {
    padding: 30% 18%;
  }
`

export const BannerOverlayText1 = styled.h1`
  color: white;
  font-family: graphik;
  font-size: 3.5rem;
  letter-spacing: 0.1rem;
  font-weight: 700;
  width: 90%;
  margin-top: 6rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 1.5rem;
    font-weight: 800;
    width: 100%;
    line-height: 2.5rem;
    margin-top: 3rem;
    margin-bottom: 5rem;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet}{
    width: 70%;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet_landscape}{
    font-size: 2.5rem;
  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro} {
    font-size: 2.5rem;
  }
`

export const BannerOverlaySubtext1 = styled.p`
  color: white;
  font-family: roboto;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  font-weight: 600;
  padding: 0% 3%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 0% 3%;
    font-size: 0.7rem;
    opacity: 0.8;
    letter-spacing: 0.28rem;
  }
`

export const SectionWrapper3 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: auto;
    min-height: 100vh;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet}{
    min-height: 70vh;
  }
`

export const ServicesWrapper1 = styled.div`
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6% 30% 14%;
  background-color: var(--color-wht);

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    background-color: white;
    padding: 18% 12%;
    position: unset;
    height: auto;
    min-height: 100vh;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet}{
    min-height: 70vh;
    padding: 32% 28%;
  }
`

export const BannerOverlayText2 = styled.h1`
  color: white;
  font-family: roboto;
  font-size: 3.2rem;
  letter-spacing: 0.1rem;
  font-weight: 300;
  margin-top: 5rem;
  width: 100%;
  color: black;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    color: black;
    font-weight: 400;
    font-family: roboto;
    font-size: 1.2rem;
    width: 100%;
    line-height: 2rem;
    margin-top: 0rem;
    letter-spacing: 0.04rem;
    margin-bottom: 3rem;
  }
`

export const ActionLinks1 = styled.p`
  color: white;
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;

  text-transform: none;
  line-height: 2;
  font-weight: 300;
  width: 65%;
  letter-spacing: 0.02rem;
  color: black;
  margin-top: 3rem;
  font-size: 1rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    color: black;
    margin-top: 0rem;
    text-transform: none;
    line-height: 2;
    font-weight: 400;
    width: 92%;
    letter-spacing: 0.04rem;
    margin-bottom: 3rem;
    font-size: 0.8rem;
  }
`

function SalesContext() {
  React.useEffect(() => {
    scroll()
  }, [])

  return (
    <>
      <SectionWrapper1 data-scroll-section>
        <InsideSectionWrapper1>
          <BannerOverlaySubtext1>Precious Metals</BannerOverlaySubtext1>
          <>
            <BannerOverlayText1 data-scroll data-scroll-speed="-2">
            We are maintaining an immense involvement in the industrial and commercial mining industry.
            </BannerOverlayText1>
          </>
        </InsideSectionWrapper1>
      </SectionWrapper1>

      <SectionWrapper2 data-scroll-section>
        <BackImg
          src={ScrollImg4}
          style={{ }}
          alt=""
          data-scroll
          data-scroll-speed="-5"
        />
      </SectionWrapper2>

      {/* <SectionWrapper3 data-scroll-section>
        <ServicesWrapper1>
          <>
            <BannerOverlayText2 data-scroll data-scroll-speed="1">
              Shubhartha Facilities & Services provides its own marketplace known as CARTZS which
              enables its clients to reach out to the end users directly.
            </BannerOverlayText2>
          </>
          <ActionLinks1 data-scroll data-scroll-speed="1">
            We Provide A Simple, Logical, And Enjoyable User Experience.
          </ActionLinks1>
        </ServicesWrapper1>
      </SectionWrapper3> */}
    </>
  )
}

export default SalesContext

const BackImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  height: 130vh;
  @media ${({ theme }) => theme.mediaQueries.tablet}{
    height: 80vh;
  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro} {
    height: 80vh;
  }
`