import styled from "styled-components"

export const StyledInput = styled.input`
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  border: ${(props) => props.border};
  border-radius: 0.2rem;
  cursor: pointer;

  transition-property: all;
  transition-duration: 420ms;
  transition-timing-function: ease;
  transition-delay: 0s;
  text-decoration: none;

  &:hover {
    background-color: ${(props) => props.backgroundColorHover};
    color: ${(props) => props.colorHover};
    border: ${(props) => props.borderHover};
  }

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
    padding: ${(props) => (props.paddingMobile ? props.paddingMobile : "0 4%")};
    margin: ${(props) => (props.marginMobile ? props.marginMobile : "0 0 7%;")};
    font-size: ${(props) => props.fontSizeMobile};
  }
`
