import { Link } from "react-router-dom"
import styled from "styled-components"

export const ActionLinks = styled.p`
  color: white;
  font-size: 0.8rem;
  font-family: "Roboto";
  // text-transform: capitalize;
  font-weight: 500;
  letter-spacing: 0.02rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    letter-spacing: 0.02rem;
    padding-bottom: 1.5rem;
    padding-top: 2.5rem;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    color: var(--color-wht);
    font-size: 0.65rem;
    font-family: "Roboto";
    text-transform: uppercase;
    font-weight: 300;
    -webkit-letter-spacing: 0.12rem;
    -moz-letter-spacing: 0.12rem;
    -ms-letter-spacing: 0.12rem;
    letter-spacing: 0.02rem;
    -webkit-text-decoration: none;
    text-decoration: none;
    /* margin-bottom: 1rem; */
    -webkit-transition: all var(--speed);
    transition: all var(--speed);
    width: 70%;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet}{
    padding-top: 0.5rem;
  }
`

export const ActionLinks1 = styled(Link)`
  color: white;
  font-size: 0.8rem;
  font-family: "Roboto";
  text-transform: capitalize;
  font-weight: 500;
  text-decoration: none;
  letter-spacing: 0.02rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    letter-spacing: 0.02rem;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    color: var(--color-wht);
    font-size: 0.7rem;
    font-family: "Roboto";
    text-transform: uppercase;
    font-weight: 500;
    -webkit-letter-spacing: 0.12rem;
    -moz-letter-spacing: 0.12rem;
    -ms-letter-spacing: 0.12rem;
    letter-spacing: 0.12rem;
    -webkit-text-decoration: none;
    text-decoration: none;
    /* margin-bottom: 1rem; */
    -webkit-transition: all var(--speed);
    transition: all var(--speed);
    text-align: right;
    width: 100%;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet}{
    display: none;
  }
`

export const ActionLink = styled(Link)`
  color: ${(props) => (props.black ? "var(--color-blk)" : "var(--color-wht)")};
  font-size: 0.8rem;
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.12rem;
  text-decoration: none;
  margin-bottom: 1.5rem;

  transition: all var(--speed);

  &:hover {
    color: var(--color-pri);
  }

  &:focus {
    color: var(--color-pri);
  }

  &:active {
    color: var(--color-pri);
  }
  @media ${({ theme }) => theme.mediaQueries.mobile} {
    letter-spacing: 0.02rem;
    line-hieght: 1rem;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    color: var(--color-wht);
    font-size: 0.7rem;
    font-family: "Roboto";
    text-transform: uppercase;
    font-weight: 500;
    -webkit-letter-spacing: 0.12rem;
    -moz-letter-spacing: 0.12rem;
    -ms-letter-spacing: 0.12rem;
    letter-spacing: 0.12rem;
    -webkit-text-decoration: none;
    text-decoration: none;
    margin-bottom: 1rem;
    -webkit-transition: all var(--speed);
    transition: all var(--speed);
    
    @media ${({ theme }) => theme.mediaQueries.tablet}{
      margin-bottom: 3.5rem;
    }
  }
`

export const BannerOverlayText = styled.h1`
  color: white;
  font-family: graphik;
  letter-spacing: 0.1rem;

  width: 60%;
  font-size: 4.8rem;
  font-weight: 700;
  margin-top: 0rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
    font-size: 2.4rem;
    font-weight: 800;
    margin: 15% 0 0;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    font-size: 2.5rem;
    font-weight: 800;
    line-height: 2.8rem;
    margin: 0%;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet} {
    width: 40%;
    line-height: 4rem;
    margin-bottom: 0rem;
  }
`
