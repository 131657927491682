import React from "react"
import styled from "styled-components"
import { v4 as uuidv4 } from "uuid"
import {
  // DetailedSayingWrapper,
  // InsideSectionWrapper,
  // SectionWrapper,
  SectionWrapper1,
  SectionWrapper2,
  InsideSectionWrapper1,
  ZigZagWrapper,
  ImageWrapper,
  CardWrapper,
  SectionWrapper3,
  InsideSectionWrapper2,
  DetailedSayingWrapper1,
} from "../../building-blocks/atoms/containers/WhatWeDoContextContainer"
// import { BackImg } from "../../building-blocks/atoms/images/images"
import {
  // BannerOverlayText,
  // BannerOverlaySubtext,
  // ActionLinks,
  BannerOverlaySubtext1,
  BannerOverlayText1,
  ActionLinks2,
  ActionLinks3,
  ActionLinks1,
} from "../../building-blocks/atoms/texts/WhatWeDoContextText"
import WhatWeDoImg from "../../assets/images/whatwedo5.jpg"
import ScrollImg1 from "../../assets/images/scroll1.jpg"
import ScrollImg2 from "../../assets/images/scroll2.jpg"
import ScrollImg3 from "../../assets/images/scroll3.jpg"
import ScrollImg4 from "../../assets/images/scroll4.jpg"
import ScrollImg5 from "../../assets/images/scroll5.jpg"
import ScrollImg6 from "../../assets/images/scroll6.jpg"
import { scroll } from "../../utils/locomotiveScroll"

const LIST = [
  {
    id: uuidv4(),
    img: ScrollImg1,
    title: "Digitization",
    subText:
      "One of the most exciting evolutions revolves around going digital.",
  },
  {
    id: uuidv4(),
    img: ScrollImg2,
    title: "Offline brand solutions",
    subText:
      "We can help in offline branding that can attract a huge number of customers",
  },
  {
    id: uuidv4(),
    img: ScrollImg3,
    title: "Marketing and promotions",
    subText:
      "Best designers, production & digital marketing team to help you in  marketing fields.",
  },
  {
    id: uuidv4(),
    img: ScrollImg4,
    title: "Sales",
    subText:
      "individual e-commerce website & a market place for retailing experience.",
  },
  {
    id: uuidv4(),
    img: ScrollImg5,
    title: "Logistics",
    subText: "Fulfillment For The Better Reachability",
  },
  {
    id: uuidv4(),
    img: ScrollImg6,
    title: "Security",
    subText: "Security, authenticity and reliability are what we believe in.",
  },
]

function WhatWeDoContext() {
  React.useEffect(() => {
    scroll()
  }, [])

  // const [isMobile, setisMobile] = React.useState(null)

  const changeMobile = () => {
    // window.matchMedia("(max-width: 37.5em)").matches
    //   ? setisMobile(true)
    //   : setisMobile(false)
  }

  React.useEffect(() => {
    changeMobile()
    window.addEventListener("resize", changeMobile)
    return () => window.removeEventListener("resize", changeMobile)
  }, [])

  return (
    <>
      <SectionWrapper1 data-scroll-section>
        <InsideSectionWrapper1>
          <BannerOverlaySubtext1>What we do</BannerOverlaySubtext1>
          <>
            <BannerOverlayText1 data-scroll data-scroll-speed="-2">
              Elevating Brands Through Innovation In Digital Transformation.
            </BannerOverlayText1>
          </>
        </InsideSectionWrapper1>
      </SectionWrapper1>

      <SectionWrapper2 data-scroll-section>
        <BackImg
          src={WhatWeDoImg}
          style={{ }}
          alt=""
          data-scroll
          data-scroll-speed="-5"
        />
      </SectionWrapper2>

      <SectionWrapper3 data-scroll-section>
        <InsideSectionWrapper2>
          <DetailedSayingWrapper1>
            <ActionLinks1 data-scroll data-scroll-speed="1">
              We are a company that provides a broad spectrum in every possible
              field. We are here to handhold you by involving our innovative
              thinking and knowledge of expertise which aims high at providing
              efficient business solutions and services that meets the standard
              of global market and objectives of our clients. Honeysys helps in
              transforming the world’s most important business into robust,
              agile organization that anticipates.
            </ActionLinks1>
          </DetailedSayingWrapper1>
        </InsideSectionWrapper2>

        <ZigZagWrapper>
          {LIST.map((i) => (
            <CardWrapper key={i.id}>
              <ImageWrapper data-scroll data-scroll-speed="2">
                <BackImg1
                  style={{
                    
                  }}
                  src={i.img}
                  alt=""
                />
              </ImageWrapper>
              <ActionLinks2 data-scroll data-scroll-speed="1">
                {i.title}
              </ActionLinks2>
              <ActionLinks3 data-scroll data-scroll-speed="1">
                {i.subText}
              </ActionLinks3>
            </CardWrapper>
          ))}
        </ZigZagWrapper>
      </SectionWrapper3>
    </>
  )
}

export default WhatWeDoContext



 const BackImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  height: 150vh;
  @media ${({ theme }) => theme.mediaQueries.ipad_pro}{
    height: 80vh;
  }
`

const BackImg1 = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  @media ${({ theme }) => theme.mediaQueries.ipad_pro}{
    height: 40vh;
    width: 35vh;
  }

  @media ${({ theme }) => theme.mediaQueries.tablet_landscape}{
    height: 60vh;
    width: 55vh;
  }
`