import React from "react"
import {
  DetailedSayingWrapper,
  InsideSectionWrapper,
  SectionWrapper,
  ServicesWrapper,
  SubActWrapper,
} from "../../building-blocks/atoms/containers/containers"
import {
  BannerOverlayText,
  BannerOverlaySubtext,
  ActionLinks,
  ActionLinkTag,
} from "../../building-blocks/atoms/texts/texts"
import OurTeam1 from "../../assets/images/ourteam1.png"
import OurTeam2 from "../../assets/images/ourteam2.png"
import OurTeam3 from "../../assets/images/ourteam3.png"
import { scroll } from "../../utils/locomotiveScroll"
function OurTeamContext() {
  React.useEffect(() => {
    scroll()
  }, [])

  return (
    <>
      <SectionWrapper
        style={{ height: "100vh", zIndex: 2 }}
        data-scroll-section
      >
        <InsideSectionWrapper
          style={{
            padding: "12% 18% 0% 12%",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <BannerOverlaySubtext style={{ padding: "0% 3%" }}>
            Our Team
          </BannerOverlaySubtext>
          <>
            <BannerOverlayText
              style={{ width: "75%", marginTop: "6rem" }}
              data-scroll
              data-scroll-speed="-2"
            >
              A strong community + <br />
              decades old exprience = <br />
              wisdom and empathy.
            </BannerOverlayText>
          </>
        </InsideSectionWrapper>
      </SectionWrapper>

      <SectionWrapper style={{ height: "110vh" }} data-scroll-section>
        <ServicesWrapper
          style={{
            backgroundColor: "var(--color-blk)",
            padding: "14% 18%",
          }}
        >
          <>
            <BannerOverlayText
              style={{
                width: "35%",
                fontWeight: 600,
                fontSize: "2rem",
                letterSpacing: "0rem",
                textTransform: "capitalize",
              }}
              data-scroll
              data-scroll-speed="1"
            >
              Cohesive <br /> community
            </BannerOverlayText>
          </>

          <DetailedSayingWrapper
            style={{ marginTop: "5rem", marginBottom: "3rem" }}
            data-scroll
            data-scroll-speed="1"
          >
            <ActionLinks
              style={{
                textTransform: "none",
                lineHeight: "2",
                fontWeight: "400",
                width: "45%",
                letterSpacing: "0.08rem",
              }}
            >
              Our team firmly believes to be a well-connected and cohesive
              community. We push each other to get better; we could never be as
              good alone as we are together. We are a team of talented
              individuals, putting best results to the table with the teamwork.
            </ActionLinks>
          </DetailedSayingWrapper>
        </ServicesWrapper>
      </SectionWrapper>

      <SectionWrapper style={{ height: "260vh" }} data-scroll-section>
        <ServicesWrapper
          style={{ height: "auto", position: "unset", padding: "14% 8%" }}
        >
          <InsideSectionWrapper
            style={{
              padding: "0%",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <BannerOverlaySubtext style={{ padding: "0% 3%" }}>
              Leadership team
            </BannerOverlaySubtext>
          </InsideSectionWrapper>
          <div
            style={{
              marginTop: "8rem",
              padding: "0rem 6rem",
              display: "flex",
              width: "100%",
              height: "auto",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            data-scroll
            data-scroll-speed="1"
          >
            <img
              src={OurTeam1}
              style={{ width: "30%", height: "auto", objectFit: "cover" }}
              alt=""
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                width: "auto",
                height: "auto",
                margin: "5rem 0rem 0rem 6rem",
              }}
            >
              <BannerOverlayText
                style={{
                  width: "100%",
                  marginTop: "0rem",
                  marginBottom: "1rem",
                  fontWeight: "600",
                  fontFamily: "roboto",
                  fontSize: "2rem",
                }}
              >
                Madan Kumar
              </BannerOverlayText>
              <ActionLinks
                style={{ fontWeight: "300", textTransform: "capitalize" }}
              >
                Founder & CEO
              </ActionLinks>
              <SubActWrapper
                style={{ marginTop: "2.5rem", marginLeft: "3rem" }}
              >
                <ActionLinkTag
                  href="https://www.linkedin.com/in/madan-kumar-gandam-05b59915/"
                  target="_blank"
                >
                  View bio
                </ActionLinkTag>
              </SubActWrapper>
            </div>
          </div>

          <div
            style={{
              marginTop: "4rem",
              padding: "0rem 6rem",
              display: "flex",
              width: "100%",
              height: "auto",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
            data-scroll
            data-scroll-speed="1"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "center",
                width: "auto",
                height: "auto",
                margin: "5rem 6rem 0rem 0rem",
              }}
            >
              <BannerOverlayText
                style={{
                  width: "100%",
                  marginTop: "0rem",
                  marginBottom: "1rem",
                  fontWeight: "600",
                  fontFamily: "roboto",
                  fontSize: "2rem",
                }}
              >
                Archana
              </BannerOverlayText>
              <ActionLinks
                style={{ fontWeight: "300", textTransform: "capitalize" }}
              >
                Director
              </ActionLinks>
              <SubActWrapper
                style={{ marginTop: "2.5rem", marginRight: "3rem" }}
              >
                <ActionLinkTag
                  href="https://www.linkedin.com/in/archana-yentrapati-4a813956/"
                  target="_blank"
                >
                  View bio
                </ActionLinkTag>
              </SubActWrapper>
            </div>
            <img
              src={OurTeam2}
              style={{ width: "30%", height: "auto", objectFit: "cover" }}
              alt=""
            />
          </div>

          <div
            style={{
              marginTop: "4rem",
              padding: "0rem 6rem",
              display: "flex",
              width: "100%",
              height: "auto",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            data-scroll
            data-scroll-speed="1"
            // data-scroll-direction="horizontal"
          >
            <img
              src={OurTeam3}
              style={{ width: "30%", height: "auto", objectFit: "cover" }}
              alt=""
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                width: "auto",
                height: "auto",
                margin: "5rem 0rem 0rem 6rem",
              }}
            >
              <BannerOverlayText
                style={{
                  width: "100%",
                  marginTop: "0rem",
                  marginBottom: "1rem",
                  fontWeight: "600",
                  fontFamily: "roboto",
                  fontSize: "2rem",
                }}
              >
                Chaitanya
              </BannerOverlayText>
              <ActionLinks
                style={{ fontWeight: "300", textTransform: "capitalize" }}
              >
                Director
              </ActionLinks>
              <SubActWrapper
                style={{ marginTop: "2.5rem", marginLeft: "3rem" }}
              >
                <ActionLinkTag
                  href="https://www.linkedin.com/in/chaitanya-nvk-b6719018/"
                  target="_blank"
                >
                  View bio
                </ActionLinkTag>
              </SubActWrapper>
            </div>
          </div>
        </ServicesWrapper>
      </SectionWrapper>
    </>
  )
}

export default OurTeamContext
