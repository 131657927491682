import React from "react"
import styled from "styled-components"
import { MenuStyledLinkWrapper } from "../../building-blocks/atoms/containers/containers"
import { MenuStyledLink } from "../../building-blocks/atoms/texts/texts"

const StyledLabel = styled.label`
  cursor: pointer;
  z-index: 18;
  position: absolute;
  top: 10%;
  right: 9%;
  transform: translate(-50%, -50%);
  & img {
    display: block;
    height: auto;
  }
  .bar-wrapper,
  .bar {
    height: 100%;
    width: 100%;
    right: 0;
    top: 0;
    position: absolute;
  }
  .bar {
    &:before,
    &:after {
      content: "";
      width: 50%;
      height: 7%;
      background: var(--color-wht);
      opacity: 0.7;
      display: block;
      position: absolute;
      right: 0;
      transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.2);
      border-radius: 20px;
    }
    &:before {
      right: 9%;
    }
    &:after {
      right: 9%;
      right: initial;
    }
  }
  .top-bar {
    &:before,
    &:after {
      top: 10%;
    }
  }
  .middle-bar {
    display: none;
    &:before,
    &:after {
      top: 50%;
      transform: translate(0, -50%);
      transition: 0.2s ease 0.1s;
    }
  }
  .bottom-bar {
    &:before,
    &:after {
      bottom: 10%;
    }
  }
  input {
    display: none;
    &:checked {
      & ~ .bar-wrapper {
        transform: rotate(90deg);
        transition: 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        .middle-bar {
          display: none;
          &:before,
          &:after {
            width: 0%;
            border-radius: 20px !important;
          }
        }
        .top-bar {
          &:before {
            transform-origin: 0 100%;
            transform: rotate(45deg) translate(10%, -70%);
            right: 39%;
          }
          &:after {
            transform-origin: 100% 100%;
            transform: rotate(-45deg) translate(-10%, -70%);
            right: 0%;
          }
        }
        .bottom-bar {
          &:before {
            transform-origin: 0 0;
            transform: rotate(-45deg) translate(10%, 70%);
            right: 39%;
          }
          &:after {
            transform-origin: 100% 0;
            transform: rotate(45deg) translate(-10%, 70%);
            right: 0%;
          }
        }
      }
    }
  }
`

const MenuLink = ({ menuOpen, setMenuOpen }) => {
  const [isMobile, setisMobile] = React.useState(null)

  const changeMobile = () => {
    window.matchMedia("(max-width: 37.5em)").matches
      ? setisMobile(true)
      : setisMobile(false)
  }

  React.useEffect(() => {
    changeMobile({ setisMobile })
    window.addEventListener("resize", changeMobile)
    return () => window.removeEventListener("resize", changeMobile)
  }, [])

  return (
    <>
      {isMobile ? (
        <StyledLabel>
          <input
            type="checkbox"
            checked={menuOpen}
            onChange={() => setMenuOpen(!menuOpen)}
          />
          <div className="bar-wrapper">
            <div className="bar top-bar" />
            {/* <div className="bar middle-bar" /> */}
            <div className="bar bottom-bar" />
          </div>
          <img
            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
            width="20px"
            alt="Hamburguer Mobile Menu"
          />
        </StyledLabel>
      ) : (
        <MenuStyledLinkWrapper onClick={() => setMenuOpen(!menuOpen)}>
          <MenuStyledLink>menu</MenuStyledLink>
        </MenuStyledLinkWrapper>
      )}
    </>
  )
}

export default MenuLink
