import styled from "styled-components"
import {
  SectionWrapper,
  ServicesWrapper,
} from "../../building-blocks/atoms/containers/SendRequestContainer"

import {
  BannerOverlayLink,
  BannerOverlayLink2,
} from "../../building-blocks/atoms/texts/SendRequestText"

const SpanHover = styled.span`
  color: var(--color-blk);

  transition: all var(--speed);

  &:hover {
    color: var(--color-pri);
  }

  &:focus {
    color: var(--color-pri);
  }

  &:active {
    color: var(--color-pri);
  }
`

const LDiv = styled.div`
  background: #94e2ff;
  height: 1px;
  width: 7.4rem;
  margin-right: 3rem;
  @media ${({ theme }) => theme.mediaQueries.mobile}{
    height: 1px;
    width: 6.4rem;
    margin-right: 1rem;
    margin-bottom: 7rem;
    margin-left: 2rem;
  }
`

const SendRequest = () => {
  return (
    <SectionWrapper data-scroll-section>
      <ServicesWrapper>
        <LDiv></LDiv>
        
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <>
            <BannerOverlayLink
              href="mailto:info@shubhartha.com"
              style={{ color: "black" }}
              data-scroll
              data-scroll-speed="1"
            >
              Send a Request.
            </BannerOverlayLink>
          </>
          <>
            <BannerOverlayLink2
              href="mailto:info@shubhartha.com"
              style={{
                color: "black",
              }}
              data-scroll
              data-scroll-speed="2"
            >
              Or <SpanHover>say Hello</SpanHover>
            </BannerOverlayLink2>
          </>
        </div>
      </ServicesWrapper>
    </SectionWrapper>
  )
}

export default SendRequest

