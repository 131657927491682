import React, { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

function SnackbarComponent({ prostats, msg, setStatusBase }) {
  const [open, setOpen] = useState(false);
  const [vertical, setvertical] = useState("top");
  const [horizontal, sethorizontal] = useState("center");

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  }

  useEffect(() => {
    setOpen(true);
    setTimeout(() => {
      handleClose();
      setStatusBase(null);
    }, 2300);
  }, [prostats]);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return (
      <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
    );
  });

  // console.log(msg, prostats, setStatusBase);

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={prostats}
        sx={{ width: "100%" }}
      >
        {msg || `Status: ${prostats}`}
      </Alert>
    </Snackbar>
  );
}

export default SnackbarComponent;
