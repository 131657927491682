import { StyledText } from "../ui/StyledText"

export const Text = ({
  fontSize,
  fontWeight,
  padding,
  margin,
  width,
  height,
  color,
  textAlign,
  lineHeight,
  letterSpacing,
  wordBreak,
  textTransformation,
  backgroundColor,
  borderRadius,
  label,
  fontSizeMobile,
  marginMobile,
  fontWeightMobile,
  paddingMobile,
}) => {
  return (
    <StyledText
      fontSize={fontSize}
      fontWeight={fontWeight}
      padding={padding}
      margin={margin}
      width={width}
      height={height}
      color={color}
      textAlign={textAlign}
      lineHeight={lineHeight}
      letterSpacing={letterSpacing}
      wordBreak={wordBreak}
      textTransformation={textTransformation}
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
      fontSizeMobile={fontSizeMobile}
      marginMobile={marginMobile}
      fontWeightMobile={fontWeightMobile}
      paddingMobile={paddingMobile}
    >
      {label}
    </StyledText>
  )
}
