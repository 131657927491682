// import { Link } from "react-router-dom"
import styled from "styled-components"

export const SectionWrapper = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 90vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    height: 100vh;
  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro} {
    height: 70vh;
  }

  @media ${({ theme }) => theme.mediaQueries.tablet} {
    height: 70vh;
    padding-bottom: 0%;
  }


`

export const SubActWrapper = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;
  margin-left: 19rem;

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    justify-content: flex-start;
    margin-top: 0%;
    padding: 5% 8%;
    margin: 0%;
    margin-left: 0%;
  }
`

export const SayWrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: flex-start;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    margin-top: 18%;
  }
`

export const DetailedSayingWrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 5rem;
  margin-bottom: 3rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    margin: 0%;
    justify-content: flex-start;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    margin: 0%;
    justify-content: flex-start;
  }
`

export const ServicesWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 130vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 14% 18%;
  background-color: var(--color-wht);

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 20% 12% 30% 12%;
    height: 100vh;
  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro} {
    height: 90vh;
    padding-bottom: 0%;
  }

  @media ${({ theme }) => theme.mediaQueries.tablet}{
    padding: 0% 12% 0% 12%;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    display: flex;
    /* -webkit-flex-direction: column; */
    -ms-flex-direction: column;
    /* -webkit-flex-direction: column; */
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    height: 100%;
    /* -webkit-align-items: center; */
    /* -webkit-box-align: center; */
    -ms-flex-align: center;
    /* -webkit-align-items: flex-start; */
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    /* -webkit-justify-content: flex-start; */
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding: 6% 18%;
  }
`

////////////////////////////////////////////

export const TitleBg = styled.div`
  background: var(--color-sec);
  width: 6%;
  height: 1.2rem;
  position: relative;
  top: 20%;
  left: -16%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: none;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    display: none;
  }
`

export const InsideSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 6% 18%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 10% 12%;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    align-items: flex-start;
  }
`