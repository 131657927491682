import React from "react"
import styled from "styled-components"
import {
  DetailedSayingWrapper,
  InsideSectionWrapper,
  SectionWrapper,
  ServicesWrapper,
  DetailedSayingWrapper1,
  InsideSectionWrapper1,
  SectionWrapper1,
  SectionWrapper2,
  InsideSectionWrapper2,
  SectionWrapper3,
  ServicesWrapper1,
  DetailedSayingWrapper2,
  InsideSectionWrapper3,
} from "../../building-blocks/atoms/containers/ProspectContextContainer"
import {
  BannerOverlayText,
  // BannerOverlaySubtext,
  ActionLinks,
  ActionLinks1,
  BannerOverlayText2,
  BannerOverlayText1,
  BannerOverlaySubtext1,
  ActionLinks2,
  BannerOverlayText4,
  BannerOverlayText3,
} from "../../building-blocks/atoms/texts/ProspectContextText"
import Prospect1 from "../../assets/images/prospect1.jpg"
import Prospect2 from "../../assets/images/prospect2.jpg"
import Prospect3 from "../../assets/images/prospect3.jpg"
import Prospect4 from "../../assets/images/prospect4.jpg"
import { scroll } from "../../utils/locomotiveScroll"
import { v4 as uuidv4 } from "uuid"

const List = [
  {
    id: uuidv4(),
    step: "01",
    img: Prospect2,
    product: "Mshoppi",
    heading: "Digitalizing malls globally",
    paragraph: `Enhance your shopping experience by visiting your favourite mall
    digitally. Mshoppi eliminates the distance between you and your
    preferred store of every mall out there in the city.
    <br/>
    <br/>
    The retail is in the midst of transformation. The future is today:
    In view of the advancing digitization in retail and the greatly
    changed customer needs, Mshoppi is pushing ahead with the
    expansion of shopping centers to digital omnichannel platforms.
    With the digital mall developed by us, the products of the retail
    partners in the centers are also visible online for the first
    time. Consumers can use the website and app to find out about what
    is available in the shopping center at anytime and anywhere in
    real time. We are responding to changed shopping habits and the
    increasing need for convenience because a large number of
    customers prepare their purchases online and want an online
    availability check for physical shops.`,
  },
  {
    id: uuidv4(),
    step: "02",
    img: Prospect3,
    product: "Yallaparty",
    heading: "Let's party anytime",
    paragraph: `Yalla Party is one of the best online and in-store party suppliers in the world. We offer a wide range of theme-based party balloons, hats, blowouts, costumes, pinatas, tableware, kits and accessories for kids and adults alike`,
  },
 
  {
    id: uuidv4(),
    step: "03",
    img: Prospect4,
    product: "Quebuster",
    heading: "The Powerful POS machine",
    paragraph: `Turn your smartphone or tablet into a powerful POS. Save your time, energy and make order processing really quick. Manage sales, inventory and employees with ease; engage customers and increase your revenue. Whether you have single or multiple stores, our tools help run your business.`,
  },
 

]


const BackImg = styled.img`
  width: 68vw;
  object-fit: cover;
  height: 100vh;
  margin-top: 20vh;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 78vw;
    object-fit: cover;
    height: 82vh;
    margin-top: 12vh;
  }
`

const Image = styled.img`
  margin-top: 40vh;
  width: 75%;
  height: 135vh;
  object-fit: cover;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    margin-top: 30vh;
    width: 100%;
    height: 40vh;
    object-fit: cover;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet_landscape}{
    height: 90vh;
  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro}{
    height: 70vh;
    margin-top: 20vh;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet}{
    height: 70vh;
    margin-top: 20vh;
    width: 75%;
  }
`

const BannerOverlayTextBig = styled.h1`
  color: var(--color-sec);
  font-family: graphik;
  font-size: 16rem;
  -webkit-letter-spacing: 0.1rem;
  -moz-letter-spacing: 0.1rem;
  -ms-letter-spacing: 0.1rem;
  letter-spacing: 0.1rem;
  font-weight: 700;
  width: 100%;
  text-align: center;
  /* padding-bottom: 10%; */
  margin-top: -3rem;
  margin-left: 4rem;
  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 4rem;
  }

  @media ${({ theme }) => theme.mediaQueries.tablet} {
    font-size: 14rem;
    margin-left: 6rem;
    margin-top: -5rem;

  }
`
const TitleBg = styled.div`
  background: var(--color-sec);
  color:var(--color-sec);
  position: absolute;
  top: 8px;
  left: 180px;
  z-index: -1;
  width: 5%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    // display: none;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    // display: none;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet_landscape} {
    top: 1%;
    left: 12.5%;
    width: 8%;
  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro}{
    top: 0.7%;
    left: 12.5%;
    width: 8%;
  }
`
function ProspectContext() {
  React.useEffect(() => {
    scroll()
  }, [])

  const [isMobile, setisMobile] = React.useState(null)

  const changeMobile = () => {
    window.matchMedia("(max-width: 37.5em)").matches
      ? setisMobile(true)
      : setisMobile(false)
  }

  React.useEffect(() => {
    changeMobile()
    window.addEventListener("resize", changeMobile)
    return () => window.removeEventListener("resize", changeMobile)
  }, [])

  return (
    <>
      <SectionWrapper1 data-scroll-section>
        <InsideSectionWrapper1>
          <BannerOverlaySubtext1>Prospect-</BannerOverlaySubtext1>
          <TitleBg>tcgvhvhvj</TitleBg>
          <>
            <BannerOverlayText1 data-scroll data-scroll-speed="-2">
              The Next
            </BannerOverlayText1>
          </>
          <>
            <BannerOverlayText2 data-scroll data-scroll-speed="-2">
              Global expansion <br />
              is what we are aiming for...
            </BannerOverlayText2>
          </>
          <>
            <ActionLinks
              style={{
                letterSpacing: "0.28rem",
                marginTop: "17%",
                marginLeft: "3rem",
              }}
              data-scroll
              data-scroll-speed="-2"
            >
              scroll down
            </ActionLinks>
          </>
        </InsideSectionWrapper1>

        <BackImg src={Prospect1} alt="" />
      </SectionWrapper1>

      <SectionWrapper2 data-scroll-section>
        <InsideSectionWrapper2>
          <DetailedSayingWrapper1>
            <ActionLinks1 data-scroll data-scroll-speed="1">
              We believe in making 'The Next' products convenient and
              lucrative for our clients. The purpose is to redefine the
              retailing experience with the help of different platforms of
              Honeysys.
            </ActionLinks1>
          </DetailedSayingWrapper1>
        </InsideSectionWrapper2>
      </SectionWrapper2>
   {isMobile ? 
   (<>
   {List.map(i => (<>
   
   
   </>))}
   </>)
    :
    (<>
    <SectionWrapper3 data-scroll-section>
        <ServicesWrapper1>
          <>
            <BannerOverlayText3 data-scroll data-scroll-speed="2">
              Digitalizing malls globally.
            </BannerOverlayText3>
          </>

          <BannerOverlayTextBig>01</BannerOverlayTextBig>
        </ServicesWrapper1>

        <Image src={Prospect2} alt="" />

        <InsideSectionWrapper3>
          <>
            <BannerOverlayText4
              style={{
                width: "100%",
                marginTop: "6rem",
                fontWeight: "400",
                fontSize: "2.8rem",

                //   {
                // // for mobile
                // font-weight: 400;
                // font-family: roboto;
                // font-size: 1.5rem;
                // width: 100%;
                // line-height: 2.4rem;
                // margin-top: 3rem;
                // margin-bottom: 0rem;
                //   }
              }}
            >
              Mshoppi
            </BannerOverlayText4>
          </>

          <DetailedSayingWrapper2
            style={{ marginTop: "4rem", marginBottom: "5rem" }}
          >
            <ActionLinks2 data-scroll data-scroll-speed="1">
              Enhance your shopping experience by visiting your favourite mall
              digitally. Mshoppi eliminates the distance between you and your
              preferred store of every mall out there in the city.
              <br />
              <br />
              The retail is in the midst of transformation. The future is today:
              In view of the advancing digitization in retail and the greatly
              changed customer needs, Mshoppi is pushing ahead with the
              expansion of shopping centers to digital omnichannel platforms.
              With the digital mall developed by us, the products of the retail
              partners in the centers are also visible online for the first
              time. Consumers can use the website and app to find out about what
              is available in the shopping center at anytime and anywhere in
              real time. We are responding to changed shopping habits and the
              increasing need for convenience because a large number of
              customers prepare their purchases online and want an online
              availability check for physical shops.
            </ActionLinks2>
          </DetailedSayingWrapper2>
        </InsideSectionWrapper3>
      </SectionWrapper3>

      
        <>
          <SectionWrapper
            style={{
              flexDirection: "column",
              height: "auto",
              justifyContent: "flex-start",
              alignItems: "flex-end",
              zIndex: 2,
            }}
            data-scroll-section
          >
            <ServicesWrapper
              style={{
                backgroundColor: "transparent",
                justifyContent: "center",
                zIndex: 2,
                padding: "0% 14%",
                alignItems: "flex-end",
              }}
            >
              <>
                <BannerOverlayText
                  style={{
                    // textAlign: "right",
                    // width: "70%",
                    // fontWeight: "500",
                    // fontFamily: "roboto",
                    // fontSize: "3.8rem",
                  }}
                  data-scroll
                  data-scroll-speed="2"
                >
                  Let's party anytime
                </BannerOverlayText>
              </>
              <BannerOverlayTextBig
                style={{
                  marginTop: "3rem",
                  marginRight: "9rem",
                }}
              >
                02
              </BannerOverlayTextBig>
            </ServicesWrapper>

            <Image
              style={{
                // marginTop: "40vh",
                // width: "75%",
                // height: "135vh",
                // objectFit: "cover",
              }}
              src={Prospect3}
              alt=""
            />

            <InsideSectionWrapper
              style={{
                padding: "3% 36% 9% 36%",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                height: "auto",
              }}
            >
              <>
                <BannerOverlayText
                  style={{
                    width: "100%",
                    marginTop: "6rem",
                    fontWeight: "400",
                    fontSize: "2.8rem",
                  }}
                >
                  Yallaparty
                </BannerOverlayText>
              </>

              <DetailedSayingWrapper
                style={{ marginTop: "4rem", marginBottom: "5rem" }}
              >
                <ActionLinks
                  style={{
                    textTransform: "none",
                    lineHeight: "2",
                    fontWeight: "400",
                    width: "100%",
                    letterSpacing: "0.08rem",
                  }}
                  data-scroll
                  data-scroll-speed="1"
                >
                  Yalla Party is one of the best online and in-store party
                  suppliers in the world. We offer a wide range of theme-based
                  party balloons, hats, blowouts, costumes, pinatas, tableware,
                  kits and accessories for kids and adults alike
                </ActionLinks>
              </DetailedSayingWrapper>
            </InsideSectionWrapper>
          </SectionWrapper>

          <SectionWrapper
            style={{
              flexDirection: "column",
              height: "auto",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              zIndex: 2,
            }}
            data-scroll-section
          >
            <ServicesWrapper
              style={{
                backgroundColor: "transparent",
                justifyContent: "center",
                zIndex: 2,
                padding: "0% 14%",
              }}
            >
              <>
                <BannerOverlayText
                  style={{
                    // width: "40%",
                    // fontWeight: "500",
                    // fontFamily: "roboto",
                    // fontSize: "3.8rem",
                  }}
                  data-scroll
                  data-scroll-speed="2"
                >
                  The Powerful POS machine
                </BannerOverlayText>
              </>
              <BannerOverlayTextBig>03</BannerOverlayTextBig>
            </ServicesWrapper>

            <Image
              style={{
                // marginTop: "40vh",
                // width: "75%",
                // height: "135vh",
                // objectFit: "cover",
              }}
              src={Prospect4}
              alt=""
            />

            <InsideSectionWrapper
              style={{
                padding: "3% 36% 9% 36%",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                height: "auto",
              }}
            >
              <>
                <BannerOverlayText
                  style={{
                    width: "100%",
                    marginTop: "6rem",
                    fontWeight: "400",
                    fontSize: "2.8rem",
                  }}
                >
                  Quebuster
                </BannerOverlayText>
              </>

              <DetailedSayingWrapper
                style={{ marginTop: "4rem", marginBottom: "5rem" }}
              >
                <ActionLinks
                  style={{
                    textTransform: "none",
                    lineHeight: "2",
                    fontWeight: "400",
                    width: "100%",
                    letterSpacing: "0.08rem",
                  }}
                  data-scroll
                  data-scroll-speed="1"
                >
                  Turn your smartphone or tablet into a powerful POS. Save your
                  time, energy and make order processing really quick. Manage
                  sales, inventory and employees with ease; engage customers and
                  increase your revenue. Whether you have single or multiple
                  stores, our tools help run your business.
                </ActionLinks>
              </DetailedSayingWrapper>
            </InsideSectionWrapper>
          </SectionWrapper>
        </>
    </>)}
      
  
    </>
  )
}

export default ProspectContext
