import styled from "styled-components"

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  // margin: 2rem 0rem;
  justify-content: center;
  align-items: center;

  padding: 0% 2% 2%;
  box-shadow: 0 8px 12px -7px rgba(var(--color-drk), 0.2);
  border-radius: 1rem;
`

export const Table = styled.table`
  // height: 100%;
  // width: 98%;
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left;
  // overflow-x: auto;

  scroll-behavior: smooth;
  width: 100%;
  height: 50vh;
  display: block;
  overflow-x: hidden;
  overflow-y: scroll;

  background-color: transparent;
`

export const Thead = styled.thead`
  width: auto;
  height: auto;
`

export const TrHead = styled.tr`
  width: 100%;
  height: auto;
`

export const Th = styled.th`
  height: 3rem;
  font-size: 0.8rem;
  line-height: 0.8rem;
  font-weight: 100;
  padding: 0rem 1rem;
`

export const Tbody = styled.tbody`
  width: 100%;
  height: auto;
`

export const Tr = styled.tr`
  border-top: 1px solid #ddd;
  width: 100%;
  height: auto;
`

export const Td = styled.td`
  height: auto;
  padding: 1rem;
  word-break: break-word;
`

export const TableHeaderText = styled.p`
  color: rgba(var(--color-drk), 0.6);
  font-size: 0.7rem;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1rem;
  margin: 0;
`

export const TableBodyText = styled.p`
  color: rgba(var(--color-drk), 1);
  font-size: 0.8rem;
  text-transform: capitalize;
  font-weight: 400;
  line-height: 1rem;
  margin: 0;
`
