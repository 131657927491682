// import { Link } from "react-router-dom"
import styled from "styled-components"

export const SectionWrapper = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 130vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-wht);
  margin-top: -0.1rem;
  z-index: 2;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 160vh;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    z-index: 0;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    height: 135vh;
  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro}{
    height: 100vh;
  }

  @media ${({ theme }) => theme.mediaQueries.tablet}{
    height: 100vh;
  }

`

export const SubActWrapper = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;
  margin-left: 15rem;

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    justify-content: flex-start;
    margin-top: 0%;
    padding: 8% 14%;
    margin: 0%;
    margin-left: 0%;
    width: 100%;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet}{
  margin-left: 0rem;
  margin-top : 5rem;
  }

`

export const SubActWrapper1 = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;
  margin-left: 15rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
    justify-content: flex-start;
    margin: 2rem 0% 0%;
  }
`

export const SayWrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: flex-start;

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    display: flex;
    width: 100%;
    height: auto;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  @media ${({ theme }) => theme.mediaQueries.ipad_pro}{
   margin-left: 5rem;
  }
`

export const SayingWrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: flex-start;
  margin: 6rem 0rem;
  width: 100%;
`
export const DetailedSayingWrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 3rem;
  margin-top: 5rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    margin: 0%;
    justify-content: flex-start;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    margin: 0%;
    justify-content: flex-start;
  }
`

export const ServicesWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  background-color: transparent;
  padding: 6% 0% 6% 45%;
  z-index: 2;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 80vh;
    position: unset;
    padding: 10% 12% 20% 12%;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    padding: 6% 8% 6% 50%;
  }

  @media ${({ theme }) => theme.mediaQueries.tablet}{
    position: absolute;
    padding: 10% 62% 0% 12%;
    left: 25rem;;
  }

`

/////////////////////////////////////////

export const TitleBg = styled.div`
  background: var(--color-sec);
  width: 6%;
  height: 1.2rem;
  position: relative;
  top: 20%;
  left: -14%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: none;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    display: none;
  }
`
