import React from "react"
import {
  BannerWrapper,
  BannerOverlayWrapper,
  BannerOverlayTextWrapper,
  ActionFirst,
  SubActWrapper,
  ScrollLine,
  PrevNextWrapper,
  ActionSecond,
  TitleBg,
} from "../../building-blocks/atoms/containers/HomeBannerContainer"
import {
  BannerOverlaySubtext,
  BannerOverlayText,
  ActionLinks,
  // ActionLinks2,
} from "../../building-blocks/atoms/texts/HomeBannerText"
import { BannerImg } from "../../building-blocks/atoms/images/HomeBannerImage"
import HeroBanner from "../../assets/images/hero_banner.png"

const HomeBanner = () => {
  const [isMobile, setisMobile] = React.useState(null)

  const changeMobile = () => {
    window.matchMedia("(max-width: 37.5em)").matches
      ? setisMobile(true)
      : setisMobile(false)
  }

  React.useEffect(() => {
    changeMobile()
    window.addEventListener("resize", changeMobile)
    return () => window.removeEventListener("resize", changeMobile)
  }, [])

  return (
    <>
      <BannerWrapper data-scroll-section style={{ justifyContent: "flex-end" }}>
        <BannerImg src={HeroBanner} alt="" data-scroll data-scroll-speed="-3" />
        <BannerOverlayWrapper>
          <BannerOverlayTextWrapper data-scroll data-scroll-speed="-2">
            <BannerOverlaySubtext style={{ zIndex: "2", }}>
              We are committed in -
            </BannerOverlaySubtext>

            <TitleBg />

            <BannerOverlayText>
            Building long-term<br/>relationships based on integrity,<br/>performance, value and client satisfaction.
            </BannerOverlayText>
          </BannerOverlayTextWrapper>

          {isMobile ? (
            <PrevNextWrapper>
              {/* <ActionLinks2 left>prev</ActionLinks2>
              <ActionLinks2>next</ActionLinks2> */}
            </PrevNextWrapper>
          ) : (
            <>
              <ActionFirst data-scroll data-scroll-speed="-1">
                {/* <SubActWrapper>
                  <ActionLinks>explore more</ActionLinks>
                </SubActWrapper>
                <PrevNextWrapper>
                  <ActionLinks2 left>prev</ActionLinks2>
                  <ActionLinks2>next</ActionLinks2>
                </PrevNextWrapper> */}
              </ActionFirst>
              <ActionSecond
                style={{
                  position: "absolute",
                  bottom: "-5rem",
                }}
                data-scroll
                data-scroll-speed="-2"
              >
                <SubActWrapper style={{ flexDirection: "column" }}>
                  <ScrollLine></ScrollLine>

                  <ActionLinks style={{ letterSpacing: "0.28rem" }}>
                    scroll down
                  </ActionLinks>
                </SubActWrapper>
              </ActionSecond>
            </>
          )}
        </BannerOverlayWrapper>
      </BannerWrapper>
    </>
  )
}

export default HomeBanner
