import { Link } from "react-router-dom"
import styled from "styled-components"

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  padding: 0% 4%;
  overflow: hidden;
  background-color: var(--wht);
  overflow-x: hidden;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 0% 7%;
    height: auto;
  }
`

export const SectionFlexWrapper = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: ${(props) => props.widthMobile};
    height: ${(props) => props.heightMobile};
    padding: ${(props) => props.paddingMobile};
    margin: ${(props) => props.marginMobile};
    align-items: ${(props) => props.alignItemsMobile};
  }
`

export const SectionGridWrapper = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  display: grid;
  grid-template-columns: ${(props) => props.gridTemplateComlumns};
  grid-template-rows: ${(props) => props.gridTemplateRows};
  gap: ${(props) => props.gap};
  grid-auto-rows: ${(props) => props.gridAutoRows};
  grid-auto-flow: ${(props) => props.gridAutoFlow};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: ${(props) => props.heightMobile};
    grid-template-columns: ${(props) => props.gridTemplateColumnsMobile};
    grid-template-rows: ${(props) => props.gridTemplateRowsMobile};
    margin: ${(props) => props.marginMobile};
    padding: ${(props) => props.paddingMobile};
    gap: ${(props) => props.gapMobile};
  }
`

export const CardWrapper = styled.div`
  text-decoration: none;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 9fr 1fr 1fr 1fr;
  gap: 0;
  grid-auto-rows: max-content;
  grid-auto-flow: row;
  align-items: center;
  justify-content: center;
  background-color: #eee;
  transition-property: all;
  transition-duration: 420ms;
  transition-timing-function: ease;
  transition-delay: 0s;
  border-radius: 1rem;
  margin: 0;
  padding: 1rem;
  box-shadow: 0px 0px 10px -4px #585858;
  border: 0.2px solid white;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    grid-template-rows: 5fr 1fr 1fr 1fr;
    padding: 0.5rem;
  }
`

export const SectionFlexWrapperLink = styled(Link)`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  text-decoration: none;
  cursor: pointer;
  justify-self: center;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: ${(props) => props.paddingMobile};
  }
`

// export const HorizontalScrollableSection = styled.div``

export const UserCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid var(--blk4);
  padding: 10% 12%;

  // backdrop-filter: blur(3rem);
  // background: rgba(255, 255, 255, 0.25);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 1rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 4%;
    flex-direction: row;
  }
`

export const Modal = styled.div`
  position: fixed;
  top: 12%;
  left: 25%;
  background-color: var(--wht);
  z-index: 120;
  display: flex;
  box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 20%);
  flex-direction: column;
  padding: 3%;
  align-items: center;
  justify-content: center;
  width: 50vw;
  height: auto;
`

export const FileInputWrapper = styled.div`
  border: 2px dashed var(--gold2);
  width: 90%;
  height: auto;
  margin: 1% ​2% 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem;
  margin: 0 0 2rem;
`
