import React from "react"
import styled from "styled-components"
import { v4 as uuidv4 } from "uuid"
import {
  SectionWrapper,
  SectionWrapper2,
  InsideSectionWrapper,
  SayWrapper,
  SayingWrapper,
  DetailedSayingWrapper,
  ActionSecond,
  ActionSecond2,
  SubActWrapper,
  CardImageWrapper,
  CardTextWrapper,
  CardHeaderWrapper2,
  CardSubtextWrapper,
  ContentWrapperLink,
} from "../../building-blocks/atoms/containers/WhatWeDoContainer"
import { CardImg } from "../../building-blocks/atoms/images/images"
import {
  ActionLink,
  ActionLinks,
  CardHeaderText2,
  CardNumberText2,
  CardSubText2,
  SayingText,
  SubActionLinks,
} from "../../building-blocks/atoms/texts/WhatWeDoText"
import ScrollImg1 from "../../assets/images/scroll1.jpg"
import ScrollImg2 from "../../assets/images/scroll2.jpg"
import ScrollImg3 from "../../assets/images/scroll3.jpg"
import ScrollImg4 from "../../assets/images/scroll4.jpg"
import ScrollImg5 from "../../assets/images/scroll5.jpg"
import ScrollImg6 from "../../assets/images/scroll6.jpg"
import ScrollImg7 from "../../assets/images/scroll7.jpg"
import RightWhite from "../../assets/icons/right_white.png"

const LIST = [
  {
    id: uuidv4(),
    step: "1 / 5",
    img: ScrollImg7,
    title: "Fast Moving Consumer Goods",
    context:
      "Maintaining our capability in Investment, Destribution, Supply and Inculcate with Fast Moving Consumer Goods.",
  },
  {
    id: uuidv4(),
    step: "2 / 5",
    img: ScrollImg1,
    title: "Agro Products",
    context:
      "One of the most exciting evolutions revolves around before globalization hit.",
  },
  {
    id: uuidv4(),
    step: "3 / 5",
    img: ScrollImg2,
    title: "Petro & Gas Products",
    context:
      "We provide and deal in all the categories of PetroChemicals and Gas in supply.",
  },
  {
    id: uuidv4(),
    step: "4 / 5",
    img: ScrollImg3,
    title: "Personal Protective Equipments",
    context:
      "We deal in best in class PPEs in supply and demand for daily use-cases with the feild standardized matches.",
  },
  {
    id: uuidv4(),
    step: "5 / 5",
    img: ScrollImg4,
    title: "Precious Metals",
    context:
      "We maintains an immense involvement in the industrial and commercial mining industry.",
  }
]

const WhatWeDo = () => {
  const [isMobile, setisMobile] = React.useState(null)
  const [isIpad, setisIpad] = React.useState(null)


  const changeIpad = () => {
    window.matchMedia("(max-width: 1024px)").matches
      ? setisIpad(true)
      : setisIpad(false)
  }

  const changeMobile = () => {
    window.matchMedia("(max-width: 37.5em)").matches
      ? setisMobile(true)
      : setisMobile(false)
  }

  React.useEffect(() => {
    changeMobile()
    changeIpad()
    window.addEventListener("resize", changeMobile)
    return () => window.removeEventListener("resize", changeMobile)
  }, [])

  const parseStep = (steps) => {
    steps = steps.split("/")
    steps = (
      <span>
        <span style={{ color: "white" }}>{steps[0]}</span>/{steps[1]}
      </span>
    )
    return steps
  }

  return (
    <>
      <SectionWrapper data-scroll-section style={{ zIndex: 2 }}>
        <InsideSectionWrapper>
          <SayWrapper>
            <ActionLinks>What we do</ActionLinks>
          </SayWrapper>
          <SayingWrapper data-scroll data-scroll-speed="1">
            <SayingText>
            Our company engages in the business of Import/Export, Trading, Man Power Facilitation & other Services.
            </SayingText>
          </SayingWrapper>
          <DetailedSayingWrapper data-scroll data-scroll-speed="1">
            <SubActionLinks>
            We are a company that provides a broad spectrum in trading of petroleum
gas & products, precious metals, agricultural products, construction materials,
PPEʼS (Personal Protective Equipments) and other products related thereto on
wholesale and retail basis, and also maintains an immense involvement in the
industrial and commercial mining industry.
It is backed by most experienced and efficient team of professionals who have
expertise and also several years of experience.
            </SubActionLinks>
          </DetailedSayingWrapper>

          {/* {isMobile ? (
            <>
              <ActionSecond
                style={{
                  justifyContent: "flex-start",
                  padding: "0%",
                  marginTop: "0rem",
                }}
              >
                <SubActWrapper
                  data-scroll
                  data-scroll-speed="2"
                  style={{
                    width: "100%",
                    justifyContent: "flex-start",
                  }}
                >
                  <ActionLink
                    to="/what-we-do"
                    style={{
                      alignItems: "center",
                      justifyContent: "flex-start",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    explore more{" "}
                    <img
                      src={RightWhite}
                      style={{
                        marginLeft: "0.5rem",
                        width: "7%",
                        height: "auto",
                        objectFit: "cover",
                      }}
                      alt=""
                    />
                  </ActionLink>
                </SubActWrapper>
              </ActionSecond>
            </>
          ) : (
            <>
              <ActionSecond2>
                <SubActWrapper data-scroll data-scroll-speed="2">
                  <div
                    style={{
                      background: "#94e2ff",
                      height: "1px",
                      width: "3.5rem",
                      marginRight: "5px",
                    }}
                  ></div>
                  <ActionLink to="/what-we-do">explore more</ActionLink>
                </SubActWrapper>
              </ActionSecond2>
            </>
          )} */}
        </InsideSectionWrapper>
      </SectionWrapper>

      {isMobile ? (
        <>
          <SectionWrapper2 data-scroll-section>
            <HorizontalScrollableSectionWrapper2 style={{}}>
              {LIST.map((item) => (
                <ContentWrapperLink
                  data-scroll
                  data-scroll-speed="3"
                  data-scroll-direction="horizontal"
                  to={item.title.replace(/\s+/g, "-").toLowerCase()}
                >
                  <CardImageWrapper>
                    <CardImg src={item.img} alt="" />
                  </CardImageWrapper>
                  <CardTextWrapper>
                    <CardHeaderWrapper2>
                      <CardNumberText2>{parseStep(item.step)}</CardNumberText2>
                      <CardHeaderText2>{item.title}</CardHeaderText2>
                    </CardHeaderWrapper2>
                    <CardSubtextWrapper>
                      <CardSubText2>{item.context}</CardSubText2>
                    </CardSubtextWrapper>
                  </CardTextWrapper>
                </ContentWrapperLink>
              ))}
            </HorizontalScrollableSectionWrapper2>
          </SectionWrapper2>
        </>
      ) : (
        <>
          <SectionWrapper2 data-scroll-section>
            <HorizontalScrollableSectionWrapper2 style={{}}>
              {LIST.map((item) => (
                <ContentWrapperLink
                  data-scroll
                  data-scroll-speed="3"
                  data-scroll-direction="horizontal"
                  to={item.title.replace(/\s+/g, "-").toLowerCase()}
                >
                  <CardImageWrapper>
                    <CardImg src={item.img} alt="" />
                  </CardImageWrapper>
                  <CardTextWrapper>
                    <CardHeaderWrapper2>
                      <CardNumberText2>{parseStep(item.step)}</CardNumberText2>
                      <CardHeaderText2>{item.title}</CardHeaderText2>
                    </CardHeaderWrapper2>
                    <CardSubtextWrapper>
                      <CardSubText2>{item.context}</CardSubText2>
                    </CardSubtextWrapper>
                  </CardTextWrapper>
                </ContentWrapperLink>
              ))}
            </HorizontalScrollableSectionWrapper2>
          </SectionWrapper2>
        </>
      )}
    </>
  )
}

export default WhatWeDo


const HorizontalScrollableSectionWrapper2 = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5,calc(100% - 50%));
  column-gap: 22%;
  padding-left: 25rem;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  
  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4,calc(100% - 50%));
    column-gap: 22%;
    padding-left: 15vw;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
  }
  
  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4,calc(100% - 50%));
    column-gap: 22%;
    padding-left: 15vw;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet}{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4,calc(100% - 50%));
    column-gap: 22%;
    padding-left: 15vw;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet_landscape}{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4,calc(100% - 50%));
    column-gap: 22%;
    padding-left: 15vw;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;

  }

  @media ${({ theme }) => theme.mediaQueries.ipad_pro}{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4,calc(100% - 50%));
    column-gap: 22%;
    padding-left: 15vw;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
  }

`