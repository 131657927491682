import { Link } from "react-router-dom"
import styled from "styled-components"
import { ShaderImage } from "./ShaderImage"

export const StyledButton = styled.button`
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  border: ${(props) => props.border};

  text-transform: capitalize;
  border-radius: 0.2rem;
  cursor: pointer;
  line-height: 0%;
  transition-property: all;
  transition-duration: 420ms;
  transition-timing-function: ease;
  transition-delay: 0s;
  text-decoration: none;

  &:hover {
    background-color: ${(props) => props.backgroundColorHover};
    color: ${(props) => props.colorHover};
    border: ${(props) => props.borderHover};

    transform: translateY(-2px);
    background-image: url(${ShaderImage});
  }

  &:focus {
    background-color: ${(props) => props.backgroundColorFocus};
    color: ${(props) => props.colorFocus};
    border: ${(props) => props.borderFocus};

    transform: translateY(-2px);
    background-image: url(${ShaderImage});
  }

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: ${(props) => props.fontSizeMobile};
    padding: ${(props) => props.paddingMobile};
    margin: ${(props) => props.marginMobile};
    width: ${(props) => props.widthMobile};
    height: ${(props) => props.heightMobile};
    line-height: 1.2rem;
  }
`

export const StyledButtonLink = styled(Link)`
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  border: ${(props) => props.border};

  text-transform: capitalize;
  border-radius: 50rem;
  cursor: pointer;
  line-height: 0%;
  transition-property: all;
  transition-duration: 420ms;
  transition-timing-function: ease;
  transition-delay: 0s;
  text-decoration: none;

  &:hover {
    background-color: ${(props) => props.backgroundColorHover};
    color: ${(props) => props.colorHover};
    border: ${(props) => props.borderHover};

    transform: translateY(-2px);
    background-image: url(${ShaderImage});
  }

  &:focus {
    background-color: ${(props) => props.backgroundColorFocus};
    color: ${(props) => props.colorFocus};
    border: ${(props) => props.borderFocus};

    transform: translateY(-2px);
    background-image: url(${ShaderImage});
  }

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: ${(props) => props.fontSizeMobile};
    padding: ${(props) => props.paddingMobile};
    margin: ${(props) => props.marginMobile};
    width: ${(props) => props.widthMobile};
    height: ${(props) => props.heightMobile};
    line-height: 1.2rem;
  }
`
