import { SectionFlexWrapper } from "../ui/StyledContainer";
import {
  Table,
  TableWrapper,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  TrHead,
  TableHeaderText,
  TableBodyText,
} from "../components/Table";
import { Text } from "../components/Text";

export const LedgerList = ({
  title,
  transactionHeaders,
}) => {
  return (
    <SectionFlexWrapper
      width="100%"
      height="auto"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      margin="0"
      padding="0"
    >
      <Text
        fontSize="1rem"
        fontWeight="700"
        padding="0"
        margin="0 0 1rem"
        width="auto"
        height="auto"
        color="var(--blk1)"
        textAlign="start"
        lineHeight="1.2rem"
        letterSpacing="unset"
        wordBreak="unset"
        textTransformation="capitalize"
        label={title}
        fontSizeMobile="0.8rem"
        marginMobile="0 0.4rem 0"
      />

      <TableWrapper>
        <Table>
          <Thead>
            <TrHead>
              <Th
                style={{
                  width: "20%",
                }}
              >
                <TableHeaderText>From</TableHeaderText>
              </Th>

              <Th
                style={{
                  width: "80%",
                }}
              >
                <TableHeaderText>Txn Hash</TableHeaderText>
              </Th>
            </TrHead>
          </Thead>

          <Tbody>
            {transactionHeaders?.map((item) => (
              <Tr>
                <Td>
                  <TableBodyText>{item?.from?.slice(0, 9)}</TableBodyText>
                </Td>
                <Td>
                  <TableBodyText>{item?.txnHash.slice(0, 45)}...</TableBodyText>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableWrapper>
    </SectionFlexWrapper>
  );
};
