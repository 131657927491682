import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { AuthForm } from "../components/AuthForm";
import {
  ProfileDrop,
  ProfileHeader,
  ProfileIcons,
} from "../ui/StyledAuth";
import { StyledContainer } from "../ui/StyledContainer";
import { useWindowSize } from "./useWindowSize";
import Login_img from "../assets/icons/login.png";
import Logout_img from "../assets/icons/logout.png";

const Layout = ({ 
    isLoggedIn,
    userId,
    userToken,
    clearToken,
    clearUserId,
    clearIsLoggedIn,setStatus, children }) => {
  const [toggleSignin, setToggleSignin] = useState(false);

  const handleLogout = () => {
    clearToken(null);
    clearUserId(null);
    clearIsLoggedIn(null);
    // history.push("/");
    setStatus("Logged out successfully", "warning");
  };

  return (
    <StyledContainer>
      <ProfileHeader>
        {userToken && isLoggedIn && userId ? (
          <ProfileIcons
            onClick={() => {
              handleLogout();
            }}
          >
            Logout{" "}
            <img
              src={Logout_img}
              alt=""
              style={{
                width: "1.2rem",
                height: "auto",
              }}
            />
          </ProfileIcons>
        ) : (
          <ProfileIcons
            onClick={() => setToggleSignin(!toggleSignin)}
          >
            Login{" "}
            <img
              src={Login_img}
              alt=""
              style={{
                width: "1.2rem",
                height: "auto",
              }}
            />
          </ProfileIcons>
        )}
      </ProfileHeader>

      {toggleSignin && (
        <ProfileDrop
        // onMouseLeave={() => setToggleSignin(false)}
        >
          <AuthForm
            setStatus={setStatus}
            toggleSignin={toggleSignin}
            setToggleSignin={setToggleSignin}
          />
        </ProfileDrop>
      )}

      {children}
    </StyledContainer>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
