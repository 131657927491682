import React from "react"
import {
  SectionWrapper,
  SayWrapper,
  DetailedSayingWrapper,
  SubActWrapper,
  InsideSectionWrapper,
  TitleBg,
} from "../../building-blocks/atoms/containers/OurClientsContainer"
import {
  ActionLinks,
  BannerOverlayText,
  SubActionLinks,
} from "../../building-blocks/atoms/texts/OurClientsText"
import RightBlack from "../../assets/icons/right_black.png"

const OurClients = () => {
  const [isMobile, setisMobile] = React.useState(null)

  const changeMobile = () => {
    window.matchMedia("(max-width: 37.5em)").matches
      ? setisMobile(true)
      : setisMobile(false)
  }

  React.useEffect(() => {
    changeMobile()
    window.addEventListener("resize", changeMobile)
    return () => window.removeEventListener("resize", changeMobile)
  }, [])

  return (
    <SectionWrapper data-scroll-section>
      <InsideSectionWrapper>
        <SayWrapper>
          <ActionLinks>our clients</ActionLinks>
          <TitleBg />
        </SayWrapper>
        <>
          <BannerOverlayText data-scroll data-scroll-speed="1">
            We are partnered with the best! Our client list speaks for itself..
          </BannerOverlayText>
        </>

        <DetailedSayingWrapper data-scroll data-scroll-speed="1">
          <SubActionLinks>
          Shubhartha Facilities & Services focuses on innovative ideas and strategies to cater all of our client’s 
needs and strongly supports the development of communities where the company is established to 
foster growth and to promote resilient and long-term business ties with our clients.
          </SubActionLinks>
        </DetailedSayingWrapper>

        {isMobile ? (
          <>
            {/* <SubActWrapper
              data-scroll
              data-scroll-speed="2"
              style={{
                width: "100%",
                justifyContent: "flex-start",
                margin: "0%",
                marginTop: "2rem",
              }}
            >
              <ActionLinks
                style={{
                  alignItems: "center",
                  justifyContent: "flex-start",
                  display: "flex",
                  width: "100%",
                  color: "white",
                  fontWeight: 600,
                }}
              >
                explore more{" "}
                <img
                  src={RightBlack}
                  style={{
                    marginLeft: "0.5rem",
                    width: "7%",
                    height: "auto",
                    objectFit: "cover",
                  }}
                  alt=""
                />
              </ActionLinks>
            </SubActWrapper> */}
          </>
        ) : (
          <>
            {/* <SubActWrapper data-scroll data-scroll-speed="2">
              <ActionLinks style={{ color: "black" }}>
                know about our clients
              </ActionLinks>
            </SubActWrapper> */}
          </>
        )}
      </InsideSectionWrapper>
    </SectionWrapper>
  )
}

export default OurClients
