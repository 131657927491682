import React, { useEffect } from "react"
import Helmet from "react-helmet"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import Layout from "./common-components/Layout/Layout"
import "./App.css"
//import lax from "lax.js"

// Page Components
import HomePage from "./components/home-page/HomePage"
import AboutUsPage from "./components/about-us-page/AboutUsPage"
import WhatWeDoPage from "./components/what-we-do-page/WhatWeDoPage"
import ProspectPage from "./components/prospect-page/ProspectPage"
import ContactUsPage from "./components/contact-us-page/ContactUsPage"
import PortfolioPage from "./components/portfolio-page/PortfolioPage"
import OurTeamPage from "./components/our-team-page/OurTeamPage"
import DigitizationPage from "./components/digitization-page/DigitizationPage"
import DigitizationPage2 from "./components/digitization-page-2/DigitizationPage2"
import OfflineBrandSolutionsPage from "./components/offline-brand-solutions-page/OfflineBrandSolutionsPage"
import MarketingAndPromotionsPage from "./components/marketing-and-promotions-page/MarketingAndPromotionsPage"
import SalesPage from "./components/sales-page/SalesPage"
import LogisticsPage from "./components/logistics-page/LogisticsPage"
import SecurityPage from "./components/security-page/SecurityPage"
import TermsConditionsPage from "./components/terms-conditions-page/TermsConditionsPage"
import PrivacyPolicyPage from "./components/privacy-policy-page/PrivacyPolicyPage"
import ConsultingServicesPage from "./components/consulting-services-page/ConsultingServicesPage"
import WorkWithUsPage from "./components/work-with-us-page/WorkWithUsPage"
import PotentialPage from "./components/potential-page/PotentialPage"
import CartzsPage from "./components/cartzs-page/CartzsPage"
import DashboardPage from "./components/dashboard-page/DashboardPage"
import CosmosPage from "./components/cosmos-page/CosmosPage"
import KiranaPage from "./components/kirana-king-page/KiranakingPage"
import emaratPage from "./components/emaratPage/emaratPage"
import PlatformPage from "./components/platform-page/PlatformPage"
import KrazyfastPage from "./components/krazyfast-page/KrazyfastPage"
import GoshoppiPage from "./components/goshoppi-page/GoshoppiPage"
import mshoppiPage from "./components/mshoppi-page/MshoppiPage"
import MshoppiPage from "./components/mshoppi-page/MshoppiPage"
import LedgerApp from "./trading_config/LedgerApp"

const App = () => {
  return (
    <>
      <Helmet>
        <title>Shubhartha Facilities & Services LLP</title>
      </Helmet>

      <Router>
        <Layout>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/about-us" component={AboutUsPage} />
            <Route exact path="/portfolio" component={PortfolioPage} />
            <Route exact path="/fast-moving-consumer-goods" component={DigitizationPage2} />
            <Route exact path="/agro-products" component={DigitizationPage} />
            <Route
              exact
              path="/petro-&-gas-products"
              component={OfflineBrandSolutionsPage}
            />
            <Route
              exact
              path="/personal-protective-equipments"
              component={MarketingAndPromotionsPage}
            />
            <Route exact path="/precious-metals" component={SalesPage} />
            <Route
              exact
              path="/consulting-services"
              component={ConsultingServicesPage}
            />
            <Route exact path="/dr-j's-cashew" component={MshoppiPage} />

            <Route exact path="/ledger" component={LedgerApp} />
          </Switch>
        </Layout>
      </Router>
    </>
  )
}

export default App
